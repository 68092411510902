import { useTranslation } from "i18n";
import { Typography } from "ui/components/Typography";
import * as Single from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Single";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";

export interface HeaderProps {
  selector: Selector<Single.State>;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();
  const id = useSelector(flow(p.selector, (s) => s.payload.id));

  return (
    <Typography>
      {t("Stock")}{" "}
      <Typography variant={"caption"} component={"span"}>
        {id}
      </Typography>
    </Typography>
  );
}
