import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { styled } from "@mui/material/styles";
import React from "react";
import { styleSecondaryTextColor } from "../../../../utils/styles";
import { ProgressBar } from "./ProgressBar";
import { accentColor } from "./constants";

export namespace Stock {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.stock>["renderProps"]
  >;
}

export const Stock = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  current,
  max,
}: Stock.Props<DataEntry, ColumnConfig>) => {
  return (
    <Wrapper>
      <ProgressBar {...{ current, max }} />
      <Text>
        <AccentText>{current}</AccentText>
        <SecondaryText>/{max}</SecondaryText>
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const Text: React.FC<React.PropsWithChildren> = styled("div")`
  ${({ theme }) => theme.typography.subtitle2};
`;

const AccentText = styled("span")`
  color: ${accentColor};
`;

const SecondaryText = styled("span")`
  color: ${styleSecondaryTextColor};
`;
