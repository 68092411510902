import { RootState, useDispatch, useSelector } from "state-manager";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { useEffect } from "react";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";
import { FormColumns } from "ui/layouts/FormColumns";
import { DataTypeJsonEditor } from "@Containers/Form/DataTypeJsonEditor";
import { jsonSchemaToDataTypeSchema } from "ds/DataTypes";

export interface ContentProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector }: ContentProps) {
  const dispatch = useDispatch();
  const selectors = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:DataTypes:Edit:Loading":
        case "Ready:DataManager:DataTypes:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:DataTypes:Edit:Ready":
        case "Ready:DataManager:DataTypes:Edit:Saving":
        case "Ready:DataManager:DataTypes:Edit:RemoveConfirmation":
        case "Ready:DataManager:DataTypes:Edit:Removing": {
          const dispatcher: DataTypeFormProps["dispatch"] = {
            setName: flow(Edit.setName, dispatch),
            setDescription: flow(Edit.setDescription, dispatch),
            setDefault: flow(Edit.setDefault, dispatch),
          };

          return {
            type: "ready",
            formSelector: flow(
              selector,
              (st) => st as typeof s,
              (s) => ({
                default: s.payload.default,
                description: s.payload.description || "",
                entity: s.payload.entity,
                name: s.payload.name,
                schema: s.payload.schema,
              }),
            ),
            jsonEditorSelector: flow(
              selector,
              (st) => st as typeof s,
              (s) => s.payload.schema.payload,
              (s) => JSON.stringify(s, null, 2),
            ),
            dispatcher,
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (selectors.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={selector} />
          <FormColumns>
            <DataTypeForm
              selector={selectors.formSelector}
              dispatch={selectors.dispatcher}
            />
            <DataTypeJsonEditor
              value$={selectors.jsonEditorSelector}
              onChange={flow(
                (v) => JSON.parse(v ?? ""),
                jsonSchemaToDataTypeSchema,
                Edit.resetSchema,
                dispatch,
              )}
              jsonSchema={{
                $schema: "http://json-schema.org/draft-07/schema#",
                title: "Core schema meta-schema",
                definitions: {
                  schemaArray: {
                    type: "array",
                    minItems: 1,
                    items: { $ref: "#" },
                  },
                  nonNegativeInteger: {
                    type: "integer",
                    minimum: 0,
                  },
                  nonNegativeIntegerDefault0: {
                    allOf: [
                      { $ref: "#/definitions/nonNegativeInteger" },
                      { default: 0 },
                    ],
                  },
                  simpleTypes: {
                    enum: [
                      "array",
                      "boolean",
                      "integer",
                      "null",
                      "number",
                      "object",
                      "string",
                    ],
                  },
                  stringArray: {
                    type: "array",
                    items: { type: "string" },
                    uniqueItems: true,
                    default: [],
                  },
                },
                type: ["object", "boolean"],
                properties: {
                  $id: {
                    type: "string",
                    // format: "uri-reference",
                  },
                  $schema: {
                    type: "string",
                    // format: "uri",
                  },
                  $ref: {
                    type: "string",
                    // format: "uri-reference",
                  },
                  $comment: {
                    type: "string",
                  },
                  title: {
                    type: "string",
                  },
                  description: {
                    type: "string",
                  },
                  default: true,
                  readOnly: {
                    type: "boolean",
                    default: false,
                  },
                  writeOnly: {
                    type: "boolean",
                    default: false,
                  },
                  examples: {
                    type: "array",
                    items: true,
                  },
                  multipleOf: {
                    type: "number",
                    exclusiveMinimum: 0,
                  },
                  maximum: {
                    type: "number",
                  },
                  exclusiveMaximum: {
                    type: "number",
                  },
                  minimum: {
                    type: "number",
                  },
                  exclusiveMinimum: {
                    type: "number",
                  },
                  maxLength: { $ref: "#/definitions/nonNegativeInteger" },
                  minLength: {
                    $ref: "#/definitions/nonNegativeIntegerDefault0",
                  },
                  pattern: {
                    type: "string",
                    // format: "regex",
                  },
                  additionalItems: { $ref: "#" },
                  items: {
                    anyOf: [
                      { $ref: "#" },
                      { $ref: "#/definitions/schemaArray" },
                    ],
                    default: true,
                  },
                  maxItems: { $ref: "#/definitions/nonNegativeInteger" },
                  minItems: {
                    $ref: "#/definitions/nonNegativeIntegerDefault0",
                  },
                  uniqueItems: {
                    type: "boolean",
                    default: false,
                  },
                  contains: { $ref: "#" },
                  maxProperties: { $ref: "#/definitions/nonNegativeInteger" },
                  minProperties: {
                    $ref: "#/definitions/nonNegativeIntegerDefault0",
                  },
                  required: { $ref: "#/definitions/stringArray" },
                  additionalProperties: { $ref: "#" },
                  definitions: {
                    type: "object",
                    additionalProperties: { $ref: "#" },
                    default: {},
                  },
                  properties: {
                    type: "object",
                    additionalProperties: { $ref: "#" },
                    default: {},
                  },
                  patternProperties: {
                    type: "object",
                    additionalProperties: { $ref: "#" },
                    // propertyNames: {
                    //   format: "regex",
                    // },
                    default: {},
                  },
                  dependencies: {
                    type: "object",
                    additionalProperties: {
                      anyOf: [
                        { $ref: "#" },
                        { $ref: "#/definitions/stringArray" },
                      ],
                    },
                  },
                  propertyNames: { $ref: "#" },
                  const: true,
                  enum: {
                    type: "array",
                    items: true,
                    minItems: 1,
                    uniqueItems: true,
                  },
                  type: {
                    anyOf: [
                      { $ref: "#/definitions/simpleTypes" },
                      {
                        type: "array",
                        items: { $ref: "#/definitions/simpleTypes" },
                        minItems: 1,
                        uniqueItems: true,
                      },
                    ],
                  },
                  format: { type: "string" },
                  contentMediaType: { type: "string" },
                  contentEncoding: { type: "string" },
                  if: { $ref: "#" },
                  then: { $ref: "#" },
                  else: { $ref: "#" },
                  allOf: { $ref: "#/definitions/schemaArray" },
                  anyOf: { $ref: "#/definitions/schemaArray" },
                  oneOf: { $ref: "#/definitions/schemaArray" },
                  not: { $ref: "#" },
                },
                default: true,
              }}
            />
          </FormColumns>
        </>
      );
  }
}

function RemoveConfirmHandler({
  selector,
}: {
  selector: (s: RootState) => Edit.State;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRemoveConfirmation = useSelector(
    flow(selector, Edit.isRemoveConfirmation),
  );

  useEffect(() => {
    if (isRemoveConfirmation) {
      const confirm = window.confirm(
        t(`Are you sure you want to delete data type?`),
      );
      if (confirm) {
        dispatch(Edit.removeApprove());
      } else {
        dispatch(Edit.removeCancel());
      }
    }
  }, [isRemoveConfirmation]);

  return null;
}
