import { RootState } from "state-manager";
import { useTranslation } from "i18n";
import { Dispatch } from "react";
import * as Fp from "fp-ts/function";
import { Picker } from "@Containers/Form/Picker";
import * as Filters from "../../../../../packages/state-manager/src/generic-states/Filters";

export namespace Executed {
  export type Filter = { executed: "all" | "executed" | "un-executed" };

  export interface Props<P extends string> {
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function Executed<P extends string>(p: Executed.Props<P>) {
  const { t } = useTranslation();
  const options = [
    { value: "executed", label: t("Executed") } as const,
    { value: "un-executed", label: t("Not executed") } as const,
  ];

  return (
    <Picker<Executed.Filter["executed"]>
      label={t("Status")}
      value$={Fp.flow(p.selector$, (v) => v.payload.fields.executed)}
      onChange={Fp.flow(
        (v) => p.actions.setValue.create({ executed: v ?? "all" }),
        p.dispatch,
      )}
      options={options}
    />
  );
}
