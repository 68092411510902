import React from "react";
import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { styled } from "@mui/material/styles";
import { Icon } from "./Icon";

export namespace MovedFrom {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.movedFrom>["renderProps"]
  >;
}

export const MovedFrom = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
}: MovedFrom.Props<DataEntry, ColumnConfig>) => (
  <Wrapper>
    <Icon />
    <Text>{text ?? "-"}</Text>
  </Wrapper>
);

export const Wrapper: React.FC<React.PropsWithChildren> = styled("div")`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const Text: React.FC<React.PropsWithChildren> = styled("div")`
  ${({ theme }) => theme.typography.subtitle2};
`;
