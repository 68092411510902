import { RootState } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { SuppliersListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { DataTypeId } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ById } from "@Containers/Filters/ById";
import { Orphans } from "@Containers/Filters/Orphans";
import { SearchFields } from "@Containers/Filters/SearchFields";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { CreatedTo } from "@Containers/Filters/CreatedTo";
import { DataTypes } from "@Containers/Filters/DataTypes";
import * as Filters from "../../../../../../../../../packages/state-manager/src/generic-states/Filters";

export namespace AdvancedFiltersForm {
  export interface DataType {
    id: DataTypeId;
    name: TranslatedStr;
  }

  export interface Props<P extends string> {
    dataTypes$: (s: RootState) => Array<DataType>;
    selector$: (s: RootState) => Filters.State<P, Listing.Filter>;
    dispatch: Dispatch<Filters.Actions<P, Listing.Filter>>;
    actions: ReturnType<
      typeof Filters.createState<P, Listing.Filter>
    >["actions"];
  }
}

export function AdvancedFiltersForm<P extends string>(
  p: AdvancedFiltersForm.Props<P>,
): ReactElement {
  return (
    <FormWrapper>
      <ById selector$={p.selector$} dispatch={p.dispatch} actions={p.actions} />
      <Orphans
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <SearchFields
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <DataTypes
        dataTypes$={p.dataTypes$}
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <CreatedFrom
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <CreatedTo
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
    </FormWrapper>
  );
}
