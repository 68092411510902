import { Selector, useDispatch, useSelector } from "state-manager";
import { NotificationItem, useToast } from "ui/components/Toast";
import { useEffect, useMemo } from "react";
import * as Arr from "fp-ts/Array";
import * as Num from "fp-ts/number";
import { first, from, map, merge, mergeMap, pairwise, Subject } from "rxjs";
import * as Nt from "state-manager/states/Notifications";
import { shallowEqualArrays } from "shallow-equal";

export interface NotificationsProps {
  selector: Selector<Nt.State>;
}

type Item = Nt.State["payload"]["items"][number];

export function Notifications(p: NotificationsProps) {
  const dispatch = useDispatch();
  const notifications$ = useMemo(() => new Subject<Item[]>(), []);
  const notifications = useSelector(
    (s) => p.selector(s).payload.items,
    shallowEqualArrays,
  );
  const toast = useToast();

  useEffect(() => {
    notifications$.next(notifications);
  }, [notifications]);

  useEffect(() => {
    merge(
      notifications$.pipe(first()),
      notifications$.pipe(
        pairwise(),
        map(([a, b]) =>
          Arr.difference<Item>({ equals: (a, b) => a.id === b.id })(b, a),
        ),
      ),
    )
      .pipe(mergeMap((vs) => from(vs)))
      .subscribe(({ id, type, message, content }) => {
        toast.enqueueSnackbar(
          <NotificationItem
            type={type}
            message={message}
            content={content}
            onLock={() => dispatch(Nt.lock(id))}
            onUnlock={() => dispatch(Nt.unlock(id))}
            onRemove={() => dispatch(Nt.removeNotification(id))}
          />,
          {
            key: String(id),
          },
        );
      });

    notifications$
      .pipe(
        map((vs) => vs.map((v) => v.id)),
        pairwise(),
        map(([a, b]) => Arr.difference(Num.Eq)(a, b)),
        mergeMap((ids) => from(ids)),
        map(String),
      )
      .subscribe(toast.closeSnackbar);
  }, []);

  return null;
}
