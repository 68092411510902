import { Selector } from "state-manager";
import { RepositoriesListing as Main } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Listing";
import { DataTypeId } from "types/src/DataType/DataType";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as Fp from "fp-ts/function";
import { RepositoriesListing } from "@Containers/Listing/RepositoriesListing";
import * as O from "fp-ts/Option";
import { RepositoryId } from "types/src/Repositories/Repository";

export namespace Content {
  export interface Props {
    selector: Selector<Main.State>;
    dispatch: (a: Main.Actions) => void;
    onCreateNewType: () => void;
    onItemClick: (id: RepositoryId) => void;
    onTypeClick: (id: DataTypeId) => void;
  }
}

export function Content(p: Content.Props) {
  const state$ = useStateBehavior().map(p.selector);
  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items,
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );

  return (
    <RepositoriesListing
      loading$={state$.map(Main.instance.states.loading.is)}
      data$={data$}
      filters$={state$.map((v) => ({
        search: v.payload.filters.payload.fields.search,
        created: v.payload.filters.payload.fields.createdAt,
      }))}
      orderBy$={state$.map((v) => O.fromNullable(v.payload.order))}
      onCreateNewType={p.onCreateNewType}
      onCreateNew={Fp.flow(Main.instance.actions.create.create, p.dispatch)}
      onItemClick={p.onItemClick}
      onTypeClick={p.onTypeClick}
      onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
      onFilterChange={Fp.flow(
        Main.instance.subStates.filters.actions.setValue.create,
        p.dispatch,
      )}
      onOrderChange={Fp.flow(Main.instance.actions.orderBy.create, p.dispatch)}
    />
  );
}
