import { RootState, useDispatch } from "state-manager";
import { DataTypesListing } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { Filters, FiltersProps } from "@Containers/Listing/Filters";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Input } from "@Containers/Form/Input";
import { Textarea } from "@Containers/Form/Textarea";
import { useTranslation } from "i18n";
import { useMemo } from "react";
import { dataTypeEntities, DataTypeEntity } from "types/src/DataType/DataType";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { MultiCombobox } from "@Containers/Form/MultiCombobox";
import { Picker } from "@Containers/Form/Picker";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { Typed } from "utils/Typed";

const filtersActions = DataTypesListing.instance.subStates.filters.actions;

interface ListingHeaderProps {
  selector: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof DataTypesListing.instance.states>[
    | "ready"
    | "fetching"];
}
export function ListingHeader(p: ListingHeaderProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = flow(
    p.selector,
    (s): ReturnType<FiltersProps["selector"]> => ({
      isOpen: s.payload.advancedFiltersState === "open",
      hasFiltersEnabled:
        !DataTypesListing.instance.subStates.filters.states.idle.is(
          s.payload.filters,
        ),
      search: pipe(
        s.payload.filters.payload.fields.name,
        O.getOrElseW(() => ""),
      ),
    }),
  );
  const entityTitle = useDataTypeEntityTitle();
  const filtersSelector = flow(
    p.selector,
    (s) => s.payload.filters.payload.fields,
  );
  const entities = useMemo(() => {
    return dataTypeEntities.map((entity) => ({
      value: entity,
      label: entityTitle(entity),
    }));
  }, [entityTitle]);

  return (
    <Filters
      selector={selector}
      dispatch={{
        onReset: () => dispatch(filtersActions.reset.create()),
        onSave: () => dispatch(filtersActions.submit.create()),
        closeFilters: () =>
          dispatch(
            DataTypesListing.instance.actions.closeAdvancedFilters.create(),
          ),
        openFilters: () =>
          dispatch(
            DataTypesListing.instance.actions.openAdvancedFilters.create(),
          ),
        setSearch: flow(
          NoEmptyString.fromString,
          (v) => filtersActions.setValue.create({ name: v }),
          dispatch,
        ),
      }}
    >
      <Input
        label={t("Title")}
        placeholder={t("Search by a text in title")}
        value$={flow(
          filtersSelector,
          (s) => s.name,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(
          NoEmptyString.fromString,
          (v) => filtersActions.setValue.create({ name: v }),
          dispatch,
        )}
      />
      <Textarea
        label={t("Description")}
        placeholder={t("Search by a text in description")}
        rows={3}
        value$={flow(
          filtersSelector,
          (s) => s.description,
          O.getOrElseW(() => ""),
        )}
        onChange={flow(
          NoEmptyString.fromString,
          (v) => filtersActions.setValue.create({ description: v }),
          dispatch,
        )}
      />
      <Picker
        label={t("Default")}
        value$={flow(filtersSelector, (s) => s.default, O.toUndefined)}
        onChange={flow(
          O.fromNullable,
          (v) => filtersActions.setValue.create({ default: v }),
          dispatch,
        )}
        options={[
          { value: true, label: t("Default only") },
          { value: false, label: t("No default only") },
        ]}
      />
      <MultiCombobox<DataTypeEntity>
        label={t("Entity")}
        placeholder={t("All entities")}
        value$={flow(
          filtersSelector,
          (s) => s.entity,
          O.getOrElseW(() => []),
        )}
        onChange={flow(
          NonEmptyArray.fromArray,
          (v) => filtersActions.setValue.create({ entity: v }),
          dispatch,
        )}
        options={entities}
      />
    </Filters>
  );
}
