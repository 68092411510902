import * as Rx from "rxjs";
import * as E from "fp-ts/Either";
import { DsError } from "ds";

import * as Arr from "fp-ts/Array";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import * as Obj from "utils/object";
import { Typed } from "utils/Typed";
import { Epic } from "../../types/RootEpic";
import * as Filters from "../Filters";
import { Items } from "./types/Items";
import { createActions, Actions } from "./types/Actions";
import { createState, State } from "./types/State";

export interface Deps<
  P extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends Record<string, any>,
  T extends { id: string },
  O,
  A extends {},
> {
  fetchItems: (
    v: Pick<
      Typed.GetCollectionType<ReturnType<typeof createState<P, F, T, O, A>>>,
      "loading" | "fetching"
    >["loading" | "fetching"],
  ) => Rx.Observable<E.Either<DsError, Items<T>>>;
  removeItems: (
    v: Array<T["id"]>,
  ) => Rx.Observable<E.Either<Array<T["id"]>, Array<T["id"]>>>;
}

export const createEpic = <
  P extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends Record<string, any>,
  T extends { id: string },
  O,
  A extends {},
>({
  states,
  actions,
  filters,
}: {
  states: ReturnType<typeof createState<P, F, T, O, A>>;
  actions: ReturnType<typeof createActions<P, T, O>>;
  filters: ReturnType<typeof Filters.createState<`${P}:filters`, F>>;
}): Epic<Actions<P, F, T, O>, State<P, F, T, O, A>, Deps<P, F, T, O, A>> => {
  const diff = Arr.difference(Str.Eq as Eq<T["id"]>);

  return (state$, deps) => {
    const loading$ = state$.pipe(
      Rx.filter(states.loading.is),
      Rx.switchMap((s) => {
        return deps
          .fetchItems(s)
          .pipe(
            Rx.map(E.map(actions.loadSuccess.create)),
            Rx.map(E.getOrElseW(actions.loadFail.create)),
          );
      }),
    );

    const fetching$ = state$.pipe(
      Rx.filter(states.fetching.is),
      Rx.switchMap((s) => {
        return deps
          .fetchItems(s)
          .pipe(
            Rx.map(E.map(actions.fetchSuccess.create)),
            Rx.map(E.getOrElseW(actions.loadFail.create)),
          );
      }),
    );

    const remove$ = state$.pipe(
      Rx.filter(states.ready.is),
      Rx.map((s) =>
        Obj.entries(s.payload.removing)
          .filter(([, v]) => v === "removing")
          .map(([k]) => k),
      ),
      Rx.scan(
        ([pendingItems], removingItems) => {
          return [removingItems, diff(pendingItems, removingItems)] as [
            T["id"][],
            T["id"][],
          ];
        },
        [[], []] as [T["id"][], T["id"][]],
      ),
      Rx.map(([, toRemove]) => toRemove),
      Rx.filter((i) => i.length > 0),
      Rx.mergeMap((toRemove) => {
        return deps
          .removeItems(toRemove)
          .pipe(
            Rx.map(E.map(actions.removeSuccess.create)),
            Rx.map(E.mapLeft(actions.removeFail.create)),
            Rx.map(E.getOrElseW((v) => v)),
          );
      }),
    );

    return Rx.merge(
      loading$,
      fetching$,
      remove$,
      filters.epic(state$.pipe(Rx.map((s) => s.payload.filters)), {}),
    );
  };
};
