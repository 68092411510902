import { Selector, useSelector } from "state-manager";
import { AutocompleteMulti as ComboboxComponent } from "ui/components/AutocompleteMulti";
import { TranslatedStr } from "types/src/TranslatedStr";
import { shallowEqualArrays } from "shallow-equal";
import * as Fp from "fp-ts/function";

export interface MultiComboboxProps<T extends string> {
  value$: Selector<T[]>;
  onChange: (value: T[]) => void;
  options: { value: T; label: TranslatedStr }[];
  placeholder?: TranslatedStr;
  label?: TranslatedStr;
}

export function MultiCombobox<T extends string>({
  value$,
  onChange,
  options,
  placeholder,
  label,
}: MultiComboboxProps<T>) {
  const values = useSelector(
    Fp.flow(value$, (vs) =>
      options
        .map((v, i) => (vs.includes(v.value) ? i : undefined))
        .filter((v): v is number => Number.isInteger(v)),
    ),
    shallowEqualArrays,
  );
  return (
    <ComboboxComponent
      label={label}
      activeIndexes={values}
      onChange={onChange}
      options={options.map((v) => v.value)}
      placeholder={placeholder}
      getLabel={(v) => options.find((i) => i.value === v)?.label ?? ""}
    />
  );
}
