import { forwardRef, ReactElement } from "react";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";

export interface CheckHeadCellProps {
  value: "checked" | "unchecked" | "indeterminate";
  onChange: () => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CheckHeadCell = forwardRef<
  HTMLTableCellElement,
  CheckHeadCellProps
>(({ onChange, value, readonly, disabled }, ref): ReactElement => {
  return (
    <TableCell ref={ref} padding={"checkbox"}>
      <Checkbox
        readOnly={readonly}
        disabled={disabled}
        checked={value === "checked"}
        indeterminate={value === "indeterminate"}
        onChange={onChange}
      />
    </TableCell>
  );
});
