import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import { RepositoryMovementsListingAll as ListingAll } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import { RepositoryMovementsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import * as ListingAllContainer from "./ListingAll";
import * as ListingContainer from "./Listing";

export interface FooterProps {
  selector: Selector<RepositoryMovements.State>;
  dispatch: (a: RepositoryMovements.Actions) => void;
}

export function Footer(p: FooterProps) {
  const r = useSelector(
    flow(
      p.selector,
      (v) => v.payload.listing,
      (s) => {
        if (ListingAll.instance.isState(s))
          return {
            type: "listing-all",
            selector: flow(
              p.selector,
              (v) => v.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        if (Listing.instance.isState(s))
          return {
            type: "listing",
            selector: flow(
              p.selector,
              (v) => v.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
          selector: flow(
            p.selector,
            (v) => v.payload.listing,
            (st) => st as typeof s,
          ),
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "listing":
      return (
        <ListingContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
