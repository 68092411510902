import { RootState, useSelector } from "state-manager";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import {
  Item as TableItem,
  ItemsTable,
} from "@Containers/Ready/DataManager/DataTypes/Listing/components/ItemsTable";
import { DataTypeId } from "types/src/DataType/DataType";
import { flow } from "fp-ts/function";
import { ListingHeader } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Filters";
import { DataTypesListing } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";

export interface ContentProps {
  selector: (s: RootState) => DataTypesListing.State;
  dispatch: (a: DataTypesListing.Actions) => void;
}

export function Content({ selector }: ContentProps) {
  const r = useSelector(
    flow(selector, (s: DataTypesListing.State) => {
      if (DataTypesListing.instance.states.loading.is(s))
        return { type: "loading" } as const;
      if (
        DataTypesListing.instance.states.ready.is(s) ||
        DataTypesListing.instance.states.fetching.is(s)
      ) {
        const subSelector = flow(selector, (st) => st as typeof s);
        return {
          type: "items",
          header: flow(selector, (st) => st as typeof s),
          items: flow(
            subSelector,
            (s) => s.payload.items,
            (is) => is.map(({ id }) => id),
          ),
          item: (id: DataTypeId) =>
            flow(
              subSelector,
              (s) => s.payload.items,
              (s): TableItem<DataTypeId> => {
                const item = s.find((i) => i.id === id)!;

                return {
                  id: item.id,
                  title: item.name,
                  entity: item.entity,
                  isDefault: item.default,
                  description: item.description,
                };
              },
            ),
        } as const;
      }
      if (DataTypesListing.instance.states.loadError.is(s))
        return { type: "error" } as const;

      silentUnreachableError(s);
      return { type: "loading" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "items":
      return (
        <ItemsTable
          selector={r.items}
          itemSelector={r.item}
          header={<ListingHeader selector={r.header} />}
        />
      );
    case "error":
      return <div>Error</div>;
  }
}
