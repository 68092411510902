import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Input } from "@Containers/Form/Input";
import { flow } from "fp-ts/function";
import { useTranslation } from "i18n";
import { Link } from "@Router/Link";
import { Typography } from "ui/components/Typography";
import * as Single from "../../../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Stocks/states/Single";
import { routes } from "@/router";

export interface FormProps {
  selector: Selector<Single.Ready>;
}

export function Form(p: FormProps): ReactElement {
  const { t } = useTranslation();
  const itemId = useSelector(
    flow(p.selector, (s) => s.payload.inventoryItemId),
  );
  const repositoryId = useSelector(
    flow(p.selector, (s) => s.payload.repositoryId),
  );
  const repositoryName = useSelector(
    flow(p.selector, (s) => s.payload.repositoryName),
  );

  return (
    <FormWrapper>
      <Input
        label={t("Stock Id")}
        value$={flow(p.selector, (v) => v.payload.id)}
        onChange={() => {}}
        readOnly
      />
      <Input
        label={t("Incoming stock")}
        value$={flow(p.selector, (v) => v.payload.incoming.toString())}
        onChange={() => {}}
        readOnly
      />
      <Input
        label={t("Quantity")}
        value$={flow(p.selector, (v) => v.payload.quantity.toString())}
        onChange={() => {}}
        readOnly
      />
      <Input
        label={t("Outgoing stock")}
        value$={flow(p.selector, (v) => v.payload.outgoing.toString())}
        onChange={() => {}}
        readOnly
      />
      <Typography>
        {t("Item")}{" "}
        <Link to={routes["/inventory-items/edit/:id"].create({ id: itemId })}>
          {itemId}
        </Link>
      </Typography>
      <Typography>
        {t("Repository")}{" "}
        <Link
          to={routes["/repositories/edit/:id"].create({ id: repositoryId })}
        >
          {repositoryName}
        </Link>
      </Typography>
    </FormWrapper>
  );
}
