import { ReactElement } from "react";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { ListingState } from "../../../../../../../../packages/state-manager/src/generic-states/Listing";
import { All } from "./components/All";

const state = DataManager.pickingOrderState.subStates.listingAll;

export interface ContentProps {
  selector: Selector<ListingState.GetState<typeof state>>;
  dispatch: (a: ListingState.GetActions<typeof state>) => void;
}

export function Content({ selector, dispatch }: ContentProps): ReactElement {
  const r = useSelector(
    flow(selector, (s) => {
      if (state.states.loading.is(s)) return { type: "loading" } as const;
      if (state.states.ready.is(s) || state.states.fetching.is(s)) {
        return {
          type: "items",
          header: flow(selector, (st) => st as typeof s),
          items: flow(selector, (st) => st as typeof s),
        } as const;
      }
      if (state.states.loadError.is(s)) return { type: "error" } as const;

      silentUnreachableError(s);
      return { type: "loading" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "items":
      return <All selector$={r.items} dispatch={dispatch} />;
    case "error":
      return <div>Error</div>;
  }
}
