import { RootState } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { InventoryItemsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { CreatedTo } from "@Containers/Filters/CreatedTo";
import { SearchFields } from "@Containers/Filters/SearchFields";
import { ById } from "@Containers/Filters/ById";

type Filters = Listing.State["payload"]["filters"];

export interface AdvancedFiltersFormProps {
  selector$: (s: RootState) => Filters;
  dispatch: Dispatch<Listing.Actions>;
}

export function AdvancedFiltersForm(p: AdvancedFiltersFormProps): ReactElement {
  return (
    <FormWrapper>
      <ById
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <SearchFields
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <CreatedFrom
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <CreatedTo
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
    </FormWrapper>
  );
}
