import {
  Body,
  Cell,
  Head,
  HeaderCell,
  HeaderRow,
  Row,
  SortableCell,
  Table,
} from "ui/components/Table";
import { RootState, Selector, useSelector } from "state-manager";
import { SupplierId } from "types/src/Supplier/Supplier";
import { useTranslation } from "i18n";
import { flow, pipe } from "fp-ts/function";
import { shallowEqualArrays } from "shallow-equal";
import { ReactElement, ReactNode, useMemo } from "react";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { Link } from "@Router/Link";
import { CheckHeadCell } from "ui/layouts/Listing/cell/CheckHeadCell";
import { CheckCell } from "ui/layouts/Listing/cell/CheckCell";
import {
  ActionsCell,
  ActionsHeaderCell,
} from "ui/layouts/Listing/cell/ActionsCell";
import { CopyTooltip } from "ui/components/CopyTooltip";
import { DateCell } from "ui/layouts/Listing/cell/DateCell";
import { isDeepEqual } from "utils/object";
import { TranslatedStr } from "types/src/TranslatedStr";
import { routes } from "@/router";

interface Column {
  id: string;
  label: TranslatedStr;
  type: "text" | "number";
}

interface Item {
  id: SupplierId;
  fields: Record<string, string | number>;
  createdAt: Date;
  updatedAt: O.Option<Date>;
  selected: boolean;
}

export interface SuppliersTableProps {
  orderBy$: (
    s: RootState,
  ) => Option<{ by: "createdAt" | "updatedAt"; direction: "asc" | "desc" }>;
  columns$: Selector<Column[]>;
  items$: Selector<Item[]>;
  orderBy: (v: "createdAt" | "updatedAt") => void;
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
  onSelectAll: () => void;
  onDeleteAll: () => void;
}

export function SuppliersTable({
  items$,
  orderBy$,
  columns$,
  orderBy,
  onSelect,
  onSelectAll,
  onDelete,
  onDeleteAll,
}: SuppliersTableProps): ReactElement {
  const selected$ = useMemo(
    () =>
      flow(
        items$,
        (s) => s.map((s) => s.selected),
        (s) =>
          s.every(Boolean)
            ? "checked"
            : s.some(Boolean)
            ? "indeterminate"
            : "unchecked",
      ),
    [items$],
  );

  return (
    <Table stickyHeader>
      <Head>
        <Header
          columns$={columns$}
          selected$={selected$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelectAll}
          onDelete={onDeleteAll}
        />
      </Head>
      <Content
        columns$={columns$}
        items$={items$}
        onSelect={onSelect}
        onDelete={onDelete}
      />
    </Table>
  );
}

interface HeaderProps {
  selected$: (s: RootState) => "checked" | "unchecked" | "indeterminate";
  columns$: Selector<Column[]>;
  orderBy$: (s: RootState) => Option<{
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  }>;
  orderBy: (v: "createdAt" | "updatedAt") => void;
  onSelect: () => void;
  onDelete: () => void;
}
function Header({
  orderBy,
  orderBy$,
  selected$,
  columns$,
  onSelect,
  onDelete,
}: HeaderProps): ReactElement {
  const { t } = useTranslation();
  const selected = useSelector(selected$);
  const sortByCreatedAt = useSelector(
    flow(
      orderBy$,
      flow(
        O.filter((o) => o.by === "createdAt"),
        O.map((o) => o.direction),
        O.toUndefined,
      ),
    ),
  );
  const sortByUpdatedAt = useSelector(
    flow(
      orderBy$,
      flow(
        O.filter((o) => o.by === "updatedAt"),
        O.map((o) => o.direction),
        O.toUndefined,
      ),
    ),
  );
  const columns = useSelector(columns$, isDeepEqual);

  return (
    <HeaderRow>
      <CheckHeadCell value={selected} onChange={onSelect} />
      <HeaderCell>{t("Id")}</HeaderCell>
      {columns.map((column) => (
        <HeaderCell key={column.id}>{column.label}</HeaderCell>
      ))}
      <SortableCell sort={sortByCreatedAt} onClick={() => orderBy("createdAt")}>
        {t("Created")}
      </SortableCell>
      <SortableCell sort={sortByUpdatedAt} onClick={() => orderBy("updatedAt")}>
        {t("Updated")}
      </SortableCell>
      <ActionsHeaderCell
        actions={[
          {
            label: t("Delete"),
            onClick: onDelete,
          },
        ]}
      />
    </HeaderRow>
  );
}

interface ContentProps {
  columns$: Selector<Column[]>;
  items$: Selector<Item[]>;
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
}
function Content({
  columns$,
  items$,
  onSelect,
  onDelete,
}: ContentProps): ReactElement {
  const items = useSelector(
    flow(items$, (vs) => {
      return vs.map((v) => ({
        key: v.id,
        item$: flow(items$, (s) => s.find((d) => d.id === v.id) as Item),
      }));
    }),
    (a, b) =>
      shallowEqualArrays(
        a.map((v) => v.key),
        b.map((v) => v.key),
      ),
  );

  return (
    <Body>
      {items.map(({ key, item$ }) => (
        <ItemRow
          key={key}
          columns$={columns$}
          item$={item$}
          onSelect={onSelect}
          onDelete={onDelete}
        />
      ))}
    </Body>
  );
}

interface ItemRowProps {
  columns$: (s: RootState) => Column[];
  item$: (s: RootState) => Item;
  onSelect: (id: SupplierId) => void;
  onDelete: (id: SupplierId) => void;
}
function ItemRow({ item$, columns$, onSelect, onDelete }: ItemRowProps) {
  const { t } = useTranslation();
  const { id, createdAt, updatedAt, selected, fields } = useSelector(
    flow(item$, (c) => ({
      id: c.id,
      createdAt: c.createdAt,
      updatedAt: c.updatedAt,
      selected: c.selected,
      fields: c.fields,
    })),
    isDeepEqual,
  );
  const columns = useSelector(columns$, isDeepEqual);

  return (
    <Row>
      <CheckCell value={selected} onChange={() => onSelect(id)} />
      <Cell $ellipsis>
        <CopyTooltip text={id}>
          <Link to={routes["/suppliers/edit/:id"].create({ id })}>{id}</Link>
        </CopyTooltip>
      </Cell>
      {columns.map((column) => (
        <Cell $ellipsis>
          {pipe(
            column,
            O.of,
            O.chain((c) => O.fromNullable(fields[c.id])),
            O.getOrElse<ReactNode>(() => ""),
          )}
        </Cell>
      ))}
      <DateCell value={O.of(createdAt)} />
      <DateCell value={updatedAt} />
      <ActionsCell
        actions={[
          {
            label: t("Delete"),
            onClick: () => onDelete(id),
          },
        ]}
      />
    </Row>
  );
}
