import { ReactElement } from "react";
import { RootState, useSelector } from "state-manager";
import { Pagination as UIPagination } from "ui/components/Pagination";
import { shallowEqualObjects } from "shallow-equal";

export interface PaginationProps {
  selector: (s: RootState) => {
    hasPrev: boolean;
    hasNext: boolean;
  };
  onChange: (page: "start" | "prev" | "next" | "end") => void;
}

export function Pagination({
  selector,
  onChange,
}: PaginationProps): ReactElement | null {
  const { hasPrev, hasNext } = useSelector(selector, shallowEqualObjects);

  return (
    <UIPagination hasPrev={hasPrev} hasNext={hasNext} onChange={onChange} />
  );
}
