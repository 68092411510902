import React from "react";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";
import { styleButtonBorderColor, stylesIcon } from "./utils/styles";

export namespace FilterButton {
  export type Props = React.PropsWithChildren<{
    Icon: React.FC<SvgIconOwnProps>;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }>;
}

export const FilterButton: React.FC<FilterButton.Props> = ({
  Icon,
  onClick,
  children,
}) => (
  <Button
    size="small"
    type="button"
    variant="outlined"
    startIcon={<Icon sx={stylesIcon} />}
    sx={sx}
    onClick={onClick}
  >
    {children}
  </Button>
);

const sx: SxProps<Theme> = (theme) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  borderColor: styleButtonBorderColor(theme),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2.5, 4),
});
