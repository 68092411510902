import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Selector } from "state-manager";

import { Typography } from "ui/components/Typography";
import { useTranslation } from "i18n";

export interface HeaderProps {
  selector: Selector<Customers.State>;
  dispatch: (a: Customers.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();
  return <Typography variant={"h4"}>{t("Customers")}</Typography>;
}
