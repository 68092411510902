import { RootState } from "state-manager";
import { useTranslation } from "i18n";
import { Dispatch } from "react";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { FilterInput } from "@Containers/Listing/FilterInput";
import { flow } from "fp-ts/function";
import * as Filters from "../../../../../packages/state-manager/src/generic-states/Filters";

export namespace SearchFields {
  export type Filter = { search: O.Option<NoEmptyString> };

  export interface Props<P extends string> {
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function SearchFields<P extends string>(p: SearchFields.Props<P>) {
  const { t } = useTranslation();

  return (
    <FilterInput
      label={t("Search by fields")}
      placeholder={t("Type content to search")}
      value$={flow(
        p.selector$,
        (v) => v.payload.fields.search,
        O.getOrElseW(() => ""),
      )}
      onChange={flow(
        NoEmptyString.fromString,
        (v) => p.actions.setValue.create({ search: v }),
        p.dispatch,
      )}
    />
  );
}
