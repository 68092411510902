import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import { CustomersListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { AdvancedFilters } from "@Containers/Listing/AdvancedFilters";
import { Typed } from "utils/Typed";
import { AdvancedFiltersForm } from "./AdvancedFiltersForm";

export interface FiltersProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: Dispatch<Listing.Actions>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const search$ = flow(
    p.selector$,
    (s) => s.payload.filters.payload.fields.search,
    O.getOrElseW(() => ""),
  );
  const isOpen$ = flow(
    p.selector$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const filters$ = flow(p.selector$, (s) => s.payload.filters);

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(
          NoEmptyString.fromString,
          (search) =>
            Listing.instance.subStates.filters.actions.setValue.create({
              search,
            }),
          p.dispatch,
        )}
      />
      <FiltersTrigger
        isSelected$={flow(
          p.selector$,
          (s) =>
            !Listing.instance.subStates.filters.states.idle.is(
              s.payload.filters,
            ),
        )}
        onClear={flow(
          Listing.instance.subStates.filters.actions.reset.create,
          p.dispatch,
        )}
        onClick={flow(
          Listing.instance.actions.openAdvancedFilters.create,
          p.dispatch,
        )}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(
          Listing.instance.actions.closeAdvancedFilters.create,
          p.dispatch,
        )}
        onClear={flow(
          Listing.instance.subStates.filters.actions.reset.create,
          p.dispatch,
        )}
        onApply={flow(
          Listing.instance.subStates.filters.actions.submit.create,
          p.dispatch,
        )}
      >
        <AdvancedFiltersForm
          dispatch={p.dispatch}
          selector$={filters$}
          actions={Listing.instance.subStates.filters.actions}
        />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}
