import { ReactElement } from "react";
import { Selector, useSelector } from "state-manager";
import { SuppliersListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "ui/layouts/Loading";
import { AllSuppliers } from "./components/AllSuppliers";

export interface ContentProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}
export function Content({ selector, dispatch }: ContentProps): ReactElement {
  const r = useSelector(
    flow(selector, (s) => {
      if (Listing.instance.states.loading.is(s))
        return { type: "loading" } as const;
      if (
        Listing.instance.states.ready.is(s) ||
        Listing.instance.states.fetching.is(s)
      ) {
        return {
          type: "items",
          header: flow(selector, (st) => st as typeof s),
          items: flow(selector, (st) => st as typeof s),
        } as const;
      }
      if (Listing.instance.states.loadError.is(s))
        return { type: "error" } as const;

      silentUnreachableError(s);
      return { type: "loading" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "items":
      return <AllSuppliers selector$={r.items} dispatch={dispatch} />;
    case "error":
      return <div>Error</div>;
  }
}
