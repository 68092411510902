import React from "react";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { useTranslation } from "i18n";
import Popover, { PopoverProps } from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import { styleSecondaryTextColor } from "@layouts/ListingTable/utils/styles";
import { usePopoverController } from "@layouts/ListingTable/hooks/usePopoverController";
import { FilterButton } from "../../../FilterButton";
import { FilterTypeDateRange, FilterProps } from "../../../types/filters";
import { formatAsDateYear } from "../../../utils/date";
import { DateRangePicker } from "./DateRangePicker";

export const DateRange = ({
  config: { options },
  value,
  onChange,
}: FilterProps<FilterTypeDateRange>) => {
  const { language } = useTranslation();
  const { onOpenPopoverButtonClick, popoverOpen, popoverProps } =
    usePopoverController();

  const { min, max } = options || {};
  const { start, end } = value || {};

  return (
    <>
      <FilterButton
        Icon={CalendarTodayRoundedIcon}
        onClick={onOpenPopoverButtonClick}
      >
        <span>
          {start ? formatAsDateYear(start, language) : noDate}
          {" – "}
          {end ? formatAsDateYear(end, language) : noDate}
        </span>
      </FilterButton>
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          <DateRangePicker {...{ value, onChange, min, max }} />
        </Popover>
      )}
    </>
  );
};

const NoDate = styled("span")`
  color: ${styleSecondaryTextColor};
`;
const noDate = <NoDate>···</NoDate>;

const popoverStaticProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};
