import { BehaviorValue } from "rx-addons/BehaviorValue";
import { styled } from "@mui/material/styles";
import React from "react";
import { DataEntryBase, ListData, PaginationDestination } from "../types/data";
import { styleTableBorderColor } from "../utils/styles";
import { ColumnsConfigBase, ColumnsVisibility } from "../types/columns";
import { Criteria, PartialCriteria } from "../types/criteria";
import { FiltersConfigBase } from "../types/filters";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";

export namespace List {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    title: string;
    columns: ColumnsConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: PartialCriteria<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    data$: BehaviorValue<ListData<DataEntry>>;

    onPageChange?: (page: PaginationDestination) => void;
  };
}

export const List = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  title,
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  criteria$,
  onCriteriaChange,
  data$,
  onPageChange,
}: List.Props<DataEntry, ColumnsConfig, FiltersConfig>) => (
  <Wrapper>
    <Header {...{ title, criteria$ }} />
    <Table
      {...{
        columns,
        columnsVisibility$,
        onColumnsVisibilityChange,
        data$,
        criteria$,
        onCriteriaChange,
      }}
    />
    <Footer {...{ data$, onPageChange }} />
  </Wrapper>
);

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;

  border: 1px solid ${styleTableBorderColor};
  border-radius: ${({ theme }) => theme.spacing(3)};
`;
