import { useBehaviorValue } from "react-rx/behaviorValue";
import React, { useCallback, useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  Criteria,
  PartialCriteria,
} from "@layouts/ListingTable/types/criteria";
import { DataEntryBase } from "./types/data";
import { ColumnsConfigBase } from "./types/columns";
import {
  FilterConfig,
  FilterGroup,
  FiltersConfigBase,
  PredefinedFilters,
  FilterProps,
} from "./types/filters";

export namespace FilterAdapter {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Config extends FilterConfig,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: PartialCriteria<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    Filter: React.FC<FilterProps<Config>>;
  } & (
    | {
        filterGroup: FilterGroup.predefined;
        filterId: keyof PredefinedFilters;
      }
    | {
        filterGroup: FilterGroup.custom;
        filterId: keyof FiltersConfig[FilterGroup.custom];
      }
  );
}

export const FilterAdapter = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Config extends FilterConfig,
>(
  props: FilterAdapter.Props<DataEntry, ColumnsConfig, FiltersConfig, Config>,
) => {
  const { filters, filterGroup, filterId } = props;

  // @ts-expect-error fixme
  const config: Config | undefined = filters[filterGroup][filterId];

  if (!config) return null;

  return (
    <FilterRender<DataEntry, ColumnsConfig, FiltersConfig, Config>
      {...{ ...props, config }}
    />
  );
};

export const FilterRender = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Config extends FilterConfig,
>({
  config,
  criteria$,
  onCriteriaChange,
  filterGroup,
  filterId,
  Filter,
}: FilterAdapter.Props<DataEntry, ColumnsConfig, FiltersConfig, Config> & {
  config: Config;
}) => {
  type Value = Config["defaultValue"];

  const criteria = useBehaviorValue(criteria$);

  const value = useMemo<Value>(
    () => ({
      ...config.defaultValue,

      ...(filterGroup === FilterGroup.predefined
        ? criteria.filters[filterGroup][filterId]
        : {}),

      ...(filterGroup === FilterGroup.custom
        ? criteria.filters[filterGroup][filterId]
        : {}),
    }),
    [config.defaultValue, criteria.filters, filterGroup, filterId],
  );

  const onChange = useCallback(
    (value: Value) => {
      onCriteriaChange({ filters: { [filterGroup]: { [filterId]: value } } });
    },
    [filterGroup, filterId, onCriteriaChange],
  );

  return <Filter {...{ config, value, onChange }} />;
};
