import { ReactElement, useMemo } from "react";
import { RootState } from "state-manager";
import { flow, pipe } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { ISODate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { DeleteConfirmation } from "@Containers/Listing/DeleteConfirmation";
import { useTranslation } from "i18n";
import { Typed } from "utils/Typed";
import { ListingState } from "../../../../../../../../../packages/state-manager/src/generic-states/Listing";
import { ListingHeader } from "./ListingHeader";
import { Table, TableProps } from "./Table";

const state = DataManager.pickingOrderState.subStates.listing;

export interface AllProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof state.states>["ready" | "fetching"];
  dispatch: (a: ListingState.GetActions<typeof state>) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const { t } = useTranslation();
  const columns$ = useMemo(() => () => [], [selector$]);
  const items$: TableProps["items$"] = useMemo(
    () =>
      flow(selector$, (s) =>
        s.payload.items.map((i) => ({
          ...i,
          fields: {},
          createdAt: ISODate.toDate(i.createdAt),
          updatedAt: pipe(i.updatedAt, O.map(ISODate.toDate)),
          selected: s.payload.selected.includes(i.id),
        })),
      ),
    [selector$],
  );

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          columns$={columns$}
          items$={items$}
          onDelete={flow(state.actions.removeItem.create, dispatch)}
          onDeleteAll={flow(state.actions.removeBulk.create, dispatch)}
          onSelect={flow(state.actions.select.create, dispatch)}
          onSelectAll={flow(state.actions.selectAll.create, dispatch)}
          orderBy={flow(state.actions.orderBy.create, dispatch)}
          orderBy$={flow(selector$, (s) => s.payload.order, O.fromNullable)}
        />
      </ListingWrapper>
      <DeleteConfirmation
        selector$={selector$}
        dispatch={dispatch}
        getName={(c) => (c === 1 ? t("order") : t("orders"))}
        // @ts-expect-error, fix later
        instance={state}
      />
    </>
  );
}
