import { ReactElement } from "react";
import { useTranslation } from "i18n";
import { Typography } from "ui/components/Typography";
import { Selector } from "state-manager";
import { RepositoryMovementsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";

export interface HeaderProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Header(p: HeaderProps): ReactElement {
  const { t } = useTranslation();
  return <Typography>{t("Repository Movements")}</Typography>;
}
