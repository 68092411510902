import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { CustomersListingAll } from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";
import { CustomersListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import * as ListingAllContainer from "./ListingAll";
import * as ListingContainer from "./Listing";

export interface FooterProps {
  selector: Selector<Customers.State>;
  dispatch: (a: Customers.Actions) => void;
}

export function Footer(p: FooterProps) {
  const listing$ = flow(p.selector, (v) => v.payload.listing);
  const r = useSelector(
    flow(listing$, (s) => {
      if (CustomersListingAll.instance.isState(s))
        return {
          type: "listing-all",
          selector: flow(listing$, (st) => st as typeof s),
        } as const;

      if (Listing.instance.isState(s))
        return {
          type: "listing",
          selector: flow(listing$, (st) => st as typeof s),
        } as const;

      silentUnreachableError(s);
      return {
        type: "unknown",
        selector: flow(p.selector, (st) => st as typeof s),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "listing":
      return (
        <ListingContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
