import { RootState } from "state-manager";
import { useTranslation } from "i18n";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Input } from "@Containers/Form/Input";
import { Textarea } from "@Containers/Form/Textarea";
import { Toggle } from "@Containers/Form/Toggle";
import { Combobox } from "@Containers/Form/Combobox";
import { useMemo } from "react";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { dataTypeEntities, DataTypeEntity } from "types/src/DataType/DataType";
import { flow } from "fp-ts/function";

interface FormProps {
  name: string;
  description: string;
  default: boolean;
  entity: DataTypeEntity;
}

interface Dispatcher {
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  setDefault: (default$: boolean) => void;
  setEntity: (entity: DataTypeEntity) => void;
}

export interface DataTypeFormProps {
  selector: (s: RootState) => FormProps;
  dispatch: Dispatcher;
}

export function DataTypeForm({ selector, dispatch }: DataTypeFormProps) {
  const { t } = useTranslation();
  const entityTitle = useDataTypeEntityTitle();
  const name$ = flow(selector, nameSelector);
  const desc$ = flow(selector, descSelector);
  const default$ = flow(selector, defaultSelector);
  const entity$ = flow(selector, entitySelector);

  const entities = useMemo(() => {
    return dataTypeEntities.map((entity) => ({
      value: entity,
      label: entityTitle(entity),
    }));
  }, [entityTitle]);

  return (
    <FormWrapper>
      <Input value$={name$} onChange={dispatch.setName} label={t("Name")} />
      <Textarea
        rows={5}
        label={t("Description")}
        value$={desc$}
        onChange={dispatch.setDescription}
      />
      <Toggle value$={default$} onChange={dispatch.setDefault}>
        {t("Make default")}
      </Toggle>
      <Combobox<DataTypeEntity>
        value$={entity$}
        onChange={dispatch.setEntity}
        options={entities}
      />
    </FormWrapper>
  );
}

const nameSelector = (s: FormProps) => s.name;
const descSelector = (s: FormProps) => s.description;
const defaultSelector = (s: FormProps) => s.default;
const entitySelector = (s: FormProps) => s.entity;
