import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { FilterAdapter } from "../../FilterAdapter";
import { Criteria, PartialCriteria } from "../../types/criteria";
import { DataEntryBase } from "../../types/data";
import { ColumnsConfigBase } from "../../types/columns";
import { FilterGroup, FiltersConfigBase } from "../../types/filters";
import { DateRange } from "./DateRange";
import { TextSearch } from "./TextSearch";

export namespace HeaderFilters {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: PartialCriteria<keyof ColumnsConfig, FiltersConfig>,
    ) => void;
    customButtons?: ReactNode;
  };
}

export const HeaderFilters = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  filters,
  criteria$,
  onCriteriaChange,
  customButtons,
}: HeaderFilters.Props<DataEntry, ColumnsConfig, FiltersConfig>) => (
  <Wrapper>
    {/* @ts-expect-error, Review later */}
    <FilterAdapter<DataEntry, ColumnsConfig, FiltersConfig>
      onCriteriaChange={onCriteriaChange}
      filters={filters}
      criteria$={criteria$}
      filterGroup={FilterGroup.predefined}
      filterId="search"
      Filter={TextSearch}
    />
    <Right>
      {customButtons}
      {/* @ts-expect-error, Review later */}
      <FilterAdapter<DataEntry, ColumnsConfig, FiltersConfig>
        onCriteriaChange={onCriteriaChange}
        filters={filters}
        criteria$={criteria$}
        filterGroup={FilterGroup.predefined}
        filterId="dateRange"
        Filter={DateRange}
      />
    </Right>
  </Wrapper>
);

const Wrapper = styled("div")`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const Right = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(4)};
`;
