import { RootState } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { InventoryItemsListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { DataTypeId } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ById } from "@Containers/Filters/ById";
import { SearchFields } from "@Containers/Filters/SearchFields";
import { DataTypes } from "@Containers/Filters/DataTypes";
import { Orphans } from "@Containers/Filters/Orphans";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { CreatedTo } from "@Containers/Filters/CreatedTo";

type Filters = Listing.State["payload"]["filters"];

interface DataType {
  id: DataTypeId;
  name: TranslatedStr;
}

export interface AdvancedFiltersFormProps {
  selector$: (s: RootState) => Filters;
  dataTypes$: (s: RootState) => Array<DataType>;
  dispatch: Dispatch<Listing.Actions>;
}

export function AdvancedFiltersForm(p: AdvancedFiltersFormProps): ReactElement {
  return (
    <FormWrapper>
      <ById
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <SearchFields
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <DataTypes
        dataTypes$={p.dataTypes$}
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <Orphans
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <CreatedFrom
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
      <CreatedTo
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={Listing.instance.subStates.filters.actions}
      />
    </FormWrapper>
  );
}
