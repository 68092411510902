import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, Selector, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import * as O from "fp-ts/Option";
import { AdvancedFiltersForm } from "@Containers/Ready/DataManager/Customers/ListingAll/components/AdvancedFiltersForm";
import { AdvancedFilters } from "@Containers/Listing/AdvancedFilters";
import { NoEmptyString } from "types/src/NoEmptyString";
import { CustomersListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";
import { match } from "fp-utilities";

export interface FiltersProps {
  dataTypes$: Selector<Listing.State["payload"]["dataTypes"]>;
  listing$: Selector<
    | ReturnType<typeof Listing.instance.states.ready.create>
    | ReturnType<typeof Listing.instance.states.fetching.create>
  >;
  dispatch: Dispatch<Listing.Actions>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const filters$ = flow(p.listing$, (s) => s.payload.filters);
  const search$ = flow(
    filters$,
    (s) => s.payload.fields.search,
    O.getOrElse(() => ""),
  );
  const isOpen$ = flow(
    p.listing$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const dataTypes$ = flow(
    p.dataTypes$,
    match(
      [Listing.instance.dataTypes.states.ready.is, (v) => v.payload],
      [Listing.instance.dataTypes.states.loading.is, () => []],
      [Listing.instance.dataTypes.states.loadError.is, () => []],
    ),
    (v) => v as AdvancedFiltersForm.DataType[],
  );

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(
          NoEmptyString.fromString,
          (search) =>
            Listing.instance.subStates.filters.actions.setValue.create({
              search,
            }),
          p.dispatch,
        )}
      />
      <FiltersTrigger
        isSelected$={flow(
          filters$,
          (s) => !Listing.instance.subStates.filters.states.idle.is(s),
        )}
        onClear={flow(
          Listing.instance.subStates.filters.actions.reset.create,
          p.dispatch,
        )}
        onClick={flow(
          Listing.instance.actions.openAdvancedFilters.create,
          p.dispatch,
        )}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(
          Listing.instance.actions.closeAdvancedFilters.create,
          p.dispatch,
        )}
        onClear={flow(
          Listing.instance.subStates.filters.actions.reset.create,
          p.dispatch,
        )}
        onApply={flow(
          Listing.instance.subStates.filters.actions.submit.create,
          p.dispatch,
        )}
      >
        <AdvancedFiltersForm
          dispatch={p.dispatch}
          selector$={filters$}
          dataTypes$={dataTypes$}
          actions={Listing.instance.subStates.filters.actions}
        />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}
