import { JSONSchema7 } from "json-schema";
import { DataTypeSchema } from "types/src/DataType/DataTypeSchema";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { jsonParse } from "utils/object";
import * as O from "fp-ts/lib/Option";
import { TranslatedStr } from "types/src/TranslatedStr";
import {
  DataTypeEntity as ApiDataTypeEntity,
  DataTypeFragmentFragment,
} from "../generated/graphql";

export function dataTypeToJsonSchema(schema: DataTypeSchema): JSONSchema7 {
  return schema;
}

export function apiEntityToDataTypeEntity(
  entity: ApiDataTypeEntity,
): DataTypeEntity {
  switch (entity) {
    case ApiDataTypeEntity.Attachment:
      return DataTypeEntity.Attachment;
    case ApiDataTypeEntity.CollectionMovement:
      return DataTypeEntity.CollectionMovement;
    case ApiDataTypeEntity.Customer:
      return DataTypeEntity.Customer;
    case ApiDataTypeEntity.Inbound:
      return DataTypeEntity.Inbound;
    case ApiDataTypeEntity.InboundItem:
      return DataTypeEntity.InboundItem;
    case ApiDataTypeEntity.Item:
      return DataTypeEntity.Item;
    case ApiDataTypeEntity.ItemSet:
      return DataTypeEntity.ItemSet;
    case ApiDataTypeEntity.Movement:
      return DataTypeEntity.Movement;
    case ApiDataTypeEntity.Order:
      return DataTypeEntity.Order;
    case ApiDataTypeEntity.OrderItem:
      return DataTypeEntity.OrderItem;
    case ApiDataTypeEntity.Other:
      return DataTypeEntity.Other;
    case ApiDataTypeEntity.Repository:
      return DataTypeEntity.Repository;
    case ApiDataTypeEntity.RepositoryBox:
      return DataTypeEntity.RepositoryBox;
    case ApiDataTypeEntity.RepositoryPallet:
      return DataTypeEntity.RepositoryPallet;
    case ApiDataTypeEntity.Supplier:
      return DataTypeEntity.Supplier;
  }
}

export function dataTypeEntityToApiEntity(
  entity: DataTypeEntity,
): ApiDataTypeEntity {
  switch (entity) {
    case DataTypeEntity.Attachment:
      return ApiDataTypeEntity.Attachment;
    case DataTypeEntity.CollectionMovement:
      return ApiDataTypeEntity.CollectionMovement;
    case DataTypeEntity.Customer:
      return ApiDataTypeEntity.Customer;
    case DataTypeEntity.Inbound:
      return ApiDataTypeEntity.InboundItem;
    case DataTypeEntity.InboundItem:
      return ApiDataTypeEntity.InboundItem;
    case DataTypeEntity.Item:
      return ApiDataTypeEntity.Item;
    case DataTypeEntity.ItemSet:
      return ApiDataTypeEntity.ItemSet;
    case DataTypeEntity.Movement:
      return ApiDataTypeEntity.Movement;
    case DataTypeEntity.Order:
      return ApiDataTypeEntity.Order;
    case DataTypeEntity.OrderItem:
      return ApiDataTypeEntity.OrderItem;
    case DataTypeEntity.Other:
      return ApiDataTypeEntity.Other;
    case DataTypeEntity.Repository:
      return ApiDataTypeEntity.Repository;
    case DataTypeEntity.RepositoryBox:
      return ApiDataTypeEntity.RepositoryBox;
    case DataTypeEntity.RepositoryPallet:
      return ApiDataTypeEntity.RepositoryPallet;
    case DataTypeEntity.Supplier:
      return ApiDataTypeEntity.Supplier;
  }
}

export function jsonSchemaToDataTypeSchema(
  schema: JSONSchema7,
): DataTypeSchema {
  return schema as DataTypeSchema;
}

export function dataTypeFragmentToDataType(
  fragment: DataTypeFragmentFragment,
): DataType {
  return {
    id: fragment.id as DataTypeId,
    name: fragment.name as TranslatedStr,
    description: fragment.description as TranslatedStr,
    entity: fragment.entity,
    default: fragment.default,
    schema: jsonSchemaToDataTypeSchema(
      jsonParse(fragment.jsonSchema) as JSONSchema7,
    ),
    createdAt: fragment.createdAt,
    updatedAt: O.fromNullable(fragment.updatedAt),
    deletedAt: O.fromNullable(fragment.deletedAt),
  };
}
