import { DataEntryBase } from "@layouts/ListingTable/types/data";
import {
  ColumnsConfigBase,
  ColumnsVisibility,
} from "@layouts/ListingTable/types/columns";
import Popover, { PopoverProps } from "@mui/material/Popover";
import React, { useMemo } from "react";
import { Columns as Icon } from "icons";
import { SxProps } from "@mui/material";
import { styled, Theme } from "@mui/material/styles";
import { usePopoverController } from "@layouts/ListingTable/hooks/usePopoverController";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export namespace VisibleColumnsPicker {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = {
    columns: ColumnsConfig;

    columnsVisibility: ColumnsVisibility<DataEntry, ColumnsConfig>;
    onColumnsVisibilityChange: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;
  };
}

export const VisibleColumnsPicker = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>({
  columns,
  columnsVisibility,
  onColumnsVisibilityChange,
}: VisibleColumnsPicker.Props<DataEntry, ColumnsConfig>) => {
  type ObjEntryColumn = [
    keyof ColumnsConfig, // fix for `Object.entries` string keys
    ColumnsConfig[keyof ColumnsConfig],
  ];
  type CheckboxesChangeHandlers = Record<
    keyof ColumnsConfig,
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >;

  const { onOpenPopoverButtonClick, popoverOpen, popoverProps } =
    usePopoverController();

  const checkboxChangeHandlers = useMemo(
    () =>
      Object.entries(columns)
        .map((entry) => entry as ObjEntryColumn)
        .reduce(
          (carry, [id, column]) => ({
            ...carry,
            [id]: ((_, checked) =>
              onColumnsVisibilityChange({
                ...columnsVisibility,
                [id]: checked,
              })) as CheckboxesChangeHandlers[keyof ColumnsConfig],
          }),
          {} as CheckboxesChangeHandlers,
        ),
    [columns, columnsVisibility, onColumnsVisibilityChange],
  );

  return (
    <>
      <Icon sx={sxIcon} onClick={onOpenPopoverButtonClick} />
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          <FormGroup>
            {Object.entries(columns)
              .map((entry) => entry as ObjEntryColumn)
              .map(([id, { label }]) => (
                <ColumnItem key={id.toString()}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={columnsVisibility[id]}
                        onChange={checkboxChangeHandlers[id]}
                        disableRipple
                      />
                    }
                    label={label}
                    sx={sxFormControl}
                  />
                </ColumnItem>
              ))}
          </FormGroup>
        </Popover>
      )}
    </>
  );
};

const sxIcon: SxProps<Theme> = (theme) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  cursor: "pointer",
});
const sxFormControl: SxProps<Theme> = (theme) => ({
  height: theme.spacing(5),
  ".MuiCheckbox-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

const ColumnItem = styled("div")(({ theme }) => ({
  padding: theme.spacing(2.5, 4),
}));

const popoverStaticProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};
