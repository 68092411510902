import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { silentUnreachableError } from "utils/exceptions";
import * as Rx from "rxjs";
import { Client } from "ds";
import { isT } from "fp-utilities";
import { strictGuard } from "utils/strictGuard";
import { DataTypeId } from "types/src/DataType/DataType";
import { Epic } from "../../../../../../types/RootEpic";
import { DataTypesListing as Listing } from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

const states = Typed.builder
  .add(
    "idle",
    (p: {
      listing: Listing.State;
      single: Create.State | Edit.State | undefined;
    }) => p,
  )
  .finish()("Ready:DataManager:DataTypes");

const isState = Typed.getGuard(states);

const actions = Typed.builder
  .add("exitSingle")
  .add("goToCreate")
  .add("goToListing")
  .add("goToEdit", (id: DataTypeId) => id)
  .finish()("Ready:DataManager:DataTypes:Actions");
const isActions = Typed.getGuard(actions);

const isAction = strictGuard((v: DataTypes.Actions): v is DataTypes.Actions => {
  return (
    isActions(v) ||
    Listing.instance.isAction(v) ||
    Create.isActions(v) ||
    Edit.isActions(v)
  );
});

function reducer(s: DataTypes.State, a: DataTypes.Actions): DataTypes.State {
  const listing = s.payload.listing;
  const single = s.payload.single;

  if (actions.goToListing.is(a)) {
    if (!Listing.instance.isState(s.payload.listing))
      return states.idle.create({
        listing: Listing.instance.init(),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToCreate.is(a)) {
    if (single && Create.isState(single)) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Create.init(),
    });
  }

  if (actions.goToEdit.is(a)) {
    if (single && Edit.isState(single) && single.payload.id === a.payload) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Edit.init({ id: a.payload }),
    });
  }

  if (actions.exitSingle.is(a)) {
    return Fp.pipe(
      O.fromNullable(single),
      O.map(() => states.idle.create({ listing, single: undefined })),
      O.getOrElse(() => s),
    );
  }

  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(listing)) {
      return Fp.pipe(
        Listing.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElse(() => s),
      );
    }

    return s;
  }

  if (Create.isActions(a)) {
    if (Create.isState(single)) {
      return Fp.pipe(
        Create.reducer(single, a),
        E.mapLeft((e) => {
          if (Create.exits.created.is(e)) {
            return states.idle.create({
              listing: Fp.pipe(
                Listing.instance.reducer(
                  s.payload.listing,
                  Listing.instance.actions.setPage.create("start"),
                ),
                E.getOrElseW(Listing.instance.init),
              ),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (Edit.isActions(a)) {
    if (Edit.isState(single)) {
      return Fp.pipe(
        Edit.reducer(single, a),
        E.mapLeft((e) => {
          if (Edit.exits.saved.is(e) || Edit.exits.removed.is(e)) {
            return states.idle.create({
              listing: reFetch(listing),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  silentUnreachableError(a);
  return s;
}

const epic: Epic<
  DataTypes.Actions,
  DataTypes.State,
  {
    pyckAdminClient$: Rx.Observable<Client>;
  }
> = (state$, ds$) => {
  const listings$ = state$.pipe(
    Rx.map((s) => s.payload.listing),
    Rx.distinctUntilChanged(),
  );
  const single$ = state$.pipe(
    Rx.map((s) => s.payload.single),
    Rx.distinctUntilChanged(),
    Rx.filter(isT),
  );

  const listing$ = Listing.instance.epic(
    listings$.pipe(Rx.filter(Listing.instance.isState)),
    ds$,
  );
  const create$ = Create.epic(single$.pipe(Rx.filter(Create.isState)), ds$);
  const edit$ = Edit.epic(single$.pipe(Rx.filter(Edit.isState)), ds$);

  return Rx.merge(listing$, create$, edit$);
};

function reFetch(
  s: DataTypes.State["payload"]["listing"],
): DataTypes.State["payload"]["listing"] {
  return Fp.pipe(
    Listing.instance.reducer(s, Listing.instance.actions.reFetch.create()),
    E.getOrElseW(() => Listing.instance.init()),
  );
}

function createInstance() {
  return {
    states,
    actions,
    isState,
    isAction,
    reducer,
    epic,
    init: () =>
      states.idle.create({
        listing: Listing.instance.init(),
        single: undefined,
      }),
    subStates: {
      listing: Listing.instance,
      create: Create,
      edit: Edit,
    },
  };
}

export namespace DataTypes {
  export type Actions =
    | Typed.GetTypes<typeof actions>
    | Listing.Actions
    | Create.Actions
    | Edit.Actions;

  export type State = Typed.GetTypes<typeof states>;

  export const instance = createInstance();
}
