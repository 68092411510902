import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { Login, login } from "../Auth";
import * as Ready from "./types/State";
import * as Actions from "./types/Actions";
import * as DataManager from "./states/DataManager";
import * as BuilderPreview from "./states/BuilderPreview";
import * as BPMNPreview from "./states/BPMNPreview";
import * as ZitadelPreview from "./states/ZitadelPreview";
import * as SandboxPreview from "./states/SandboxPreview";

import { Customers } from "./states/DataManager/states/Customers";

import { StocksListing } from "./states/DataManager/states/Stocks/states/Listing";
import * as StocksSingle from "./states/DataManager/states/Stocks/states/Single";

import { TransactionsListing } from "./states/DataManager/states/Transactions/states/Listing";
import * as TransactionsSingle from "./states/DataManager/states/Transactions/states/Single";
import { pickingOrderState } from "./states/DataManager/utils";
import { InventoryItems } from "./states/DataManager/states/InventoryItems";
import { ItemMovements } from "./states/DataManager/states/ItemMovements";
import { Repositories } from "./states/DataManager/states/Repositories";
import { RepositoryMovements } from "./states/DataManager/states/RepositoryMovements";
import { Suppliers } from "./states/DataManager/states/Suppliers";
import { PickingOrders } from "./states/DataManager/states/PickingOrder";

export function reducer(
  s: Ready.State,
  a: Actions.Actions,
): Ready.State | Login {
  if (DataManager.isActions(a)) {
    if (DataManager.isState(s.payload.subState)) {
      const subState = DataManager.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (BuilderPreview.isActions(a)) {
    if (BuilderPreview.isState(s.payload.subState)) {
      const subState = BuilderPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (BPMNPreview.isActions(a)) {
    if (BPMNPreview.isState(s.payload.subState)) {
      const subState = BPMNPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (ZitadelPreview.isActions(a)) {
    if (ZitadelPreview.isState(s.payload.subState)) {
      const subState = ZitadelPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (SandboxPreview.isActions(a)) {
    if (SandboxPreview.isState(s.payload.subState)) {
      const subState = SandboxPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? Ready.state({
            user: s.payload.user,
            organizations: {
              isActive: s.payload.organizations.isActive,
              ids: s.payload.organizations.ids,
              entities: s.payload.organizations.entities,
            },
            orgId: s.payload.orgId,
            openAI: s.payload.openAI,
            subState,
          })
        : s;
    }
    return s;
  }

  if (a.type === "Ready:SignOut") {
    if (Ready.isSigningOut(s.payload.subState)) return s;

    return Ready.state({
      user: s.payload.user,
      organizations: {
        isActive: s.payload.organizations.isActive,
        ids: s.payload.organizations.ids,
        entities: s.payload.organizations.entities,
      },
      orgId: s.payload.orgId,
      openAI: s.payload.openAI,
      subState: Ready.signingOut({}),
    });
  }

  if (a.type === "Ready:SignedOut") {
    if (!Ready.isSigningOut(s.payload.subState)) return s;

    return login({ goTo: { type: "customers" } });
  }

  if (a.type === "Ready:Reauthorize") {
    if (E.isLeft(s.payload.user)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        user: E.left({
          avatar: s.payload.user.right.avatar,
          username: s.payload.user.right.username,
          email: s.payload.user.right.email,
        }),
      },
    };
  }

  if (a.type === "Ready:ReauthorizeFail") {
    if (!E.isLeft(s.payload.user)) return s;

    return login({ goTo: { type: "customers" } });
  }

  if (a.type === "Ready:ReauthorizeSuccess") {
    if (!E.isLeft(s.payload.user)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        user: E.right({
          avatar: a.payload.avatar,
          username: a.payload.username,
          email: a.payload.email,
          accessToken: a.payload.accessToken,
        }),
      },
    };
  }

  if (Actions.isGoto(a)) {
    if (Ready.isSigningOut(s.payload.subState)) return s;

    const getSubState = (
      a: Actions.Goto,
    ): Ready.State["payload"]["subState"] => {
      const getDataManagerState = (
        subState: DataManager.State["payload"]["subState"],
      ): DataManager.State => {
        if (DataManager.isState(s.payload.subState))
          return {
            ...s.payload.subState,
            payload: {
              ...s.payload.subState.payload,
              subState,
            },
          } as DataManager.State;
        return {
          ...DataManager.init(),
          payload: {
            ...DataManager.init().payload,
            subState,
          },
        } as DataManager.State;
      };

      switch (a.type) {
        case "Ready:GoTo:DataTypes:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.map((s) => {
                switch (a.payload) {
                  case "customer":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Customers.instance.isState),
                      O.getOrElse(Customers.instance.init),
                      (s) =>
                        Customers.instance.reducer(
                          s,
                          Customers.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "item":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(InventoryItems.instance.isState),
                      O.getOrElse(InventoryItems.instance.init),
                      (s) =>
                        InventoryItems.instance.reducer(
                          s,
                          InventoryItems.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "order":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(PickingOrders.instance.isState),
                      O.getOrElse(PickingOrders.instance.init),
                      (s) =>
                        PickingOrders.instance.reducer(
                          s,
                          PickingOrders.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "repository":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Repositories.instance.isState),
                      O.getOrElse(Repositories.instance.init),
                      (s) =>
                        Repositories.instance.reducer(
                          s,
                          Repositories.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "repository-movement":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(RepositoryMovements.instance.isState),
                      O.getOrElse(RepositoryMovements.instance.init),
                      (s) =>
                        RepositoryMovements.instance.reducer(
                          s,
                          RepositoryMovements.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "item-movement":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(ItemMovements.instance.isState),
                      O.getOrElse(ItemMovements.instance.init),
                      (s) =>
                        ItemMovements.instance.reducer(
                          s,
                          ItemMovements.instance.actions.goToCreateType.create(),
                        ),
                    );
                  case "supplier":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Suppliers.instance.isState),
                      O.getOrElse(Suppliers.instance.init),
                      (s) =>
                        Suppliers.instance.reducer(
                          s,
                          Suppliers.instance.actions.goToCreateType.create(),
                        ),
                    );
                }
              }),
              O.getOrElseW(() => {
                return Customers.instance.reducer(
                  Customers.instance.init(),
                  Customers.instance.actions.goToCreateType.create(),
                );
              }),
            ),
          );
        case "Ready:GoTo:DataTypes:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.map((s) => {
                switch (a.payload.entity) {
                  case "customer":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Customers.instance.isState),
                      O.getOrElse(Customers.instance.init),
                      (s) =>
                        Customers.instance.reducer(
                          s,
                          Customers.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "item":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(InventoryItems.instance.isState),
                      O.getOrElse(InventoryItems.instance.init),
                      (s) =>
                        InventoryItems.instance.reducer(
                          s,
                          InventoryItems.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "order":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(PickingOrders.instance.isState),
                      O.getOrElse(PickingOrders.instance.init),
                      (s) =>
                        PickingOrders.instance.reducer(
                          s,
                          PickingOrders.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "repository":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Repositories.instance.isState),
                      O.getOrElse(Repositories.instance.init),
                      (s) =>
                        Repositories.instance.reducer(
                          s,
                          Repositories.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "repository-movement":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(RepositoryMovements.instance.isState),
                      O.getOrElse(RepositoryMovements.instance.init),
                      (s) =>
                        RepositoryMovements.instance.reducer(
                          s,
                          RepositoryMovements.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "item-movement":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(ItemMovements.instance.isState),
                      O.getOrElse(ItemMovements.instance.init),
                      (s) =>
                        ItemMovements.instance.reducer(
                          s,
                          ItemMovements.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                  case "supplier":
                    return Fp.pipe(
                      O.some(s),
                      O.filter(Suppliers.instance.isState),
                      O.getOrElse(Suppliers.instance.init),
                      (s) =>
                        Suppliers.instance.reducer(
                          s,
                          Suppliers.instance.actions.goToEditType.create(
                            a.payload.id,
                          ),
                        ),
                    );
                }
              }),
              O.getOrElseW(() => {
                return Customers.instance.reducer(
                  Customers.instance.init(),
                  Customers.instance.actions.goToEditType.create(a.payload.id),
                );
              }),
            ),
          );

        case "Ready:GoTo:Customers":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Customers:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Customers:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Customers:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:Suppliers":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:WarehouseBuilder":
          return BuilderPreview.init();
        case "Ready:GoTo:BPMNBuilder":
          return BPMNPreview.idle({});
        case "Ready:GoTo:ZitadelApp":
          return ZitadelPreview.idle({});
        case "Ready:GoTo:GraphQLSandbox":
          return SandboxPreview.idle({});

        case "Ready:GoTo:Repositories":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:RepositoryMovements":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToCreate.create(
                    a.payload,
                  ),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToEdit.create(
                    a.payload,
                  ),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToListing.create(
                    a.payload,
                  ),
                ),
            ),
          );

        case "Ready:GoTo:InventoryItems":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:ItemMovements":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:PickingOrders":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:Stocks:Listing":
          return getDataManagerState(StocksListing.instance.init());
        case "Ready:GoTo:Stocks:Single":
          return getDataManagerState(StocksSingle.loading({ id: a.payload }));

        case "Ready:GoTo:Transactions:Listing":
          return getDataManagerState(TransactionsListing.instance.init());
        case "Ready:GoTo:Transactions:Single":
          return getDataManagerState(
            TransactionsSingle.loading({ id: a.payload }),
          );
      }
    };

    return Ready.state({
      ...s.payload,
      organizations: {
        ...s.payload.organizations,
        isActive: false,
      },
      subState: getSubState(a),
    });
  }

  if (a.type === "Ready:ChangeActive:Organizations") {
    return {
      ...s,
      payload: {
        ...s.payload,
        organizations: {
          ...s.payload.organizations,
          isActive: a.payload,
        },
      },
    };
  }

  if (a.type === "Ready:ChangeOrgId:Organizations") {
    return {
      ...s,
      payload: {
        ...s.payload,
        orgId: a.payload,
        subState: DataManager.init(),
      },
    };
  }

  silentUnreachableError(a);
  return s;
}
