import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";
import { useTranslation } from "i18n";
import * as Obj from "utils/object";
import { ListingState } from "state-manager/generic-states/Listing";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type _ = any;

export namespace CustomersDeleteConfirmation {
  export type Instance<P extends string> = ReturnType<
    typeof ListingState.createState<P, _, _, _, _>
  >;

  export interface Props<P extends string> {
    selector$: (s: RootState) => ListingState.GetState<Instance<P>>;
    dispatch: (a: _) => void;
    instance: Instance<P>;
  }
}

export function CustomersDeleteConfirmation<P extends string>(
  p: CustomersDeleteConfirmation.Props<P>,
): ReactElement | null {
  const { t } = useTranslation();
  const removing$ = flow(
    p.selector$,
    O.of,
    O.filter(p.instance.states.ready.is),
    O.map((s) => Obj.values(s.payload.removing)),
  );
  const count = useSelector(
    flow(
      removing$,
      O.map((v) => v.filter((v) => v === "confirm").length),
      O.getOrElseW(() => 0),
    ),
  );
  const isRemoving = useSelector(
    flow(
      removing$,
      O.filter((s) => s.some((v) => v === "removing")),
      O.map(() => true),
      O.getOrElseW(() => false),
    ),
  );
  const isOpen = count > 0;
  const many = count > 1;

  if (!isOpen) return null;

  return (
    <DeleteModal
      title={many ? t("Remove customers") : t("Remove customer")}
      onConfirm={() => p.dispatch(p.instance.actions.removeConfirm.create())}
      onCancel={() => p.dispatch(p.instance.actions.removeDecline.create())}
      isLoading={isRemoving}
    >
      {t("Are you sure you want to delete this customer?")}
    </DeleteModal>
  );
}
