import { forwardRef, ReactElement } from "react";
import Cell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

export interface CheckCellProps {
  value: boolean;
  onChange: () => void;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CheckCell = forwardRef<HTMLTableCellElement, CheckCellProps>(
  ({ onChange, value, readonly, disabled }, ref): ReactElement => {
    return (
      <Cell ref={ref} padding={"checkbox"}>
        <Checkbox
          readOnly={readonly}
          disabled={disabled}
          checked={value}
          onChange={onChange}
        />
      </Cell>
    );
  },
);
