import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { silentUnreachableError } from "utils/exceptions";
import * as Rx from "rxjs";
import { Client } from "ds";
import { strictGuard } from "utils/strictGuard";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { Epic, mergeByGuard } from "../../../../../../types/RootEpic";
import * as DataTypesCreate from "../DataTypes/states/Create";
import * as DataTypesEdit from "../DataTypes/states/Edit";
import { PickingOrdersListingAll as ListingAll } from "./states/ListingAll";
import { PickingOrdersListing as Listing } from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

const create = Create.createState("Ready:DataManager:PickingOrders:Create");
const edit = Edit.createState("Ready:DataManager:PickingOrders:Edit");

const states = Typed.builder
  .add(
    "idle",
    (p: {
      listing: ListingAll.State | Listing.State;
      single:
        | Create.State<"Ready:DataManager:PickingOrders:Create">
        | Edit.State<"Ready:DataManager:PickingOrders:Edit">
        | DataTypesCreate.State
        | DataTypesEdit.State
        | undefined;
    }) => p,
  )
  .finish()("Ready:DataManager:PickingOrders");

const isState = Typed.getGuard(states);

const actions = Typed.builder
  .add("goToListingAll")
  .add("exitSingle")
  .add("goToCreate", (id: DataTypeId) => id)
  .add("goToCreateType")
  .add("goToListing", (id: DataTypeId) => id)
  .add("goToEdit", (id: PickingOrderId) => id)
  .add("goToEditType", (id: DataTypeId) => id)
  .finish()("Ready:DataManager:PickingOrders:Actions");
const isActions = Typed.getGuard(actions);

const isAction = strictGuard(
  (v: PickingOrders.Actions): v is PickingOrders.Actions => {
    return (
      isActions(v) ||
      ListingAll.instance.isAction(v) ||
      Listing.instance.isAction(v) ||
      create.isActions(v) ||
      edit.isActions(v) ||
      DataTypesCreate.isActions(v) ||
      DataTypesEdit.isActions(v)
    );
  },
);

function reducer(
  s: PickingOrders.State,
  a: PickingOrders.Actions,
): PickingOrders.State {
  const listing = s.payload.listing;
  const single = s.payload.single;

  if (actions.goToListingAll.is(a)) {
    if (!ListingAll.instance.isState(s.payload.listing))
      return states.idle.create({
        listing: ListingAll.instance.init(),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToListing.is(a)) {
    if (
      !Listing.instance.isState(s.payload.listing) ||
      s.payload.listing.payload.id !== a.payload
    )
      return states.idle.create({
        listing: Listing.instance.init({ id: a.payload }),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToCreate.is(a)) {
    if (
      single &&
      create.isState(single) &&
      single.payload.dataTypeId === a.payload
    ) {
      return s;
    }

    return states.idle.create({
      listing,
      single: create.init({ dataTypeId: a.payload }),
    });
  }

  if (actions.goToCreateType.is(a)) {
    if (single && DataTypesCreate.isState(single)) {
      return s;
    }

    return states.idle.create({
      listing,
      single: DataTypesCreate.init(DataTypeEntity.Order),
    });
  }

  if (actions.goToEdit.is(a)) {
    if (single && edit.isState(single) && single.payload.id === a.payload) {
      return s;
    }

    return states.idle.create({
      listing,
      single: edit.init({ id: a.payload }),
    });
  }

  if (actions.goToEditType.is(a)) {
    if (
      single &&
      DataTypesEdit.isState(single) &&
      single.payload.id === a.payload
    ) {
      return s;
    }

    return states.idle.create({
      listing,
      single: DataTypesEdit.init({ id: a.payload }),
    });
  }

  if (actions.exitSingle.is(a)) {
    return Fp.pipe(
      O.fromNullable(single),
      O.map(() => states.idle.create({ listing, single: undefined })),
      O.getOrElse(() => s),
    );
  }

  if (ListingAll.instance.isAction(a)) {
    if (ListingAll.instance.isState(listing)) {
      return Fp.pipe(
        ListingAll.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElse(() => s),
      );
    }

    return s;
  }

  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(listing)) {
      return Fp.pipe(
        Listing.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElseW((e) => {
          if (Listing.instance.exits.create.is(e))
            return states.idle.create({
              listing: listing,
              single: create.init({ dataTypeId: listing.payload.id }),
            });

          silentUnreachableError(e);
          return s;
        }),
      );
    }

    return s;
  }

  if (create.isActions(a)) {
    if (create.isState(single)) {
      return Fp.pipe(
        create.reducer(single, a),
        E.mapLeft((e) => {
          if (create.exits.created.is(e)) {
            return states.idle.create({
              listing:
                Listing.instance.isState(s.payload.listing) &&
                s.payload.listing.payload.id === e.payload.dataTypeId
                  ? Fp.pipe(
                      Listing.instance.reducer(
                        s.payload.listing,
                        Listing.instance.actions.setPage.create("start"),
                      ),
                      E.getOrElseW(() =>
                        Listing.instance.init({ id: e.payload.dataTypeId }),
                      ),
                    )
                  : Listing.instance.init({ id: e.payload.dataTypeId }),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (DataTypesCreate.isActions(a)) {
    if (DataTypesCreate.isState(single)) {
      return Fp.pipe(
        DataTypesCreate.reducer(single, a),
        E.mapLeft((e) => {
          if (DataTypesCreate.exits.created.is(e)) {
            return states.idle.create({
              listing:
                Listing.instance.isState(s.payload.listing) &&
                s.payload.listing.payload.id === e.payload
                  ? Fp.pipe(
                      Listing.instance.reducer(
                        s.payload.listing,
                        Listing.instance.actions.setPage.create("start"),
                      ),
                      E.getOrElseW(() =>
                        Listing.instance.init({ id: e.payload }),
                      ),
                    )
                  : Listing.instance.init({ id: e.payload }),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (edit.isActions(a)) {
    if (edit.isState(single)) {
      return Fp.pipe(
        edit.reducer(single, a),
        E.mapLeft((e) => {
          if (edit.exits.saved.is(e)) {
            return states.idle.create({
              listing: reFetch(listing),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (DataTypesEdit.isActions(a)) {
    if (DataTypesEdit.isState(single)) {
      return Fp.pipe(
        DataTypesEdit.reducer(single, a),
        E.mapLeft((e) => {
          if (DataTypesEdit.exits.saved.is(e)) {
            return states.idle.create({
              listing: reFetch(listing),
              single: undefined,
            });
          }

          if (DataTypesEdit.exits.removed.is(e)) {
            return states.idle.create({
              listing:
                Listing.instance.isState(listing) &&
                listing.payload.id === e.payload
                  ? ListingAll.instance.init()
                  : reFetch(listing),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  silentUnreachableError(a);
  return s;
}

const epic: Epic<
  PickingOrders.Actions,
  PickingOrders.State,
  {
    pyckAdminClient$: Rx.Observable<Client>;
  }
> = (state$, ds$) => {
  const listing$ = mergeByGuard([
    [ListingAll.instance.isState, ListingAll.instance.epic],
    [Listing.instance.isState, Listing.instance.epic],
  ])(state$.pipe(Rx.map((s) => s.payload.listing)), ds$);

  const single$ = mergeByGuard([
    [
      (v: PickingOrders.State["payload"]["single"]): v is undefined =>
        v === undefined,
      () => Rx.NEVER,
    ],
    [
      (
        v: PickingOrders.State["payload"]["single"],
      ): v is Exclude<PickingOrders.State["payload"]["single"], undefined> =>
        v !== undefined,
      (s$) =>
        mergeByGuard([
          [create.isState, create.epic],
          [edit.isState, edit.epic],
          [DataTypesCreate.isState, DataTypesCreate.epic],
          [DataTypesEdit.isState, DataTypesEdit.epic],
        ])(s$, ds$),
    ],
  ])(state$.pipe(Rx.map((s) => s.payload.single)), ds$);

  return Rx.merge(listing$, single$);
};

function reFetch(
  s: PickingOrders.State["payload"]["listing"],
): PickingOrders.State["payload"]["listing"] {
  if (Listing.instance.isState(s)) {
    return Fp.pipe(
      Listing.instance.reducer(s, Listing.instance.actions.reFetch.create()),
      E.getOrElseW(() => Listing.instance.init({ id: s.payload.id })),
    );
  }

  return Fp.pipe(
    ListingAll.instance.reducer(
      s,
      ListingAll.instance.actions.reFetch.create(),
    ),
    E.getOrElseW(() => ListingAll.instance.init()),
  );
}

function createInstance() {
  return {
    states,
    actions,
    isState,
    isAction,
    reducer,
    epic,
    init: () =>
      states.idle.create({
        listing: ListingAll.instance.init(),
        single: undefined,
      }),
    subStates: {
      listingAll: ListingAll.instance,
      listing: Listing.instance,
      create: create,
      edit: edit,
      dataTypesCreate: DataTypesCreate,
      dataTypesEdit: DataTypesEdit,
    },
  };
}

export namespace PickingOrders {
  export type Actions =
    | Typed.GetTypes<typeof actions>
    | ListingAll.Actions
    | Listing.Actions
    | Create.Actions<"Ready:DataManager:PickingOrders:Create">
    | Edit.Actions<"Ready:DataManager:PickingOrders:Edit">
    | DataTypesCreate.Actions
    | DataTypesEdit.Actions;

  export type State = Typed.GetTypes<typeof states>;

  export const instance = createInstance();
}
