import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Search as Icon } from "icons";
import { stylesIcon } from "../../../utils/styles";
import { FilterTypeTextSearch, FilterProps } from "../../../types/filters";

export const TextSearch = ({
  config,
  value,
  onChange,
}: FilterProps<FilterTypeTextSearch>) => {
  const { text } = value || {};

  return (
    <OutlinedInput
      size="small"
      sx={sx}
      startAdornment={icon}
      placeholder={config.options?.placeholder}
      value={text}
      onChange={({ target: { value: text } }) => onChange({ text })}
    />
  );
};

const sx: SxProps<Theme> = (theme) => ({
  width: theme.spacing(100),
  maxWidth: theme.spacing(100),
  padding: theme.spacing(2.5, 3.5),
  gap: theme.spacing(2),
  borderRadius: theme.spacing(2),
  ".MuiInputBase-input": {
    height: theme.spacing(6),
    padding: 0,
  },
});

const icon = <Icon sx={stylesIcon} />;
