import { RootState } from "state-manager";
import { Input } from "@Containers/Form/Input";
import { TranslatedStr } from "types/src/TranslatedStr";

interface FilterInputProps {
  value$: (s: RootState) => string;
  onChange: (v: string) => void;
  label: TranslatedStr;
  placeholder?: TranslatedStr;
}
export function FilterInput(p: FilterInputProps) {
  return (
    <Input
      label={p.label}
      value$={p.value$}
      onChange={p.onChange}
      placeholder={p.placeholder}
    />
  );
}
