import { ItemMovementsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { useTranslation } from "i18n";
import { ExecuteModal } from "ui/layouts/Dialogs/ExecuteModal";

export interface ExecuteConfirmationProps {
  selector$: (s: RootState) => Listing.State;
  dispatch: (a: Listing.Actions) => void;
}

export function ExecuteConfirmation(
  p: ExecuteConfirmationProps,
): ReactElement | null {
  const { t } = useTranslation();
  const count = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.instance.states.ready.is),
      O.map(
        (s) =>
          s.payload.items.filter((i) => !!s.payload.executing[i.id]).length,
      ),
      O.getOrElseW(() => 0),
    ),
  );
  const isLoading = useSelector(
    flow(
      p.selector$,
      O.of,
      O.filter(Listing.instance.states.ready.is),
      O.filter((s) =>
        s.payload.items.some((i) => s.payload.executing[i.id] === "executing"),
      ),
      O.map(() => true),
      O.getOrElseW(() => false),
    ),
  );
  const isOpen = count > 0;
  const many = count > 1;

  if (!isOpen) return null;

  return (
    <ExecuteModal
      title={many ? t("Execute items") : t("Execute item")}
      onConfirm={() =>
        p.dispatch(Listing.instance.actions.executeConfirm.create())
      }
      onCancel={() =>
        p.dispatch(Listing.instance.actions.executeDecline.create())
      }
      isLoading={isLoading}
    >
      {t("Are you sure to execute? This cannot be undone!")}
    </ExecuteModal>
  );
}
