import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { useTranslation } from "i18n";
import { styleTableBorderColor } from "../utils/styles";
import { FilterButton } from "../FilterButton";

export namespace Header {
  export type Props = {
    title: string;
  };
}

export const Header = ({ title }: Header.Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typography variant="h6">{title}</Typography>
      <FilterButton Icon={FilterListRoundedIcon}>{t("Filters")}</FilterButton>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => `${theme.spacing(5)} ${theme.spacing(6)}`};
  border-bottom: 1px solid ${styleTableBorderColor};
`;
