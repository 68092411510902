import { Typography } from "ui/components/Typography";
import { RootState, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow } from "fp-ts/function";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";

export interface HeaderProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Header({ selector }: HeaderProps): ReactElement | null {
  const name = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:DataTypes:Edit:Loading":
        case "Ready:DataManager:DataTypes:Edit:LoadError":
          return null;
        case "Ready:DataManager:DataTypes:Edit:Ready":
        case "Ready:DataManager:DataTypes:Edit:Removing":
        case "Ready:DataManager:DataTypes:Edit:RemoveConfirmation":
        case "Ready:DataManager:DataTypes:Edit:Saving":
          return s.payload.name;
      }
    }),
  );

  return name !== null ? <Typography>{name}</Typography> : null;
}
