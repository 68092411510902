import { RootState } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { FormWrapper } from "ui/layouts/FormWrapper";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { ById } from "@Containers/Filters/ById";
import { SearchFields } from "@Containers/Filters/SearchFields";
import { Orphans } from "@Containers/Filters/Orphans";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { CreatedTo } from "@Containers/Filters/CreatedTo";
import { ListingState } from "../../../../../../../../../packages/state-manager/src/generic-states/Listing";

const state = DataManager.pickingOrderState.subStates.listing;

export namespace AdvancedFiltersForm {
  export interface Props {
    selector$: (
      s: RootState,
    ) => ListingState.GetState<typeof state>["payload"]["filters"];
    dispatch: Dispatch<ListingState.GetActions<typeof state>>;
  }
}

export function AdvancedFiltersForm(
  p: AdvancedFiltersForm.Props,
): ReactElement {
  return (
    <FormWrapper>
      <ById
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={state.subStates.filters.actions}
      />
      <SearchFields
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={state.subStates.filters.actions}
      />
      <Orphans
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={state.subStates.filters.actions}
      />
      <CreatedFrom
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={state.subStates.filters.actions}
      />
      <CreatedTo
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={state.subStates.filters.actions}
      />
    </FormWrapper>
  );
}
