import { Autocomplete, FormControl, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";

export namespace AutocompleteMulti {
  export interface Props<T> {
    label?: string;
    search?: string;
    onSearch?: (s: string) => void;
    activeIndexes?: number[];
    placeholder?: string;
    options?: T[];
    onChange?: (value: T[]) => void;
    getLabel: (t: T) => string;
    error?: string | boolean;
  }
}

export function AutocompleteMulti<T>(p: AutocompleteMulti.Props<T>) {
  return (
    <FormControl error={!!p.error}>
      <Autocomplete<T, true>
        multiple
        disablePortal
        options={p.options ?? []}
        getOptionLabel={p.getLabel}
        renderInput={(props) => (
          <TextField
            variant={"outlined"}
            placeholder={p.placeholder}
            label={p.label}
            {...props}
          />
        )}
        onChange={(_, v) => p.onChange?.(v)}
        inputValue={p.search}
        onInputChange={(_, v) => p.onSearch?.(v)}
        size={"small"}
      />
      {typeof p.error === "string" ? (
        <FormHelperText>{p.error}</FormHelperText>
      ) : undefined}
    </FormControl>
  );
}
