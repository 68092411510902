import { useTranslation } from "i18n";
import { Typography } from "ui/components/Typography";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Edit";
import { Selector } from "state-manager";

export interface HeaderProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Typography>{t("Edit supplier")}</Typography>;
}
