import { RootState, useSelector } from "state-manager";
import { useTranslation } from "i18n";
import { Dispatch } from "react";
import * as Fp from "fp-ts/function";
import { DataTypeId } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";
import * as Arr from "fp-ts/Array";
import { shallowEqualObjects } from "shallow-equal";
import { MultiCombobox } from "@Containers/Form/MultiCombobox";
import * as Filters from "../../../../../packages/state-manager/src/generic-states/Filters";

export namespace DataTypes {
  export interface DataType {
    id: DataTypeId;
    name: TranslatedStr;
  }

  export type Filter = {
    dataTypes: DataTypeId[];
  };

  export interface Props<P extends string> {
    dataTypes$: (s: RootState) => DataType[];
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function DataTypes<P extends string>(p: DataTypes.Props<P>) {
  const { t } = useTranslation();
  const dataTypes = useSelector(
    Fp.flow(p.dataTypes$, (ds) =>
      ds.map((d) => ({ value: d.id, label: d.name })),
    ),
    Arr.getEq<{ value: DataTypeId; label: TranslatedStr }>({
      equals: shallowEqualObjects,
    }).equals,
  );

  return (
    <MultiCombobox<DataTypeId>
      label={t("DataTypes")}
      placeholder={t("All DataTypes")}
      value$={Fp.flow(p.selector$, (v) => v.payload.fields.dataTypes)}
      onChange={Fp.flow(
        (v) => p.actions.setValue.create({ dataTypes: v }),
        p.dispatch,
      )}
      options={dataTypes}
    />
  );
}
