import { RootState, useSelector } from "state-manager";
import { DataTypesListing } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { ReactElement } from "react";
import { silentUnreachableError } from "utils/exceptions";
import { Typography } from "ui/components/Typography";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";

export interface HeaderProps {
  selector: (s: RootState) => DataTypesListing.State;
  dispatch: (a: DataTypesListing.Actions) => void;
}

export function Header({ selector }: HeaderProps): ReactElement | null {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, getSelector),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return <Typography>{t("Data types")}</Typography>;
  }
}

interface Null {
  type: "null";
}
interface Paginated {
  type: "paginated";
}
type ContentType = Null | Paginated;

function getSelector(s: DataTypesListing.State): ContentType {
  if (DataTypesListing.instance.states.loading.is(s)) return { type: "null" };
  if (DataTypesListing.instance.states.loadError.is(s)) return { type: "null" };

  if (
    DataTypesListing.instance.states.ready.is(s) ||
    DataTypesListing.instance.states.fetching.is(s)
  ) {
    return {
      type: "paginated",
    };
  }

  silentUnreachableError(s);
  return { type: "null" };
}
