import {
  Body,
  Cell,
  Head,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "ui/components/Table";
import { useTranslation } from "i18n";
import { RootState, useSelector } from "state-manager";
import { StatusIndicator } from "ui/components/StatusIndicator";
import { DisabledMd, Typography } from "ui/components/Typography";
import { Tooltip } from "ui/components/Tooltip";
import { shallowEqualArrays, shallowEqualObjects } from "shallow-equal";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ReactElement } from "react";
import { Link } from "@Router/Link";
import { routes } from "@/router";

export interface Item<T extends DataTypeId> {
  id: T;
  title: string;
  entity: DataTypeEntity;
  isDefault: boolean;
  description: string;
}

export interface ItemsTableProps<T extends DataTypeId> {
  selector: (s: RootState) => T[];
  itemSelector: (id: T) => (s: RootState) => Item<T>;
  header?: ReactElement;
}

export function ItemsTable<T extends DataTypeId>({
  selector,
  itemSelector,
  header,
}: ItemsTableProps<T>) {
  const { t } = useTranslation();

  return (
    <ListingWrapper header={header}>
      <Table stickyHeader>
        <Head>
          <HeaderRow>
            <HeaderCell>{t("Title")}</HeaderCell>
            <HeaderCell>{t("Entity")}</HeaderCell>
            <HeaderCell size={"small"} />
            <HeaderCell>{t("Description")}</HeaderCell>
          </HeaderRow>
        </Head>
        <Body>
          <ItemsList selector={selector} itemSelector={itemSelector} />
        </Body>
      </Table>
    </ListingWrapper>
  );
}

interface ItemsListProps<T extends DataTypeId> {
  selector: (s: RootState) => T[];
  itemSelector: (id: T) => (s: RootState) => Item<T>;
}
function ItemsList<T extends DataTypeId>(p: ItemsListProps<T>) {
  const items = useSelector(p.selector, shallowEqualArrays);
  return items.map((id) => <ItemRow key={id} selector={p.itemSelector(id)} />);
}

interface ItemRowProps<T extends DataTypeId> {
  selector: (s: RootState) => Item<T>;
}
function ItemRow<T extends DataTypeId>({ selector }: ItemRowProps<T>) {
  const item = useSelector(selector, shallowEqualObjects);
  const { t } = useTranslation();
  const entityTitle = useDataTypeEntityTitle();

  return (
    <Row>
      <Cell tabIndex={1}>
        {item.title ? (
          <Typography
            component={Link}
            // @ts-expect-error, review later
            to={routes["/data-types/edit/:id"].create({ id: item.id })}
          >
            {item.title}
          </Typography>
        ) : (
          <DisabledMd
            component={Link}
            // @ts-expect-error, review later
            to={routes["/data-types/edit/:id"].create({ id: item.id })}
          >
            {t("No title")}
          </DisabledMd>
        )}
      </Cell>
      <Cell>
        <Typography variant={"caption"}>{entityTitle(item.entity)}</Typography>
      </Cell>
      <Cell align={"center"} size={"small"}>
        {item.isDefault ? (
          <Tooltip content={t("Default")}>
            <span>
              <StatusIndicator type={"available"} />
            </span>
          </Tooltip>
        ) : null}
      </Cell>
      <Cell $ellipsis>{item.description}</Cell>
    </Row>
  );
}
