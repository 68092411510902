import { alpha, Theme } from "@mui/material/styles";

const themeProvider =
  <Styles>(styler: (theme: Theme) => Styles) =>
  // Handle both `styled()(({ theme }) =>)` and `sx={theme =>}`
  (themeHolder: { theme: Theme } | Theme) => {
    const theme = "theme" in themeHolder ? themeHolder.theme : themeHolder;
    return styler(theme);
  };

export const styleTableBorderColor = themeProvider((theme) =>
  alpha(theme.palette.common.black, 0.1),
);

export const styleButtonBorderColor = themeProvider((theme) =>
  alpha(theme.palette.common.black, 0.23),
);

export const styleSecondaryTextColor = themeProvider((theme) =>
  alpha(theme.palette.common.black, 0.5),
);

export const stylesIcon = themeProvider((theme) => ({
  color: theme.palette.action.active,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));
