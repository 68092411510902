import * as Rx from "rxjs";
import { Client, DsError } from "ds";
import { Client as OpenAIClient } from "open-ai-ds";
import { getDataTypes } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isOneOf } from "utils/isOneOf";
import { Epic, mergeByGuard } from "../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

import { DataTypes } from "./states/DataTypes";
import { Customers } from "./states/Customers";
import { Suppliers } from "./states/Suppliers";
import { Repositories } from "./states/Repositories";
import { InventoryItems } from "./states/InventoryItems";
import { ItemMovements } from "./states/ItemMovements";
import { RepositoryMovements } from "./states/RepositoryMovements";
import * as Stocks from "./states/Stocks";
import * as Transactions from "./states/Transactions";
import { pickingOrderState } from "./utils";

const loadingEpic: Epic<
  Actions.Actions,
  State.Loading | State.LoadError,
  {
    pyckAdminClient$: Rx.Observable<Client>;
    openAIClient$: Rx.Observable<OpenAIClient>;
  }
> = (state$, { pyckAdminClient$ }) =>
  state$.pipe(
    Rx.distinctUntilKeyChanged("type"),
    Rx.filter(State.isLoading),
    Rx.map((s) => s.payload),
    Rx.withLatestFrom(pyckAdminClient$),
    Rx.switchMap(([, client]) => {
      return Rx.from(getDataTypes(client, {})).pipe(
        Rx.map(
          flow(
            E.map((v) => Actions.loadSuccess({ dataTypes: v.items })),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.loadFail({ type: "unknown" }),
            ),
          ),
        ),
        Rx.catchError(() => Rx.of(Actions.loadFail({ type: "unknown" }))),
      );
    }),
  );

const subStatesEpic: Epic<
  Actions.Actions,
  State.Ready,
  {
    pyckAdminClient$: Rx.Observable<Client>;
    openAIClient$: Rx.Observable<OpenAIClient>;
  }
> = (state$, ds$) => {
  return mergeByGuard([
    [DataTypes.instance.isState, DataTypes.instance.epic],
    [Customers.instance.isState, Customers.instance.epic],
    [Suppliers.instance.isState, Suppliers.instance.epic],
    [Repositories.instance.isState, Repositories.instance.epic],
    [InventoryItems.instance.isState, InventoryItems.instance.epic],
    [ItemMovements.instance.isState, ItemMovements.instance.epic],
    [RepositoryMovements.instance.isState, RepositoryMovements.instance.epic],
    [Stocks.isState, Stocks.epic],
    [Transactions.isState, Transactions.epic],
    [pickingOrderState.isState, pickingOrderState.epic],
  ])(state$.pipe(Rx.map((s) => s.payload.subState)), ds$);
};

export const epic = mergeByGuard([
  [isOneOf([State.isLoading, State.isLoadError]), loadingEpic],
  [State.isReady, subStatesEpic],
]);
