import { Selector, useSelector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { DataTypes } from "state-manager/states/Ready/states/DataManager/states/DataTypes";
import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import * as Stocks from "state-manager/states/Ready/states/DataManager/states/Stocks";
import * as Transactions from "state-manager/states/Ready/states/DataManager/states/Transactions";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import { ReactElement } from "react";
import * as DataTypesContainer from "./DataTypes";
import * as CustomersContainer from "./Customers";
import * as SuppliersContainer from "./Suppliers";
import * as RepositoriesContainer from "./Repositories";
import * as InventoryItemsContainer from "./InventoryItems";
import * as ItemMovementsContainer from "./ItemMovements";
import * as RepositoryMovementsContainer from "./RepositoryMovements";
import * as StocksContainer from "./Stocks";
import * as TransactionsContainer from "./Transactions";
import * as PickingOrders from "./PickingOrders";

export interface FooterProps {
  selector: Selector<DataManager.State>;
  dispatch: (a: DataManager.Actions) => void;
}

export function Footer(p: FooterProps): ReactElement | null {
  const r = useSelector(
    flow(
      p.selector,
      (s) => s.payload.subState,
      (s) => {
        if (DataTypes.instance.isState(s)) {
          return {
            type: "DataTypes",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Customers.instance.isState(s)) {
          return {
            type: "Customers",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Suppliers.instance.isState(s)) {
          return {
            type: "Suppliers",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Repositories.instance.isState(s)) {
          return {
            type: "Repositories",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (InventoryItems.instance.isState(s)) {
          return {
            type: "InventoryItems",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (ItemMovements.instance.isState(s)) {
          return {
            type: "ItemMovements",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (RepositoryMovements.instance.isState(s)) {
          return {
            type: "RepositoryMovements",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Stocks.isState(s)) {
          return {
            type: "Stocks",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Transactions.isState(s)) {
          return {
            type: "Transactions",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (DataManager.pickingOrderState.isState(s)) {
          return {
            type: "PickingOrders",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }

        silentUnreachableError(s);
        return { type: "unknown" } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "DataTypes":
      return (
        <DataTypesContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "Customers":
      return (
        <CustomersContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "Suppliers":
      return (
        <SuppliersContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "Repositories":
      return (
        <RepositoriesContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "InventoryItems":
      return (
        <InventoryItemsContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "ItemMovements":
      return (
        <ItemMovementsContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "RepositoryMovements":
      return (
        <RepositoryMovementsContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "Stocks":
      return (
        <StocksContainer.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "Transactions":
      return (
        <TransactionsContainer.Footer
          selector={r.selector}
          dispatch={p.dispatch}
        />
      );
    case "PickingOrders":
      return (
        <PickingOrders.Footer selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
