import { ReactNode } from "react";
import { Container, Grid2 } from "@mui/material";

import Toolbar from "@mui/material/Toolbar";

export interface PageLayoutProps {
  mainSidebar: ReactNode;
  secondarySidebar?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  className?: string;
}

export function PageLayout({
  mainSidebar,
  secondarySidebar,
  footer,
  header,
  children,
  className,
}: PageLayoutProps) {
  return (
    <Grid2
      container
      className={className}
      flexGrow={1}
      sx={{ height: "100vh" }}
    >
      <GridContainer row>
        {mainSidebar}
        {secondarySidebar}
      </GridContainer>

      <GridContainer grow>
        <GridContainer>
          <Toolbar>{header}</Toolbar>
        </GridContainer>
        <GridContainer row grow>
          <Grid2
            size={"grow"}
            sx={{
              maxHeight: "100%",
              minHeight: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container
              sx={{ minHeight: 0, flexGrow: 1, overflowY: "auto", py: 3 }}
            >
              {children}
            </Container>
            <Container sx={{ py: 3 }}>{footer}</Container>
          </Grid2>
        </GridContainer>
      </GridContainer>
    </Grid2>
  );
}

function GridContainer(p: {
  children?: ReactNode;
  grow?: boolean;
  row?: boolean;
}) {
  return (
    <Grid2
      container
      sx={{
        maxHeight: "100%",
        minHeight: 0,
        display: "flex",
        flexDirection: p.row ? "row" : "column",
        overflowY: "auto",
      }}
      size={p.grow ? "grow" : "auto"}
    >
      {p.children}
    </Grid2>
  );
}
