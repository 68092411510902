import { FilterType, PredefinedFilters } from "../types/filters";

export const predefinedFilters: Required<PredefinedFilters> = {
  search: {
    type: FilterType.textSearch,
  },
  dateRange: {
    type: FilterType.dateRange,
  },
};
