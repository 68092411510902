import { DataTypes } from "state-manager/states/Ready/states/DataManager/states/DataTypes";
import { DataTypesListing as Listing } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import * as ListingContainer from "./Listing";

export interface HeaderProps {
  selector: Selector<DataTypes.State>;
  dispatch: (a: DataTypes.Actions) => void;
}

export function Header(p: HeaderProps) {
  const r = useSelector(
    flow(
      p.selector,
      (v) => v.payload.listing,
      (s) => {
        if (Listing.instance.isState(s))
          return {
            type: "listing",
            selector: flow(
              p.selector,
              (v) => v.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "listing":
      return (
        <ListingContainer.Header selector={r.selector} dispatch={p.dispatch} />
      );
    case "unknown":
      return null;
  }
}
