import { useTranslation } from "i18n";
import { ReactElement } from "react";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import DoubleLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Left from "@mui/icons-material/KeyboardArrowLeft";
import DoubleRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import Right from "@mui/icons-material/KeyboardArrowRight";

export interface PaginationProps {
  hasPrev: boolean;
  hasNext: boolean;
  onChange: (page: "start" | "prev" | "next" | "end") => void;
}

export function Pagination({
  hasPrev,
  hasNext,
  onChange,
}: PaginationProps): ReactElement | null {
  const { t } = useTranslation();

  return (
    <Stack direction={"row"}>
      <Button
        startIcon={<DoubleLeft />}
        variant={"text"}
        onClick={() => onChange("start")}
        disabled={!hasPrev}
      >
        {t("First")}
      </Button>
      <Button
        startIcon={<Left />}
        variant={"text"}
        onClick={() => onChange("prev")}
        disabled={!hasPrev}
      >
        {t("Previous")}
      </Button>
      <Button
        endIcon={<Right />}
        variant={"text"}
        onClick={() => onChange("next")}
        disabled={!hasNext}
      >
        {t("Next")}
      </Button>
      <Button
        endIcon={<DoubleRight />}
        variant={"text"}
        onClick={() => onChange("end")}
        disabled={!hasNext}
      >
        {t("Last")}
      </Button>
    </Stack>
  );
}
