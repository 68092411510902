import React from "react";
import TableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { styled } from "@mui/material/styles";
import { ColumnsConfigBase, ColumnsVisibility } from "../../types/columns";
import { DataEntryBase, ListData } from "../../types/data";
import { FiltersConfigBase } from "../../types/filters";
import { Criteria, PartialCriteria } from "../../types/criteria";
import { Body } from "./Body";
import { Header } from "./Header";

export namespace Table {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    columns: ColumnsConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: PartialCriteria<keyof ColumnsConfig, FiltersConfig>,
    ) => void;

    data$: BehaviorValue<ListData<DataEntry>>;
  };
}

export const Table = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  criteria$,
  onCriteriaChange,
  data$,
}: Table.Props<DataEntry, ColumnsConfig, FiltersConfig>) => {
  return (
    <TableContainer component={Container}>
      <MuiTable size="small">
        <Header<DataEntry, ColumnsConfig, FiltersConfig>
          {...{
            columns,
            columnsVisibility$,
            onColumnsVisibilityChange,
            criteria$,
            onCriteriaChange,
          }}
        />
        <Body<DataEntry, ColumnsConfig>
          {...{ columns, columnsVisibility$, data$ }}
        ></Body>
      </MuiTable>
    </TableContainer>
  );
};

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  border: none;
`;
