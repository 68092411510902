import { useTranslation } from "i18n";
import { Button } from "@components/Button";
import { Footer as FooterLayout } from "../Footer";

export interface FooterProps {
  submit: {
    disabled: boolean;
    onClick: VoidFunction;
  };
}

export function Footer({ submit }: FooterProps) {
  const { t } = useTranslation();

  return (
    <FooterLayout>
      <Button onClick={submit.onClick} disabled={submit.disabled}>
        {t("Save")}
      </Button>
      {null}
    </FooterLayout>
  );
}
