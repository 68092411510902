import React, { ReactNode } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { styled } from "@mui/material/styles";
import {
  CellType as CellType_,
  ColumnsConfigBase as ColumnsConfigBase_,
  ColumnsVisibility as ColumnsVisibility_,
} from "./types/columns";
import {
  DataEntryBase,
  ListData as ListData_,
  PaginationDestination,
} from "./types/data";
import {
  FiltersConfigBase as FiltersConfigBase_,
  FilterType as FilterType_,
} from "./types/filters";
import {
  Criteria as Criteria_,
  PartialCriteria as PartialCriteria_,
} from "./types/criteria";
import { List } from "./List";
import { predefinedFilters as predefinedFilters_ } from "./constants/filters";
import { HeaderFilters } from "./filters/predefined/HeaderFilters";

export function ListingTable<
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase_<DataEntry>,
  FiltersConfig extends FiltersConfigBase_,
>({
  title,
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  loading$,
  data$,
  filters,
  criteria$,
  onCriteriaChange,
  onPageChange,
  headerButtons,
}: ListingTable.Props<DataEntry, ColumnsConfig, FiltersConfig>) {
  return (
    <Wrapper>
      <HeaderFilters<DataEntry, ColumnsConfig, FiltersConfig>
        {...{
          filters,
          criteria$,
          onCriteriaChange,
          customButtons: headerButtons,
        }}
      />
      <List<DataEntry, ColumnsConfig, FiltersConfig>
        {...{
          title,
          columns,
          columnsVisibility$,
          onColumnsVisibilityChange,
          loading$,
          criteria$,
          onCriteriaChange,
          data$,
          onPageChange,
        }}
      />
    </Wrapper>
  );
}

export namespace ListingTable {
  export const CellType = CellType_;

  export type ColumnsConfigBase<DataEntry extends DataEntryBase> =
    ColumnsConfigBase_<DataEntry>;

  export type ColumnsVisibility<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = ColumnsVisibility_<DataEntry, ColumnsConfig>;

  export const FilterType = FilterType_;

  export type FiltersConfigBase<CustomKeys extends string = string> =
    FiltersConfigBase_<CustomKeys>;

  export type Criteria<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = Criteria_<Columns, FiltersConfig>;

  export type PartialCriteria<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = PartialCriteria_<Columns, FiltersConfig>;

  export const predefinedFilters = predefinedFilters_;

  export type ListData<DataEntry extends DataEntryBase> = ListData_<DataEntry>;

  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    columns: ColumnsConfig;
    filters: FiltersConfig;

    title: string;
    headerButtons?: ReactNode;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange? /*renders Visible Columns picker*/ : (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<Criteria<keyof ColumnsConfig, FiltersConfig>>;
    onCriteriaChange: (
      criteria: PartialCriteria<keyof ColumnsConfig, FiltersConfig>,
    ) => void;

    loading$: BehaviorValue<boolean>;
    data$: BehaviorValue<ListData<DataEntry>>;

    onPageChange: (page: PaginationDestination) => void;
  };
}

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }
`;
