import { ReactElement, useCallback, useMemo } from "react";
import { CustomersListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";
import { Selector } from "state-manager";
import { flow } from "fp-ts/function";
import {
  Table,
  CustomersTableProps,
} from "@Containers/Ready/DataManager/Customers/ListingAll/components/Table";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ListingHeader } from "@Containers/Ready/DataManager/Customers/ListingAll/components/ListingHeader";
import * as O from "fp-ts/Option";
import { CustomersDeleteConfirmation } from "@Containers/Listing/CustomersDeleteConfirmation";

export interface AllCustomersProps {
  dataTypes$: Selector<Listing.State["payload"]["dataTypes"]>;
  listing$: Selector<
    | ReturnType<typeof Listing.instance.states.ready.create>
    | ReturnType<typeof Listing.instance.states.fetching.create>
  >;
  dispatch: (a: Listing.Actions) => void;
}

export function All({
  listing$,
  dataTypes$,
  dispatch,
}: AllCustomersProps): ReactElement {
  const items$: CustomersTableProps["items$"] = useMemo(
    () =>
      flow(listing$, (s) =>
        s.payload.items.map((i) => ({
          ...i,
          selected: s.payload.selected.includes(i.id),
        })),
      ),
    [listing$],
  );
  const orderBy$ = useMemo(
    () => flow(listing$, (s) => s.payload.order, O.fromNullable),
    [listing$],
  );

  const onSelect = useCallback(
    flow(Listing.instance.actions.select.create, dispatch),
    [dispatch],
  );
  const onSelectAll = useCallback(
    flow(Listing.instance.actions.selectAll.create, dispatch),
    [dispatch],
  );

  const onDelete = useCallback(
    flow(Listing.instance.actions.removeItem.create, dispatch),
    [dispatch],
  );
  const onDeleteAll = useCallback(
    flow(Listing.instance.actions.removeBulk.create, dispatch),
    [dispatch],
  );

  return (
    <>
      <ListingWrapper
        header={
          <ListingHeader
            dataTypes$={dataTypes$}
            listing$={listing$}
            dispatch={dispatch}
          />
        }
      >
        <Table
          items$={items$}
          orderBy$={orderBy$}
          orderBy={flow(Listing.instance.actions.orderBy.create, dispatch)}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <CustomersDeleteConfirmation
        // @ts-expect-error, fix later
        instance={Listing.instance}
        selector$={listing$}
        dispatch={dispatch}
      />
    </>
  );
}
