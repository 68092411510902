import { forwardRef, Ref, useRef, useState } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import Cell from "@mui/material/TableCell";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";

export interface ActionsCellAction {
  label: TranslatedStr;
  onClick: () => void;
}

export interface ActionsCellProps {
  className?: string;
  actions: ActionsCellAction[];
  label?: TranslatedStr;
}

export const ActionsCell = forwardRef<HTMLTableCellElement, ActionsCellProps>(
  (props, ref) => {
    return (
      <Cell ref={ref} className={props.className} padding={"checkbox"}>
        <OverflowMenu label={props.label} items={props.actions} />
      </Cell>
    );
  },
);

export const ActionsHeaderCell = (props: ActionsCellProps) => {
  return (
    <Cell className={props.className} padding={"checkbox"}>
      <OverflowMenu items={props.actions} label={props.label} />
    </Cell>
  );
};

const TooltipOverflowButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement>) =>
    props["aria-label"] ? (
      <Tooltip title={props["aria-label"]} placement="left">
        <IconButton {...props} ref={ref}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton {...props} ref={ref}>
        <MoreVertIcon />
      </IconButton>
    ),
);

interface OverflowMenuProps {
  label?: TranslatedStr;
  items: Array<{
    label: string;
    onClick?: () => void;
  }>;
}
const OverflowMenu = (p: OverflowMenuProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <TooltipOverflowButton
        ref={ref}
        onClick={() => setOpen((v) => !v)}
        aria-label={p.label}
      />
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={ref.current}>
        {p.items.map((a, i) => {
          return (
            <MenuItem
              key={i}
              onClick={() => {
                setOpen(false);
                a.onClick?.();
              }}
              value={a.label}
            >
              {a.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
