import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import { CustomersListing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import { SuppliersListing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import { RepositoriesListing } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Listing";
import { InventoryItemsListing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import { ItemMovementsListing } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import { RepositoryMovementsListing } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import * as Stocks from "state-manager/states/Ready/states/DataManager/states/Stocks";
import * as Transactions from "state-manager/states/Ready/states/DataManager/states/Transactions";
import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { flow, pipe } from "fp-ts/function";
import { useTranslation } from "i18n";
import * as ReadyActions from "state-manager/states/Ready";
import * as O from "fp-ts/Option";
import { TranslatedStr } from "types/src/TranslatedStr";
import { isShallowEqual } from "utils/object";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { isOneOf } from "utils/isOneOf";
import { SecondarySidebar } from "ui/components/SecondarySidebar";

interface Item {
  type: string;
  title: TranslatedStr;
  action: ReadyActions.Goto;
  isActive: boolean;
}

export interface SidebarProps {
  selector: Selector<DataManager.State>;
  dispatch: (action: ReadyActions.Goto) => void;
}

export function Sidebar(p: SidebarProps): ReactElement {
  const { t } = useTranslation();
  const current = useSelector(
    flow(p.selector, (s): Omit<NavItemProps, "onClick">[] => {
      return [
        {
          type: "customers",
          title: t("Customers"),
          action: ReadyActions.goToCustomers(),
          isActive: Customers.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Customer,
            ReadyActions.goToCustomersByDataType,
          )(s),
        },
        {
          type: "suppliers",
          title: t("Suppliers"),
          action: ReadyActions.goToSuppliers(),
          isActive: Suppliers.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Supplier,
            ReadyActions.goToSuppliersByDataType,
          )(s),
        },
        {
          type: "repositories",
          title: t("Repositories"),
          action: ReadyActions.goToRepositories(),
          isActive: Repositories.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Repository,
            ReadyActions.goToRepositoriesByDataType,
          )(s),
        },
        {
          type: "inventory-items",
          title: t("Inventory Items"),
          action: ReadyActions.goToInventoryItems(),
          isActive: InventoryItems.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Item,
            ReadyActions.goToInventoryItemsByDataType,
          )(s),
        },
        {
          type: "item-movements",
          title: t("Items Movements"),
          action: ReadyActions.goToItemMovements(),
          isActive: ItemMovements.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Movement,
            ReadyActions.goToItemMovementsByDataType,
          )(s),
        },
        {
          type: "repositories-movements",
          title: t("Repositories Movements"),
          action: ReadyActions.goToRepositoryMovements(),
          isActive: RepositoryMovements.instance.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Movement,
            ReadyActions.goToRepositoryMovementsByDataType,
          )(s),
        },
        {
          type: "stocks",
          title: t("Stocks"),
          action: ReadyActions.goToStocks(),
          isActive: Stocks.isState(s.payload.subState),
          subItems: [],
        },
        {
          type: "transactions",
          title: t("Transactions"),
          action: ReadyActions.goToTransactions(),
          isActive: Transactions.isState(s.payload.subState),
          subItems: [],
        },
        {
          type: "picking-orders",
          title: t("Picking Orders"),
          action: ReadyActions.goToPickingOrders(),
          isActive: DataManager.pickingOrderState.isState(s.payload.subState),
          subItems: getDataTypesByEntity(
            DataTypeEntity.Order,
            ReadyActions.goToPickingOrdersByDataType,
          )(s),
        },
      ].map((v) => ({
        ...v,
        isActive: v.isActive && !v.subItems.some((v) => v.isActive),
      }));
    }),
    isShallowEqual,
  );

  return (
    <SecondarySidebar title={t("Data Manager")}>
      {current.map((item) => (
        <NavItem
          key={item.type}
          action={item.action}
          isActive={item.isActive}
          title={item.title}
          type={item.type}
          subItems={item.subItems}
          onClick={p.dispatch}
        />
      ))}
    </SecondarySidebar>
  );
}

interface NavItemProps extends Item {
  subItems: Array<Item>;
  onClick: (item: Item["action"]) => void;
}
function NavItem(p: NavItemProps) {
  const { t } = useTranslation();

  return (
    <>
      <SecondarySidebar.Item
        title={p.title}
        onClick={() => p.onClick(p.action)}
        isCurrent={
          p.isActive || (p.subItems.some((v) => v.isActive) && "ancestor")
        }
      >
        {!!p.subItems.length &&
          p.subItems.map((item) => (
            <SecondarySidebar.SubItem
              key={item.type}
              onClick={() => p.onClick(item.action)}
              isCurrent={item.isActive}
              title={item.title || t("No title")}
            />
          ))}
      </SecondarySidebar.Item>
    </>
  );
}

function getDataTypesByEntity(
  entity: DataTypeEntity,
  action: (id: DataTypeId) => ReadyActions.Goto,
): (s: DataManager.State) => Item[] {
  return flow(
    O.of<DataManager.State>,
    O.filter(DataManager.isReady),
    O.map((s) => {
      return s.payload.dataTypes
        .filter((d) => d.entity === entity)
        .map(
          (d): Item => ({
            type: `${entity}:data-type:${d.id}`,
            title: d.name,
            action: action(d.id),
            isActive: pipe(
              s.payload.subState,
              O.of,
              O.map((s) => {
                if (Customers.instance.isState(s)) return s.payload.listing;
                if (InventoryItems.instance.isState(s))
                  return s.payload.listing;
                if (ItemMovements.instance.isState(s)) return s.payload.listing;
                if (Repositories.instance.isState(s)) return s.payload.listing;
                if (Suppliers.instance.isState(s)) return s.payload.listing;
                if (RepositoryMovements.instance.isState(s))
                  return s.payload.listing;
                if (DataManager.pickingOrderState.isState(s))
                  return s.payload.listing;

                return s;
              }),
              O.filter(
                isOneOf([
                  CustomersListing.instance.isState,
                  SuppliersListing.instance.isState,
                  RepositoriesListing.instance.isState,
                  InventoryItemsListing.instance.isState,
                  ItemMovementsListing.instance.isState,
                  RepositoryMovementsListing.instance.isState,
                  DataManager.pickingOrderState.subStates.listing.isState,
                ]),
              ),
              O.map((s) => s.payload.id === d.id),
              O.getOrElse<boolean>(() => false),
            ),
          }),
        );
    }),
    O.getOrElse<Item[]>(() => []),
  );
}
