import { useTranslation } from "i18n";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut, signOut } from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";
import { RootState, useSelector } from "state-manager";
import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import {
  Box,
  Database,
  Edit,
  LifeBuoy,
  Logo,
  Move,
  Paperclip,
  Refresh,
  Settings,
  ThreeLayers,
} from "icons";
import { MainSidebar as UiMain } from "ui/components/MainSidebar";

export interface MainSidebarProps {
  selector: (s: RootState) => Ready.State;
  dispatch: (a: Ready.Actions) => void;
}

export function MainSidebar({ selector, dispatch }: MainSidebarProps) {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s) => {
      if (s.payload.organizations.isActive) return "organizations";
      if (DataManager.isState(s.payload.subState)) return "data-manager";
      if (BuilderPreview.isState(s.payload.subState)) return "builder-preview";
      if (BPMNPreview.isState(s.payload.subState)) return "bpmn-preview";
      if (ZitadelPreview.isState(s.payload.subState)) return "zitadel-preview";
      if (SandboxPreview.isState(s.payload.subState)) return "sandbox-preview";

      if (isSigningOut(s.payload.subState)) return "signOut";

      silentUnreachableError(s.payload.subState);
      return undefined;
    }),
  );

  return (
    <UiMain>
      <UiMain.Item
        isCurrent={false}
        icon={<Logo />}
        title={t("Pyck")}
        onClick={() => dispatch(Ready.goToCustomers())}
      />

      <UiMain.Group>
        <UiMain.Item
          isCurrent={r === "data-manager"}
          icon={<ThreeLayers />}
          title={t("Data Manager")}
          onClick={() => dispatch(Ready.goToCustomers())}
        />
        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "data-base"}
          icon={<Database />}
          title={t("Database")}
          onClick={() => {}}
        />
        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "stocks"}
          icon={<Box />}
          title={t("Stocks")}
          onClick={() => dispatch(Ready.goToStocks())}
        />
        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "movements"}
          icon={<Move />}
          title={t("Movements")}
          onClick={() => {}}
        />

        <UiMain.Item
          isCurrent={r === "builder-preview"}
          icon={<Edit />}
          title={t("Warehouse builder")}
          onClick={() => dispatch(Ready.goToWarehouseBuilder())}
        />

        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "workflows"}
          icon={<Refresh />}
          title={t("Workflows")}
          onClick={() => {}}
        />

        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "attachments"}
          icon={<Paperclip />}
          title={t("Attachments")}
          onClick={() => {}}
        />
      </UiMain.Group>

      <UiMain.Separator />

      <UiMain.Group>
        <UiMain.Item
          icon={<Settings />}
          title={t("Settings")}
          onClick={() => {}}
          isCurrent={false}
        />
        <UiMain.Item
          icon={<LifeBuoy />}
          title={t("Need help?")}
          onClick={() => {}}
          isCurrent={false}
        />
      </UiMain.Group>
      <UiMain.Divider />
      <CurrentUserNavItem selector={selector} dispatch={dispatch} />
    </UiMain>
  );
}

function CurrentUserNavItem(p: {
  selector: (s: RootState) => Ready.State;
  dispatch: (a: Ready.Actions) => void;
}) {
  const getUser = flow(p.selector, (v) => v.payload.user, E.toUnion);
  const username = useSelector(flow(getUser, (v) => v.username));
  const email = useSelector(flow(getUser, (v) => v.email));
  const avatar = useSelector(flow(getUser, (v) => v.avatar));

  return (
    <UiMain.Avatar
      name={username}
      email={email}
      avatar={avatar}
      onLogout={() => p.dispatch(signOut())}
    />
  );
}
