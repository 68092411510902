import { BehaviorValue } from "rx-addons/BehaviorValue";
import { RootState, useStore } from "state-manager";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import * as Rx from "rxjs";
import { ReplaySubject, Subscription } from "rxjs";

const StateBehaviorContext = createContext<BehaviorValue<RootState>>(
  new BehaviorValue<RootState>(
    // @ts-expect-error, cannot have access to initial state here
    null,
    Rx.NEVER,
  ),
);

/**
 * @deprecated, this context exits only for migration period
 */
export function StateBehaviorProvider(p: { children?: ReactNode }) {
  const store = useStore();
  const unsubscribe = useRef<{
    store: () => void;
    b$: Subscription;
  } | null>(null);
  const behaviour = useMemo(() => new ReplaySubject<RootState>(1), []);
  const b$ = useMemo(() => new BehaviorValue(store.getState(), behaviour), []);

  if (!unsubscribe.current) {
    unsubscribe.current = {
      store: store.subscribe(() => {
        behaviour.next(store.getState());
      }),
      b$: b$.subscribe((v) => {
        // need to keep the behavior live
      }),
    };
  }

  useEffect(() => {
    return () => {
      unsubscribe.current?.store?.();
      unsubscribe.current?.b$.unsubscribe?.();
    };
  }, []);

  return (
    <StateBehaviorContext.Provider value={b$}>
      {p.children}
    </StateBehaviorContext.Provider>
  );
}

/**
 * @deprecated, this hook exits only for migration period
 */
export function useStateBehavior(): BehaviorValue<RootState> {
  return useContext(StateBehaviorContext);
}
