import { ReactElement } from "react";
import { ItemMovementsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { Typed } from "utils/Typed";
import { DeleteConfirmation } from "@Containers/Listing/DeleteConfirmation";
import { useTranslation } from "i18n";
import { ListingHeader } from "./ListingHeader";
import { Table } from "./Table";
import { ExecuteConfirmation } from "./ExecuteConfirmation";

export interface AllProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: (a: Listing.Actions) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          items$={flow(selector$, (s) =>
            s.payload.items.map((i) => ({
              ...i,
              selected: s.payload.selected.includes(i.id),
            })),
          )}
          onSelect={flow(Listing.instance.actions.select.create, dispatch)}
          onSelectAll={flow(
            Listing.instance.actions.selectAll.create,
            dispatch,
          )}
          onDeleteAll={flow(
            Listing.instance.actions.removeBulk.create,
            dispatch,
          )}
          onDelete={flow(Listing.instance.actions.removeItem.create, dispatch)}
          onExecuteAll={flow(
            Listing.instance.actions.executeBulk.create,
            dispatch,
          )}
          onExecute={flow(
            Listing.instance.actions.executeItem.create,
            dispatch,
          )}
        />
      </ListingWrapper>
      <DeleteConfirmation
        getName={(c) => (c === 1 ? t("item") : t("items"))}
        selector$={selector$}
        dispatch={dispatch}
        // @ts-expect-error, fix later
        instance={Listing.instance}
      />
      <ExecuteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
