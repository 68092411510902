import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { ThemeProvider } from "@components/ThemeProvider";

import Drawer from "@mui/material/Drawer";
import { Box, Divider, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";

import Grid from "@mui/material/Grid2";

export interface GlobalSheetProps {
  isOpen: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
}

export function GlobalSheet({
  children,
  isOpen,
  header,
  footer,
  onClose,
}: GlobalSheetProps) {
  return createPortal(
    <ThemeProvider theme="light">
      <Drawer open={isOpen} anchor={"right"}>
        {header && (
          <Box sx={{ px: 2, py: 1 }}>
            <Grid container>
              <Grid size={"grow"}>{header}</Grid>
              <Grid size={"auto"}>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        )}
        <Divider />
        <Box sx={{ p: 2, flex: 1, width: "340px" }}>{children}</Box>
        {footer && <Divider />}
        {footer && <Box sx={{ p: 2 }}>{footer}</Box>}
      </Drawer>
    </ThemeProvider>,
    document.body,
  );
}
