import { useDispatch, useSelector } from "state-manager";
import * as Auth from "state-manager/states/Auth";

import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as SandboxPreview from "state-manager/states/Ready/states/SandboxPreview";

import { DataTypes } from "state-manager/states/Ready/states/DataManager/states/DataTypes";
import { DataTypesListing } from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Listing";
import * as DataTypeCreate from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Create";
import * as DataTypeEdit from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";

import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { CustomersListingAll } from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";
import { CustomersListing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import * as CustomersCreate from "state-manager/states/Ready/states/DataManager/states/Customers/states/Create";
import * as CustomersEdit from "state-manager/states/Ready/states/DataManager/states/Customers/states/Edit";

import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { SuppliersListingAll } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { SuppliersListing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import * as SuppliersCreate from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Create";
import * as SuppliersEdit from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Edit";

import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { RepositoriesListingAll } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/ListingAll";
import { RepositoriesListing } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Listing";
import * as RepositoriesCreate from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Create";
import * as RepositoriesEdit from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Edit";

import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { InventoryItemsListingAll } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import { InventoryItemsListing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import * as InventoryItemsCreate from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Create";
import * as InventoryItemsEdit from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Edit";

import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { ItemMovementsListingAll } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/ListingAll";
import { ItemMovementsListing } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import * as ItemMovementsCreate from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Create";
import * as ItemMovementsEdit from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Edit";

import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import { RepositoryMovementsListingAll } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import { RepositoryMovementsListing } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import * as RepositoryMovementsCreate from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Create";
import * as RepositoryMovementsEdit from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Edit";

import { StocksListing } from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Listing";
import * as StocksSingle from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Single";

import { TransactionsListing } from "state-manager/states/Ready/states/DataManager/states/Transactions/states/Listing";
import * as TransactionsSingle from "state-manager/states/Ready/states/DataManager/states/Transactions/states/Single";

import { silentUnreachableError, unreachableError } from "utils/exceptions";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut } from "state-manager/states/Ready";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { flow } from "fp-ts/function";
import * as Router from "@/router";

export function RouterDispatcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const statePath = useSelector(
    flow(
      (s) => s.content,
      (s): Router.Route | undefined => {
        if (Auth.isState(s)) {
          if (Auth.isIdle(s)) return undefined;
          if (Auth.isAuthConfirmation(s)) return "/callback";
          if (Auth.isLogin(s)) return "/login";
          if (Auth.isAuthError(s)) return "/login";
          if (Auth.isAuthentication(s)) return "/login";
        }

        if (Ready.isState(s)) {
          const subState = s.payload.subState;

          if (isSigningOut(subState)) return "/login";

          if (DataManager.isState(subState)) {
            const dmSubState = subState.payload.subState;

            if (DataTypes.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                DataTypeEdit.isState(single) &&
                DataTypesListing.instance.isState(listing)
              ) {
                return Router.routes["/data-types/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                DataTypeCreate.isState(single) &&
                DataTypesListing.instance.isState(listing)
              ) {
                return Router.routes["/data-types/add"].create();
              }

              return Router.routes["/data-types"].create();
            }

            if (Customers.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                CustomersEdit.isState(single) &&
                CustomersListingAll.instance.isState(listing)
              ) {
                return Router.routes["/customers/edit/:id"].create({
                  id: single.payload.customerId,
                });
              }

              if (
                single !== undefined &&
                CustomersCreate.isState(single) &&
                CustomersListing.instance.isState(listing)
              ) {
                return Router.routes["/customers/:dataTypeId/add"].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (CustomersListing.instance.isState(listing)) {
                return Router.routes["/customers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/customers"].create();
            }

            if (InventoryItems.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                InventoryItemsEdit.isState(single) &&
                InventoryItemsListingAll.instance.isState(listing)
              ) {
                return Router.routes["/inventory-items/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                InventoryItemsCreate.isState(single) &&
                InventoryItemsListing.instance.isState(listing)
              ) {
                return Router.routes["/inventory-items/:dataTypeId/add"].create(
                  {
                    dataTypeId: single.payload.dataTypeId,
                  },
                );
              }

              if (InventoryItemsListing.instance.isState(listing)) {
                return Router.routes["/inventory-items/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/inventory-items"].create();
            }

            if (ItemMovements.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                ItemMovementsEdit.isState(single) &&
                ItemMovementsListingAll.instance.isState(listing)
              ) {
                return Router.routes["/item-movements/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                ItemMovementsCreate.isState(single) &&
                ItemMovementsListing.instance.isState(listing)
              ) {
                return Router.routes["/item-movements/:dataTypeId/add"].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (ItemMovementsListing.instance.isState(listing)) {
                return Router.routes["/item-movements/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/item-movements"].create();
            }

            if (DataManager.pickingOrderState.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                DataManager.pickingOrderState.subStates.edit.isState(single) &&
                DataManager.pickingOrderState.subStates.listingAll.isState(
                  listing,
                )
              ) {
                return Router.routes["/picking-orders/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                DataManager.pickingOrderState.subStates.create.isState(
                  single,
                ) &&
                DataManager.pickingOrderState.subStates.listing.isState(listing)
              ) {
                return Router.routes["/picking-orders/:dataTypeId/add"].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (
                DataManager.pickingOrderState.subStates.listing.isState(listing)
              ) {
                return Router.routes["/picking-orders/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/picking-orders"].create();
            }

            if (Suppliers.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                SuppliersEdit.isState(single) &&
                SuppliersListingAll.instance.isState(listing)
              ) {
                return Router.routes["/suppliers/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                SuppliersCreate.isState(single) &&
                SuppliersListing.instance.isState(listing)
              ) {
                return Router.routes["/suppliers/:dataTypeId/add"].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (SuppliersListing.instance.isState(listing)) {
                return Router.routes["/suppliers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/suppliers"].create();
            }

            if (Repositories.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                RepositoriesEdit.isState(single) &&
                RepositoriesListingAll.instance.isState(listing)
              ) {
                return Router.routes["/repositories/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                RepositoriesCreate.isState(single) &&
                RepositoriesListing.instance.isState(listing)
              ) {
                return Router.routes["/repositories/:dataTypeId/add"].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (RepositoriesListing.instance.isState(listing)) {
                return Router.routes["/repositories/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/repositories"].create();
            }

            if (RepositoryMovements.instance.isState(dmSubState)) {
              const single = dmSubState.payload.single;
              const listing = dmSubState.payload.listing;

              if (
                single !== undefined &&
                RepositoryMovementsEdit.isState(single) &&
                RepositoryMovementsListingAll.instance.isState(listing)
              ) {
                return Router.routes["/repository-movements/edit/:id"].create({
                  id: single.payload.id,
                });
              }

              if (
                single !== undefined &&
                RepositoryMovementsCreate.isState(single) &&
                RepositoryMovementsListing.instance.isState(listing)
              ) {
                return Router.routes[
                  "/repository-movements/:dataTypeId/add"
                ].create({
                  dataTypeId: single.payload.dataTypeId,
                });
              }

              if (RepositoryMovementsListing.instance.isState(listing)) {
                return Router.routes[
                  "/repository-movements/:dataTypeId"
                ].create({
                  dataTypeId: listing.payload.id,
                });
              }

              return Router.routes["/repository-movements"].create();
            }

            if (StocksListing.instance.isState(dmSubState))
              return Router.routes["/stocks"].create();
            if (StocksSingle.isState(dmSubState))
              return Router.routes["/stocks/:id"].create({
                id: dmSubState.payload.id,
              });

            if (TransactionsListing.instance.isState(dmSubState))
              return Router.routes["/transactions"].create();
            if (TransactionsSingle.isState(dmSubState))
              return Router.routes["/transactions/:id"].create({
                id: dmSubState.payload.id,
              });

            silentUnreachableError(dmSubState);
            return undefined;
          }

          if (BuilderPreview.isState(subState)) return "/warehouse-builder";
          if (BPMNPreview.isState(subState)) return "/bpmn-builder";
          if (ZitadelPreview.isState(subState)) return "/zitadel-app";
          if (SandboxPreview.isState(subState)) return "/graphql-sandbox";

          unreachableError(subState);
          return undefined;
        }

        unreachableError(s);
        return undefined;
      },
    ),
  );

  useEffect(() => {
    if (statePath && statePath !== location.pathname) {
      navigate(statePath);
    }
  }, [statePath]);

  useEffect(() => {
    if (location.pathname !== statePath) {
      const action = Router.getRedirectAction(location.pathname);

      switch (action?.type) {
        case "data-types:listing":
          dispatch(Ready.goToDataTypesListing());
          break;
        case "data-types:create":
          dispatch(Ready.goToDataTypesCreate());
          break;
        case "data-types:edit":
          dispatch(Ready.goToDataTypesEdit(action.id));
          break;
        case "customers":
          dispatch(Ready.goToCustomers());
          break;
        case "customers:create":
          dispatch(Ready.goToCustomersCreate(action.dataTypeId));
          break;
        case "customers:edit":
          dispatch(Ready.goToCustomersEdit(action.id));
          break;
        case "customers:data-type":
          dispatch(Ready.goToCustomersByDataType(action.id));
          break;
        case "suppliers":
          dispatch(Ready.goToSuppliers());
          break;
        case "suppliers:create":
          dispatch(Ready.goToSuppliersCreate(action.dataTypeId));
          break;
        case "suppliers:edit":
          dispatch(Ready.goToSuppliersEdit(action.id));
          break;
        case "suppliers:data-type":
          dispatch(Ready.goToSuppliersByDataType(action.id));
          break;
        case "warehouse-builder":
          dispatch(Ready.goToWarehouseBuilder());
          break;
        case "bpmn-builder":
          dispatch(Ready.goToBPMNBuilder());
          break;
        case "zitadel-app":
          dispatch(Ready.goToZitadelApp());
          break;
        case "graphql-sandbox":
          dispatch(Ready.goToGraphQLSandbox());
          break;
        case "repositories":
          dispatch(Ready.goToRepositories());
          break;
        case "repositories:create":
          dispatch(Ready.goToRepositoriesCreate(action.dataTypeId));
          break;
        case "repositories:edit":
          dispatch(Ready.goToRepositoriesEdit(action.id));
          break;
        case "repositories:data-type":
          dispatch(Ready.goToRepositoriesByDataType(action.id));
          break;
        case "inventoryItems":
          dispatch(Ready.goToInventoryItems());
          break;
        case "inventoryItems:create":
          dispatch(Ready.goToInventoryItemsCreate(action.dataTypeId));
          break;
        case "inventoryItems:edit":
          dispatch(Ready.goToInventoryItemsEdit(action.id));
          break;
        case "inventoryItems:data-type":
          dispatch(Ready.goToInventoryItemsByDataType(action.id));
          break;
        case "itemMovements":
          dispatch(Ready.goToItemMovements());
          break;
        case "itemMovements:create":
          dispatch(Ready.goToItemMovementsCreate(action.id));
          break;
        case "itemMovements:edit":
          dispatch(Ready.goToItemMovementsEdit(action.id));
          break;
        case "itemMovements:data-type":
          dispatch(Ready.goToItemMovementsByDataType(action.id));
          break;

        case "repositoryMovements":
          dispatch(Ready.goToRepositoryMovements());
          break;
        case "repositoryMovements:create":
          dispatch(Ready.goToRepositoryMovementsCreate(action.id));
          break;
        case "repositoryMovements:edit":
          dispatch(Ready.goToRepositoryMovementsEdit(action.id));
          break;
        case "repositoryMovements:data-type":
          dispatch(Ready.goToRepositoryMovementsByDataType(action.id));
          break;

        case "pickingOrders":
          dispatch(Ready.goToPickingOrders());
          break;
        case "pickingOrders:create":
          dispatch(Ready.goToPickingOrdersCreate(action.id));
          break;
        case "pickingOrders:edit":
          dispatch(Ready.goToPickingOrdersEdit(action.id));
          break;
        case "pickingOrders:data-type":
          dispatch(Ready.goToPickingOrdersByDataType(action.id));
          break;

        case "stocks":
          dispatch(Ready.goToStocks());
          break;
        case "stocks:single":
          dispatch(Ready.goToStock(action.id));
          break;
        case "transactions":
          dispatch(Ready.goToTransactions());
          break;
        case "transactions:single":
          dispatch(Ready.goToTransaction(action.id));
          break;
        case undefined:
          break;
        default:
          silentUnreachableError(action);
          break;
      }
    }
  }, [location.pathname]);

  return null;
}
