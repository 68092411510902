import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { SuppliersListingAll } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { SuppliersListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import * as Create from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Create";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Edit";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";

import { GlobalSheet } from "ui/components/Sheet";
import * as ListingAllContainer from "./ListingAll";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";
import * as ListingContainer from "./Listing";

export interface ContentProps {
  selector: Selector<Suppliers.State>;
  dispatch: (a: Suppliers.Actions) => void;
}

export function Content(p: ContentProps) {
  const content = useSelector(
    flow(
      p.selector,
      (s) => s.payload.listing,
      (s) => {
        if (SuppliersListingAll.instance.isState(s))
          return {
            type: "listing-all",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        if (Listing.instance.isState(s))
          return {
            type: "listing",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );
  const sheet = useSelector(
    flow(
      p.selector,
      (s) => s.payload.single,
      (s) => {
        if (s === undefined) {
          return {
            type: "unknown",
          } as const;
        }

        if (Create.isState(s))
          return {
            type: "create",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        if (Edit.isState(s))
          return {
            type: "edit",
            selector: flow(
              p.selector,
              (s) => s.payload.single,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  return (
    <>
      {getContent()}
      {getSheet()}
    </>
  );

  function getContent() {
    switch (content.type) {
      case "listing-all":
        return (
          <ListingAllContainer.Content
            selector={content.selector}
            dispatch={p.dispatch}
          />
        );
      case "listing":
        return (
          <ListingContainer.Content
            selector={content.selector}
            dispatch={p.dispatch}
          />
        );
      case "unknown":
        return null;
    }
  }

  function getSheet() {
    switch (sheet.type) {
      case "create":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Suppliers.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <CreateContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <CreateContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
          >
            <CreateContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "edit":
        return (
          <GlobalSheet
            isOpen
            onClose={Fp.flow(
              Suppliers.instance.actions.exitSingle.create,
              p.dispatch,
            )}
            header={
              <EditContainer.Header
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
            footer={
              <EditContainer.Footer
                selector={sheet.selector}
                dispatch={p.dispatch}
              />
            }
          >
            <EditContainer.Content
              selector={sheet.selector}
              dispatch={p.dispatch}
            />
          </GlobalSheet>
        );
      case "unknown":
        return <GlobalSheet isOpen={false} />;
    }
  }
}
