import { useTranslation } from "i18n";
import { Typography } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";

const state = DataManager.pickingOrderState.subStates.create;

type State = GetContructorType<typeof state.states>;
type Actions = GetContructorType<typeof state.actions>;

export interface HeaderProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Typography>{t("Add new order")}</Typography>;
}
