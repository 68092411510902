import { ReactElement, useMemo } from "react";
import { SuppliersListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import {
  SuppliersTable,
  SuppliersTableProps,
} from "@Containers/Ready/DataManager/Suppliers/ListingAll/components/SuppliersTable";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ListingHeader } from "@Containers/Ready/DataManager/Suppliers/ListingAll/components/ListingHeader";
import { DeleteConfirmation } from "@Containers/Listing/DeleteConfirmation";
import { useTranslation } from "i18n";
import { Typed } from "utils/Typed";
import * as O from "fp-ts/Option";

export interface AllSuppliersProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: (a: Listing.Actions) => void;
}

export function AllSuppliers({
  selector$,
  dispatch,
}: AllSuppliersProps): ReactElement {
  const { t } = useTranslation();
  const items$: SuppliersTableProps["items$"] = useMemo(
    () =>
      flow(selector$, (s) =>
        s.payload.items.map((i) => ({
          ...i,
          selected: s.payload.selected.includes(i.id),
        })),
      ),
    [selector$],
  );

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <SuppliersTable
          items$={items$}
          orderBy$={flow(selector$, (s) => s.payload.order, O.fromNullable)}
          orderBy={flow(Listing.instance.actions.orderBy.create, dispatch)}
          onSelect={flow(Listing.instance.actions.select.create, dispatch)}
          onSelectAll={flow(
            Listing.instance.actions.selectAll.create,
            dispatch,
          )}
          onDeleteAll={flow(
            Listing.instance.actions.removeBulk.create,
            dispatch,
          )}
          onDelete={flow(Listing.instance.actions.removeItem.create, dispatch)}
        />
      </ListingWrapper>
      <DeleteConfirmation
        getName={(c) => (c === 1 ? t("supplier") : t("suppliers"))}
        selector$={selector$}
        dispatch={dispatch}
        // @ts-expect-error, fix later
        instance={Listing.instance}
      />
    </>
  );
}
