import React, { useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import TableBody from "@mui/material/TableBody";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { getColumnsOrder } from "@layouts/ListingTable/utils/columns";
import { DataEntryBase, ListData } from "../../types/data";
import { ColumnsConfigBase } from "../../types/columns";
import { CellType } from "./CellType";

export namespace Body {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = {
    columns: ColumnsConfig;
    columnsVisibility$: BehaviorValue<
      Partial<Record<keyof ColumnsConfig, boolean>>
    >;
    data$: BehaviorValue<ListData<DataEntry>>;
  };
}

export const Body = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>({
  columns,
  columnsVisibility$,
  data$,
}: Body.Props<DataEntry, ColumnsConfig>) => {
  const columnsVisibility = useBehaviorValue(columnsVisibility$);
  const entries = useBehaviorValue(
    useMemo(() => data$.map(({ entries }) => entries), [data$]),
  );
  const columnsOrder = useMemo(
    () => getColumnsOrder<DataEntry, ColumnsConfig>(columns, columnsVisibility),
    [columns, columnsVisibility],
  );

  return (
    <TableBody>
      {entries.map((entry) => (
        <TableRow key={entry.id}>
          {columnsOrder.map((columnId) => {
            const column = columns[columnId];

            return (
              <TableCell key={columnId.toString()} sx={sxCell}>
                {
                  // fixme: Why `column` is `| undefined`?
                  //   Above is `column: ColumnsConfig[keyof ColumnsConfig]`
                  column && <CellType {...{ column, entry }} />
                }
              </TableCell>
            );
          })}
          <TableCell key="_actions" sx={sxCell}>
            {/*todo: actions*/}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const sxCell: SxProps<Theme> = (theme) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3, 6),
});
