import {
  Body,
  Cell,
  Head,
  HeaderCell,
  HeaderRow,
  Row,
  Table as TableComponent,
} from "ui/components/Table";
import { RootState, useSelector } from "state-manager";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { shallowEqualArrays } from "shallow-equal";
import { ReactElement, useMemo } from "react";
import { Ellipsis } from "ui/components/Typography";
import { Link } from "@Router/Link";
import { CheckHeadCell } from "ui/layouts/Listing/cell/CheckHeadCell";
import { CheckCell } from "ui/layouts/Listing/cell/CheckCell";
import {
  ActionsCell,
  ActionsHeaderCell,
} from "ui/layouts/Listing/cell/ActionsCell";
import { CopyTooltip } from "ui/components/CopyTooltip";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { eqByKey } from "utils/eq";
import { Tooltip } from "ui/components/Tooltip";
import { StatusIndicator } from "ui/components/StatusIndicator";
import { routes } from "@/router";

interface Item {
  id: ItemMovementId;
  collectionId: string;
  orderId: string;
  from: {
    id: RepositoryId;
    name: string;
  };
  to: {
    id: RepositoryId;
    name: string;
  };
  item: InventoryItemId;
  quantity: number;
  handler: string;
  executed: boolean;
  selected: boolean;
}

export interface TableProps {
  items$: (s: RootState) => Item[];
  onSelect: (id: ItemMovementId) => void;
  onDelete: (id: ItemMovementId) => void;
  onExecute: (id: ItemMovementId) => void;
  onSelectAll: () => void;
  onExecuteAll: () => void;
  onDeleteAll: () => void;
}

export function Table({
  items$,
  onSelect,
  onSelectAll,
  onDelete,
  onExecute,
  onDeleteAll,
  onExecuteAll,
}: TableProps): ReactElement {
  const selected$ = useMemo(
    () =>
      flow(
        items$,
        (s) => s.map((s) => s.selected),
        (s) =>
          s.every(Boolean)
            ? "checked"
            : s.some(Boolean)
            ? "indeterminate"
            : "unchecked",
      ),
    [items$],
  );

  return (
    <TableComponent stickyHeader>
      <Head>
        <Header
          selected$={selected$}
          onSelect={onSelectAll}
          onDelete={onDeleteAll}
          onExecute={onExecuteAll}
        />
      </Head>
      <Content
        items$={items$}
        onSelect={onSelect}
        onDelete={onDelete}
        onExecute={onExecute}
      />
    </TableComponent>
  );
}

interface HeaderProps {
  selected$: (s: RootState) => "checked" | "unchecked" | "indeterminate";
  onSelect: () => void;
  onExecute: () => void;
  onDelete: () => void;
}
function Header({
  selected$,
  onSelect,
  onDelete,
  onExecute,
}: HeaderProps): ReactElement {
  const { t } = useTranslation();
  const selected = useSelector(selected$);

  return (
    <HeaderRow>
      <CheckHeadCell value={selected} onChange={onSelect} />
      <HeaderCell>{t("Id")}</HeaderCell>
      <HeaderCell>{t("Collection Id")}</HeaderCell>
      <HeaderCell>{t("Order Id")}</HeaderCell>
      <HeaderCell>{t("From")}</HeaderCell>
      <HeaderCell>{t("To")}</HeaderCell>
      <HeaderCell>{t("Item")}</HeaderCell>
      <HeaderCell>{t("Quantity")}</HeaderCell>
      <HeaderCell>{t("Handler")}</HeaderCell>
      <HeaderCell />
      <ActionsHeaderCell
        actions={[
          {
            label: t("Execute"),
            onClick: onExecute,
          },
          {
            label: t("Delete"),
            onClick: onDelete,
          },
        ]}
      />
    </HeaderRow>
  );
}

interface ContentProps {
  items$: (s: RootState) => Item[];
  onSelect: (id: ItemMovementId) => void;
  onDelete: (id: ItemMovementId) => void;
  onExecute: (id: ItemMovementId) => void;
}
function Content({
  items$,
  onSelect,
  onDelete,
  onExecute,
}: ContentProps): ReactElement {
  const items = useSelector(
    flow(items$, (vs) => {
      return vs.map((v) => ({
        key: v.id,
        item$: flow(items$, (s) => s.find((d) => d.id === v.id) as Item),
      }));
    }),
    (a, b) =>
      shallowEqualArrays(
        a.map((v) => v.key),
        b.map((v) => v.key),
      ),
  );

  return (
    <Body>
      {items.map(({ key, item$ }) => (
        <ItemRow
          key={key}
          item$={item$}
          onSelect={onSelect}
          onDelete={onDelete}
          onExecute={onExecute}
        />
      ))}
    </Body>
  );
}

interface ItemRowProps {
  item$: (s: RootState) => Item;
  onSelect: (id: ItemMovementId) => void;
  onDelete: (id: ItemMovementId) => void;
  onExecute: (id: ItemMovementId) => void;
}
function ItemRow({ item$, onSelect, onDelete, onExecute }: ItemRowProps) {
  const { t } = useTranslation();
  const id = useSelector(flow(item$, (s) => s.id));
  const collectionId = useSelector(flow(item$, (s) => s.collectionId));
  const orderId = useSelector(flow(item$, (s) => s.orderId));
  const from = useSelector(
    flow(item$, (s) => s.from),
    eqByKey("id"),
  );
  const to = useSelector(
    flow(item$, (s) => s.to),
    eqByKey("id"),
  );
  const item = useSelector(flow(item$, (s) => s.item));
  const quantity = useSelector(flow(item$, (s) => s.quantity));
  const handler = useSelector(flow(item$, (s) => s.handler));
  const executed = useSelector(flow(item$, (s) => s.executed));
  const selected = useSelector(flow(item$, (s) => s.selected));

  return (
    <Row>
      <CheckCell value={selected} onChange={() => onSelect(id)} />
      <Cell $ellipsis>
        <CopyTooltip text={id}>
          <Link to={routes["/item-movements/edit/:id"].create({ id })}>
            {id}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={collectionId}>
          <Ellipsis>{collectionId}</Ellipsis>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={orderId}>
          <Ellipsis>{orderId}</Ellipsis>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={from.id}>
          <Link to={routes["/repositories/edit/:id"].create({ id: from.id })}>
            {from.name}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={to.id}>
          <Link to={routes["/repositories/edit/:id"].create({ id: to.id })}>
            {to.name}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={item}>
          <Link to={routes["/inventory-items/edit/:id"].create({ id: item })}>
            {item}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell>{quantity}</Cell>
      <Cell $ellipsis>
        <Tooltip content={handler}>
          <span>{handler}</span>
        </Tooltip>
      </Cell>
      <Cell align={"center"} size={"small"}>
        <Tooltip content={executed ? t("Executed") : t("No executed")}>
          <span>
            <StatusIndicator type={executed ? "available" : "offline"} />
          </span>
        </Tooltip>
      </Cell>
      <ActionsCell
        actions={[
          {
            label: t("Execute"),
            onClick: () => onExecute(id),
          },
          {
            label: t("Delete"),
            onClick: () => onDelete(id),
          },
        ]}
      />
    </Row>
  );
}
