import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { SupplierId } from "types/src/Supplier/Supplier";

export namespace SuppliersListing {
  export interface Item {
    id: SupplierId;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
  }

  export interface Props {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    loading$: BehaviorValue<boolean>;
    filters$: BehaviorValue<{
      search: O.Option<NoEmptyString>;
      created: DateRange;
    }>;
    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;

    onCreateNew: (() => void) | undefined;
    onCreateNewType: () => void;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: SupplierId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onFilterChange: (
      v: Partial<{
        search: O.Option<NoEmptyString>;
        created: DateRange;
      }>,
    ) => void;
    onOrderChange: (p: "createdAt" | "updatedAt") => void;
  }
}

export function SuppliersListing(p: SuppliersListing.Props) {
  const { t } = useTranslation();

  return (
    <EntityListing
      title={t("Recent suppliers")}
      newItemTitle={t("New supplier")}
      newTypeTitle={t("New supplier type")}
      data$={p.data$}
      onItemClick={p.onItemClick}
      onOrderChange={p.onOrderChange}
      onFilterChange={p.onFilterChange}
      orderBy$={p.orderBy$}
      filters$={p.filters$}
      onPageChange={p.onPageChange}
      loading$={p.loading$}
      onTypeClick={p.onTypeClick}
      onCreateNew={p.onCreateNew}
      onCreateNewType={p.onCreateNewType}
    />
  );
}
