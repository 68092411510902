import MuiGlobalStyles from "@mui/material/GlobalStyles";

export function GlobalStyles() {
  return (
    <MuiGlobalStyles
      styles={(p) => ({
        color: "red",
      })}
    />
  );
}
