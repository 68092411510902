import { Selector, useSelector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import * as Stocks from "state-manager/states/Ready/states/DataManager/states/Stocks";
import * as Transactions from "state-manager/states/Ready/states/DataManager/states/Transactions";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import { useTranslation } from "i18n";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface HeaderProps {
  selector: Selector<DataManager.State>;
  dispatch: (a: DataManager.Actions) => void;
}

export function Header(p: HeaderProps): TranslatedStr | null {
  const { t } = useTranslation();

  const r = useSelector(
    flow(
      p.selector,
      (s) => s.payload.subState,
      (s) => {
        if (Customers.instance.isState(s)) {
          return "Customers";
        }
        if (Suppliers.instance.isState(s)) {
          return "Suppliers";
        }
        if (Repositories.instance.isState(s)) {
          return "Repositories";
        }
        if (InventoryItems.instance.isState(s)) {
          return "InventoryItems";
        }
        if (ItemMovements.instance.isState(s)) {
          return "ItemMovements";
        }
        if (RepositoryMovements.instance.isState(s)) {
          return "RepositoryMovements";
        }
        if (Stocks.isState(s)) {
          return "Stocks";
        }
        if (Transactions.isState(s)) {
          return "Transactions";
        }
        if (DataManager.pickingOrderState.isState(s)) {
          return "PickingOrders";
        }

        silentUnreachableError(s);
        return "unknown" as const;
      },
    ),
  );

  switch (r) {
    case "Customers":
      return t("Customers");
    case "Suppliers":
      return t("Suppliers");
    case "Repositories":
      return t("Repositories");
    case "InventoryItems":
      return t("Inventory items");
    case "ItemMovements":
      return t("Item movements");
    case "RepositoryMovements":
      return t("Repository movements");
    case "Stocks":
      return t("Stocks");
    case "Transactions":
      return t("Transactions");
    case "PickingOrders": {
      return t("Orders");
    }
    case "unknown":
      return null;
  }
}
