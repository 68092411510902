import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Schema from "../../../../../../../../generic-states/Schema";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (Schema.isActions(a)) {
    return State.isReady(s)
      ? E.right(
          State.ready({
            ...s.payload,
            schema: Schema.reducer(s.payload.schema, a),
          }),
        )
      : E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Edit:LoadFail": {
      return s.type === "Ready:DataManager:DataTypes:Edit:Loading"
        ? E.right(State.loadError(s.payload))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:LoadSuccess": {
      return s.type === "Ready:DataManager:DataTypes:Edit:Loading"
        ? E.right(
            State.ready({
              ...s.payload,
              ...a.payload,
              schema: Schema.draft(a.payload.schema),
            }),
          )
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SetName": {
      return State.isReady(s)
        ? E.right(State.ready({ ...s.payload, name: a.payload }))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SetDescription": {
      return State.isReady(s)
        ? E.right(State.ready({ ...s.payload, description: a.payload }))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SetEntity": {
      return State.isReady(s)
        ? E.right(State.ready({ ...s.payload, entity: a.payload }))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SetDefault": {
      return State.isReady(s)
        ? E.right(State.ready({ ...s.payload, default: a.payload }))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:ResetSchema": {
      return State.isReady(s)
        ? E.right(
            State.ready({ ...s.payload, schema: Schema.draft(a.payload) }),
          )
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:Submit": {
      return State.isReady(s) ? E.right(State.saving(s.payload)) : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SaveFail": {
      return State.isSaving(s) ? E.right(State.ready(s.payload)) : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:SaveSuccess": {
      return State.isSaving(s)
        ? E.left(Exits.exits.saved.create(s.payload.id))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:Remove": {
      return State.isReady(s)
        ? E.right(State.removeConfirmation(s.payload))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveApprove": {
      return State.isRemoveConfirmation(s)
        ? E.right(State.removing(s.payload))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveCancel": {
      return State.isRemoveConfirmation(s)
        ? E.right(State.ready(s.payload))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveSuccess": {
      return State.isRemoving(s)
        ? E.left(Exits.exits.removed.create(s.payload.id))
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Edit:RemoveFail": {
      return State.isRemoving(s) ? E.right(State.ready(s.payload)) : E.right(s);
    }
  }
}
