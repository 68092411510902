import { ReactElement } from "react";
import { Selector, useDispatch, useSelector } from "state-manager";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Footer as FooterLayout } from "ui/layouts/Footer";
import { Pagination } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Pagination";
import { Button } from "ui/components/Button";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType } from "types/src/Utils";

const state = DataManager.pickingOrderState.subStates.listing;

type State = GetContructorType<typeof state.states>;
type Actions = GetContructorType<typeof state.actions>;

export interface FooterProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Footer({ selector }: FooterProps): ReactElement | null {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s: State) => {
      if (state.states.loading.is(s) || state.states.loadError.is(s))
        return { type: "null" } as const;

      if (state.states.ready.is(s) || state.states.fetching.is(s)) {
        return {
          type: "paginated",
          selector: flow(
            selector,
            (a) => a as typeof s,
            (s) => ({
              hasPrev: s.payload.pageInfo.hasPreviousPage,
              hasNext: s.payload.pageInfo.hasNextPage,
            }),
          ),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "null" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return (
        <FooterLayout>
          <Button onClick={() => dispatch(state.actions.create.create())}>
            {t("Add new")}
          </Button>
          <Pagination
            selector={r.selector}
            onChange={(v) => dispatch(state.actions.setPage.create(v))}
          />
        </FooterLayout>
      );
  }
}
