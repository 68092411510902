import { ReactNode } from "react";
import { styled } from "@mui/material/styles";

export interface ListingFooterProps {
  children: [ReactNode, ReactNode];
}
export function Footer({ children }: ListingFooterProps) {
  return (
    <Wrapper>
      {children[0] && <div>{children[0]}</div>}
      {children[1] && <div>{children[1]}</div>}
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
