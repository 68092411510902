import React from "react";
import { alpha, styled } from "@mui/material/styles";
import { accentColor } from "./constants";

export namespace ProgressBar {
  export type Props = {
    current: number;
    max: number;
  };
}

export const ProgressBar = ({ current, max }: ProgressBar.Props) => {
  const percentage = max
    ? Math.max(0, Math.min(100, (current / max) * 100))
    : 0;

  return (
    <Wrapper>
      <Filled style={{ width: `${percentage}%` }} />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: ${({ theme }) => theme.spacing(13.5)};
  height: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.1)};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

const Filled = styled("div")`
  height: 100%;
  background-color: ${accentColor};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;
