import { ReactElement, useMemo } from "react";
import { RepositoriesListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/ListingAll";
import { RootState } from "state-manager";
import { flow, pipe } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ListingHeader } from "@Containers/Ready/DataManager/Repositories/ListingAll/components/ListingHeader";
import { ISODate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { Typed } from "utils/Typed";
import { DeleteConfirmation } from "@Containers/Listing/DeleteConfirmation";
import { useTranslation } from "i18n";
import { RepositoriesTable, RepositoriesTableProps } from "./Table";

export interface AllRepositoriesProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: (a: Listing.Actions) => void;
}

export function AllRepositories({
  selector$,
  dispatch,
}: AllRepositoriesProps): ReactElement {
  const { t } = useTranslation();

  const items$: RepositoriesTableProps["items$"] = useMemo(
    () =>
      flow(selector$, (s) =>
        s.payload.items.map((i) => ({
          ...i,
          createdAt: ISODate.toDate(i.createdAt),
          updatedAt: pipe(i.updatedAt, O.map(ISODate.toDate)),
          selected: s.payload.selected.includes(i.id),
        })),
      ),
    [selector$],
  );

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <RepositoriesTable
          items$={items$}
          onDelete={flow(Listing.instance.actions.removeItem.create, dispatch)}
          onDeleteAll={flow(
            Listing.instance.actions.removeBulk.create,
            dispatch,
          )}
          onSelect={flow(Listing.instance.actions.select.create, dispatch)}
          onSelectAll={flow(
            Listing.instance.actions.selectAll.create,
            dispatch,
          )}
          orderBy={flow(Listing.instance.actions.orderBy.create, dispatch)}
          orderBy$={flow(selector$, (s) => s.payload.order, O.fromNullable)}
        />
      </ListingWrapper>
      <DeleteConfirmation
        getName={(c) => (c === 1 ? t("repository") : t("repositories"))}
        selector$={selector$}
        dispatch={dispatch}
        // @ts-expect-error, fix later
        instance={Listing.instance}
      />
    </>
  );
}
