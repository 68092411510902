import { ReactElement } from "react";
import { InventoryItemsListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import { RootState } from "state-manager";
import { flow } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ListingHeader } from "@Containers/Ready/DataManager/InventoryItems/ListingAll/components/ListingHeader";
import { Typed } from "utils/Typed";
import * as O from "fp-ts/Option";
import { DeleteConfirmation } from "@Containers/Listing/DeleteConfirmation";
import { useTranslation } from "i18n";
import { Table } from "./Table";

export interface AllProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: (a: Listing.Actions) => void;
}

export function All({ selector$, dispatch }: AllProps): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <Table
          items$={flow(selector$, (s) =>
            s.payload.items.map((v) => ({
              ...v,
              selected: s.payload.selected.includes(v.id),
            })),
          )}
          orderBy$={flow(selector$, (s) => s.payload.order, O.fromNullable)}
          orderBy={flow(actions.orderBy.create, dispatch)}
          onSelect={flow(actions.select.create, dispatch)}
          onSelectAll={flow(actions.selectAll.create, dispatch)}
          onDeleteAll={flow(actions.removeBulk.create, dispatch)}
          onDelete={flow(actions.removeItem.create, dispatch)}
        />
      </ListingWrapper>
      <DeleteConfirmation
        // @ts-expect-error, fix later
        instance={Listing.instance}
        getName={(c) => (c === 1 ? t("inventory item") : t("inventory item"))}
        selector$={selector$}
        dispatch={dispatch}
      />
    </>
  );
}

const actions = Listing.instance.actions;
