import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { silentUnreachableError } from "utils/exceptions";
import * as Rx from "rxjs";
import { Client } from "ds";
import { isT } from "fp-utilities";
import { strictGuard } from "utils/strictGuard";
import { DataTypeId } from "types/src/DataType/DataType";
import { CustomerId } from "types/src/Customers/Customer";
import { Epic } from "../../../../../../types/RootEpic";
import { CustomersListingAll } from "./states/ListingAll";
import { CustomersListing as Listing } from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

const states = Typed.builder
  .add(
    "idle",
    (p: {
      listing: CustomersListingAll.State | Listing.State;
      single: Create.State | Edit.State | undefined;
    }) => p,
  )
  .finish()("Ready:DataManager:Customers");

const isState = Typed.getGuard(states);

const actions = Typed.builder
  .add("goToListingAll")
  .add("exitSingle")
  .add("goToCreate", (id: DataTypeId) => id)
  .add("goToListing", (id: DataTypeId) => id)
  .add("goToEdit", (id: CustomerId) => id)
  .finish()("Ready:DataManager:Customers:Actions");
const isActions = Typed.getGuard(actions);

const isAction = strictGuard((v: Customers.Actions): v is Customers.Actions => {
  return (
    isActions(v) ||
    CustomersListingAll.instance.isAction(v) ||
    Listing.instance.isAction(v) ||
    Create.isActions(v) ||
    Edit.isActions(v)
  );
});

function reducer(s: Customers.State, a: Customers.Actions): Customers.State {
  const listing = s.payload.listing;
  const single = s.payload.single;

  if (actions.goToListingAll.is(a)) {
    if (!CustomersListingAll.instance.isState(s.payload.listing))
      return states.idle.create({
        listing: CustomersListingAll.instance.init(),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToListing.is(a)) {
    if (
      !Listing.instance.isState(s.payload.listing) ||
      s.payload.listing.payload.id !== a.payload
    )
      return states.idle.create({
        listing: Listing.instance.init({ id: a.payload }),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToCreate.is(a)) {
    if (
      single &&
      Create.isState(single) &&
      single.payload.dataTypeId === a.payload
    ) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Create.init({ dataTypeId: a.payload }),
    });
  }

  if (actions.goToEdit.is(a)) {
    if (
      single &&
      Edit.isState(single) &&
      single.payload.customerId === a.payload
    ) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Edit.init({ customerId: a.payload }),
    });
  }

  if (actions.exitSingle.is(a)) {
    return Fp.pipe(
      O.fromNullable(single),
      O.map(() => states.idle.create({ listing, single: undefined })),
      O.getOrElse(() => s),
    );
  }

  if (CustomersListingAll.instance.isAction(a)) {
    if (CustomersListingAll.instance.isState(listing)) {
      return Fp.pipe(
        CustomersListingAll.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElse(() => s),
      );
    }

    return s;
  }

  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(listing)) {
      return Fp.pipe(
        Listing.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElseW((e) => {
          if (Listing.instance.exits.create.is(e))
            return states.idle.create({
              listing: listing,
              single: Create.init({ dataTypeId: listing.payload.id }),
            });

          silentUnreachableError(e);
          return s;
        }),
      );
    }

    return s;
  }

  if (Create.isActions(a)) {
    if (Create.isState(single)) {
      return Fp.pipe(
        Create.reducer(single, a),
        E.mapLeft((e) => {
          if (Create.exits.created.is(e)) {
            return states.idle.create({
              listing:
                Listing.instance.isState(s.payload.listing) &&
                s.payload.listing.payload.id === e.payload.dataTypeId
                  ? Fp.pipe(
                      Listing.instance.reducer(
                        s.payload.listing,
                        Listing.instance.actions.setPage.create("start"),
                      ),
                      E.getOrElseW(() =>
                        Listing.instance.init({ id: e.payload.dataTypeId }),
                      ),
                    )
                  : Listing.instance.init({ id: e.payload.dataTypeId }),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (Edit.isActions(a)) {
    if (Edit.isState(single)) {
      return Fp.pipe(
        Edit.reducer(single, a),
        E.mapLeft((e) => {
          if (Edit.exits.saved.is(e)) {
            return states.idle.create({
              listing: reFetch(listing),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  silentUnreachableError(a);
  return s;
}

const epic: Epic<
  Customers.Actions,
  Customers.State,
  {
    pyckAdminClient$: Rx.Observable<Client>;
  }
> = (state$, ds$) => {
  const listings$ = state$.pipe(
    Rx.map((s) => s.payload.listing),
    Rx.distinctUntilChanged(),
  );
  const single$ = state$.pipe(
    Rx.map((s) => s.payload.single),
    Rx.distinctUntilChanged(),
    Rx.filter(isT),
  );

  const listingAll$ = CustomersListingAll.instance.epic(
    listings$.pipe(Rx.filter(CustomersListingAll.instance.isState)),
    ds$,
  );
  const listing$ = Listing.instance.epic(
    listings$.pipe(Rx.filter(Listing.instance.isState)),
    ds$,
  );
  const create$ = Create.epic(single$.pipe(Rx.filter(Create.isState)), ds$);
  const edit$ = Edit.epic(single$.pipe(Rx.filter(Edit.isState)), ds$);

  return Rx.merge(listingAll$, listing$, create$, edit$);
};

function reFetch(
  s: Customers.State["payload"]["listing"],
): Customers.State["payload"]["listing"] {
  if (Listing.instance.isState(s)) {
    return Fp.pipe(
      Listing.instance.reducer(s, Listing.instance.actions.reFetch.create()),
      E.getOrElseW(() => Listing.instance.init({ id: s.payload.id })),
    );
  }

  return Fp.pipe(
    CustomersListingAll.instance.reducer(
      s,
      CustomersListingAll.instance.actions.reFetch.create(),
    ),
    E.getOrElseW(() => CustomersListingAll.instance.init()),
  );
}

function createInstance() {
  return {
    states,
    actions,
    isState,
    isAction,
    reducer,
    epic,
    init: () =>
      states.idle.create({
        listing: CustomersListingAll.instance.init(),
        single: undefined,
      }),
    subStates: {
      listingAll: CustomersListingAll.instance,
      listing: Listing.instance,
      create: Create,
      edit: Edit,
    },
  };
}

export namespace Customers {
  export type Actions =
    | Typed.GetTypes<typeof actions>
    | CustomersListingAll.Actions
    | Listing.Actions
    | Create.Actions
    | Edit.Actions;

  export type State = Typed.GetTypes<typeof states>;

  export const instance = createInstance();
}
