export enum FilterGroup {
  predefined = "predefined",
  custom = "custom",
}

export enum FilterType {
  textSearch = "textSearch",
  dateRange = "dateRange",
  numberRange = "numberRange",
}

type ConfigType<
  Type extends FilterType,
  Options extends Record<string, unknown>,
  Value extends Record<string, unknown>,
> = {
  type: Type;
  options?: Options;
  defaultValue?: Value;
};

export type FilterTypeTextSearch = ConfigType<
  FilterType.textSearch,
  { placeholder?: string },
  { text?: string }
>;
export type FilterTypeDateRange = ConfigType<
  FilterType.dateRange,
  {
    min?: Date;
    max?: Date;
  },
  {
    start?: Date;
    end?: Date;
  }
>;
export type FilterTypeNumberRange = ConfigType<
  FilterType.numberRange,
  { label: string },
  {
    start?: number;
    end?: number;
  }
>;

export type FilterTypeMap = {
  [FilterType.textSearch]: FilterTypeTextSearch;
  [FilterType.dateRange]: FilterTypeDateRange;
  [FilterType.numberRange]: FilterTypeNumberRange;
};

export type FilterConfig = FilterTypeMap[FilterType];

export type PredefinedFilters = {
  search: FilterTypeTextSearch;
  dateRange: FilterTypeDateRange;
};

export type FiltersConfigBase<CustomKeys extends string = string> = {
  [FilterGroup.predefined]: Partial<PredefinedFilters> &
    Pick<PredefinedFilters, "search">;
  [FilterGroup.custom]: Record<CustomKeys, FilterConfig>; // fixme: How to specify render order in UI/sidebar
};

export type FilterProps<Config extends FilterConfig> = {
  config: Config;
  value: Config["defaultValue"];
  onChange: (value: Config["defaultValue"]) => void;
};
