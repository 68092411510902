import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { styled, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

export namespace Badge {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.badge>["renderProps"]
  >;
}

export const Badge = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
  color,
}: Badge.Props<DataEntry, ColumnConfig>) => (
  <Wrapper sx={colorsSx[color]}>{text}</Wrapper>
);

const Wrapper = styled("span")`
  ${({ theme }) => theme.typography.caption}
  display: inline-block;
  border-radius: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
`;

type Color = Badge.Props<
  DataEntryBase,
  ColumnConfigBase<DataEntryBase>
>["color"];

const colorsSx: Record<Color, SxProps<Theme>> = {
  green: (theme) => ({
    backgroundColor: "#EDF7ED", // fixme: compute from theme colors?
    color: theme.palette.success.light,
  }),
  blue: (theme) => ({
    backgroundColor: "#E5F6FD",
    color: theme.palette.info.light,
  }),
};
