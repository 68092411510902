import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import { NoEmptyString } from "types/src/NoEmptyString";
import {
  DataTypeEntity,
  DataTypeId,
  DataType,
} from "types/src/DataType/DataType";
import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import * as Rx from "rxjs";
import { Client } from "ds";
import {
  deleteInventoryItems,
  getInventoryItems,
  GetInventoryItemsVars,
} from "ds/InventoryItems";
import { getDataTypes } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../../../generic-states/Listing";

const prefix = "Ready:DataManager:InventoryItems:Listing" as const;

function createState() {
  const state = ListingState.createState<
    typeof prefix,
    InventoryItemsListing.Filters,
    InventoryItemsListing.Item,
    "createdAt" | "updatedAt",
    { id: DataTypeId }
  >(prefix, {
    defaultFilters: {
      id: O.none,
      search: O.none,
      createdAt: [undefined, undefined],
      updatedAt: [undefined, undefined],
    },
  });

  const epic: Epic<
    ListingState.GetActions<typeof state>,
    ListingState.GetState<typeof state>,
    { pyckAdminClient$: Rx.Observable<Client> }
  > = (state$, { pyckAdminClient$ }) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getInventoryItems(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: [DataTypeEntity.Item],
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;

                    return E.right({
                      items: v.items.right,
                      dataTypes: Fp.pipe(
                        v.dataTypes,
                        E.map((v) => v.items),
                        E.getOrElse(() => [] as DataType[]),
                      ),
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      dataType: O.fromNullable(
                        r.dataTypes.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteInventoryItems(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
    });
  };

  return { ...state, epic };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetInventoryItemsVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetInventoryItemsVars["where"] = {
      dataTypes: [s.payload.id],
      createdAt: fields.createdAt,
      updatedAt: fields.updatedAt,
      id: O.toUndefined(fields.id),
      search: O.toUndefined(fields.search),
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace InventoryItemsListing {
  export type Filters = {
    createdAt: DateRange;
    updatedAt: DateRange;
    id: Option<NoEmptyString>;
    search: Option<NoEmptyString>;
  };

  export interface Item {
    id: InventoryItemId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
  }

  export const instance = createState();

  export type State = ListingState.GetState<typeof instance>;
  export type Actions = ListingState.GetActions<typeof instance>;
  export type Exits = ListingState.GetExits<typeof instance>;
}
