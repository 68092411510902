import { Footer as FooterLayout } from "ui/layouts/Single/Footer";
import { RootState, useDispatch, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/DataTypes/states/Edit";
import { Button } from "ui/components/Button";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";

export interface FooterProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Footer({ selector }: FooterProps) {
  const dispatch = useDispatch();
  const isSaving = useSelector(flow(selector, Edit.isSaving));
  const { t } = useTranslation();

  return (
    <FooterLayout>
      <Button
        variant={"text"}
        onClick={() => dispatch(Edit.remove())}
        color={"error"}
      >
        {t("Delete")}
      </Button>
      <Button
        color={"primary"}
        onClick={() => dispatch(Edit.submit())}
        disabled={isSaving}
      >
        {t("Save")}
      </Button>
    </FooterLayout>
  );
}
