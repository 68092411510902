import { Selector, useSelector } from "state-manager";
import { StocksListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Stocks/states/Listing";
import {
  Head,
  HeaderCell,
  HeaderRow,
  Cell,
  Table as TableComponent,
  Body,
  Row,
} from "ui/components/Table";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { CopyTooltip } from "ui/components/CopyTooltip";
import { DateCell } from "ui/layouts/Listing/cell/DateCell";
import { shallowEqual, shallowEqualArrays } from "shallow-equal";
import * as O from "fp-ts/Option";
import { Link } from "@Router/Link";
import { ISODate } from "types/src/date/ISODate";
import { routes } from "@/router";

export interface ItemsTableProps {
  selector: Selector<Listing.Item[]>;
  dispatch: (a: Listing.Actions) => void;
}

export function ItemsTable(p: ItemsTableProps) {
  const { t } = useTranslation();
  const ids = useSelector(
    flow(p.selector, (v) => v.map((i) => i.id)),
    shallowEqualArrays,
  );

  return (
    <TableComponent stickyHeader>
      <Head>
        <HeaderRow>
          <HeaderCell>{t("Id")}</HeaderCell>
          <HeaderCell>{t("Item")}</HeaderCell>
          <HeaderCell>{t("Repository")}</HeaderCell>
          <HeaderCell>{t("CreatedAt")}</HeaderCell>
          <HeaderCell>{t("Movement")}</HeaderCell>
          <HeaderCell>{t("Incoming stock")}</HeaderCell>
          <HeaderCell>{t("Quantity")}</HeaderCell>
          <HeaderCell>{t("Outgoing stock")}</HeaderCell>
        </HeaderRow>
      </Head>
      <Body>
        {ids.map((id) => (
          <ItemRow
            key={id}
            selector={flow(p.selector, (s) => s.find((i) => i.id === id)!)}
          />
        ))}
      </Body>
    </TableComponent>
  );
}

interface ItemRowProps {
  selector: Selector<Listing.Item>;
}

function ItemRow(p: ItemRowProps) {
  const item = useSelector(p.selector, shallowEqual);

  return (
    <Row>
      <Cell $ellipsis>
        <CopyTooltip text={item.id}>
          <Link
            to={routes["/stocks/:id"].create({
              id: item.id,
            })}
          >
            {item.id}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={item.item.id}>
          <Link
            to={routes["/inventory-items/edit/:id"].create({
              id: item.item.id,
            })}
          >
            {item.item.id}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell $ellipsis>
        <CopyTooltip text={item.repository.id}>
          <Link
            to={routes["/repositories/edit/:id"].create({
              id: item.repository.id,
            })}
          >
            {item.repository.name}
          </Link>
        </CopyTooltip>
      </Cell>
      <DateCell value={O.some(ISODate.toDate(item.createdAt))} />
      <Cell $ellipsis>
        <CopyTooltip text={item.movementId}>
          <Link
            to={routes["/item-movements/edit/:id"].create({
              id: item.movementId,
            })}
          >
            {item.movementId}
          </Link>
        </CopyTooltip>
      </Cell>
      <Cell>{item.incomingStock}</Cell>
      <Cell>{item.quantity}</Cell>
      <Cell>{item.outgoingStock}</Cell>
    </Row>
  );
}
