import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  firstValueFrom,
  from,
  map,
  merge,
  NEVER,
  Observable,
  of,
  pairwise,
  shareReplay,
  Subject,
  switchMap,
} from "rxjs";
import {
  getClient,
  Client,
  isUnauthorizedError,
  DsError,
  unknownError,
} from "ds";
import { getClient as getOpenAIClient } from "open-ai-ds";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { UserAccessToken, OrgId, OpenAIApiKey, OpenAIOrgKey } from "types";
import { DataSource } from "../../types/DataSource";
import { Epic, mergeByGuard } from "../../types/RootEpic";
import * as DataManager from "./states/DataManager";
import * as BuilderPreview from "./states/BuilderPreview";
import * as BPMNPreview from "./states/BPMNPreview";
import { isSigningOut, SigningOut, State } from "./types/State";
import * as ZitadelPreview from "./states/ZitadelPreview";
import * as SandboxPreview from "./states/SandboxPreview";
import * as Actions from "./types/Actions";

interface Deps {
  userManager: DataSource["userManager"];
  clientUri: string;
}

export const epic: Epic<Actions.Actions, State, Deps> = (state$, clients) => {
  const handler = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Fn extends (client: Client) => Promise<E.Either<DsError, any>>,
  >(
    fn: Fn,
  ): ReturnType<Fn> => {
    const _client$ = client$.pipe(
      filter(O.isSome),
      map((c) => c.value),
    );

    return firstValueFrom(
      _client$.pipe(
        switchMap((client) => {
          return from(fn(client)).pipe(
            catchError(() => of(E.left(unknownError()))),
            switchMap((res) => {
              if (E.isLeft(res) && isUnauthorizedError(res.left)) {
                logger$.next(Actions.reauthorize());
                return handler(fn);
              }

              return of(res);
            }),
          );
        }),
      ),
    ) as ReturnType<Fn>;
  };

  const logger$ = new Subject<Actions.Actions>();

  const client$: Observable<Option<Client>> = state$.pipe(
    map((s) =>
      E.isRight(s.payload.user)
        ? E.right({
            accessToken: s.payload.user.right.accessToken,
            orgId: s.payload.orgId,
          })
        : E.left("refresh" as const),
    ),
    map(O.fromEither),
    distinctUntilChanged(
      O.getEq<{ accessToken: UserAccessToken; orgId: OrgId }>({
        equals: (a, b) =>
          a.accessToken === b.accessToken && a.orgId === b.orgId,
      }).equals,
    ),
    map(O.map((u) => getClient(clients.clientUri, u.accessToken, u.orgId))),
    shareReplay(1),
  );

  const orgIdSetter$ = state$.pipe(
    map((s) => s.payload.orgId),
    pairwise(),
    filter(([a, b]) => a !== b),
    switchMap(([, v]) => from(clients.userManager.setActiveOrgId(v))),
    switchMap(() => NEVER),
  );

  const pyckAdminClient$ = of<Client>({
    query: (o) => handler((client) => client.query(o)),
    mutate: (o) => handler((client) => client.mutate(o)),
  });

  const openAIClient$ = state$.pipe(
    map((s) => s.payload.openAI),
    filter((s): s is { apiKey: OpenAIApiKey; orgKey: OpenAIOrgKey } =>
      Boolean(s?.apiKey && s?.orgKey),
    ),
    switchMap((v) => {
      return of(getOpenAIClient(v.apiKey, v.orgKey));
    }),
  );

  const reauthorize$ = state$.pipe(
    map((s) => s.payload.user),
    distinctUntilKeyChanged("_tag"),
    filter(E.isLeft),
    switchMap(() => {
      return from(clients.userManager.signinSilent()).pipe(
        map(O.map(Actions.reauthorizeSuccess)),
        catchError(() => of(O.none)),
        map(O.getOrElseW(Actions.reauthorizeFail)),
      );
    }),
  );

  const signOutEpic: Epic<Actions.SignedOut, SigningOut> = (state$) =>
    state$.pipe(
      switchMap(() => {
        return from(clients.userManager.signOut()).pipe(
          map(() => Actions.signedOut()),
          catchError(() => of(Actions.signedOut())),
        );
      }),
    );

  const subStatesEpic = mergeByGuard([
    [isSigningOut, signOutEpic],
    [DataManager.isState, DataManager.epic],
    [BuilderPreview.isState, BuilderPreview.epic],
    [BPMNPreview.isState, BPMNPreview.epic],
    [ZitadelPreview.isState, ZitadelPreview.epic],
    [SandboxPreview.isState, SandboxPreview.epic],
  ]);

  return merge(
    subStatesEpic(state$.pipe(map((s) => s.payload.subState)), {
      pyckAdminClient$,
      openAIClient$,
    }),
    logger$,
    reauthorize$,
    orgIdSetter$,
  );
};
