import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { silentUnreachableError } from "utils/exceptions";
import { isOneOf } from "utils/isOneOf";
import * as Obj from "utils/object";
import * as Arr from "fp-ts/Array";
import { Eq } from "fp-ts/Eq";
import * as Filters from "../Filters";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";

export const createReducer = <
  P extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends Record<string, any>,
  T extends { id: string },
  OrderBy,
  A extends {},
>({
  states,
  actions,
  exits,
  filters: filtersInstance,
}: {
  states: ReturnType<typeof State.createState<P, F, T, OrderBy, A>>;
  actions: ReturnType<typeof Actions.createActions<P, T, OrderBy>>;
  exits: ReturnType<typeof Exits.createExits<P>>;
  filters: ReturnType<typeof Filters.createState<`${P}:filters`, F>>;
}) => {
  type _State = State.State<P, F, T, OrderBy, A>;
  const idEq: Eq<T["id"]> = { equals: (a, b) => a === b };
  const intersection = Arr.intersection<T["id"]>(idEq);

  return (
    s: _State,
    a: Actions.Actions<P, F, T, OrderBy>,
  ): E.Either<Exits.Exits<P>, _State> => {
    if (filtersInstance.isAction(a)) {
      return Fp.pipe(
        O.fromEither(filtersInstance.reducer(s.payload.filters, a)),
        O.filter((filters) => filters !== s.payload.filters),
        O.map((filters) => {
          if (filtersInstance.states.edited.is(filters)) {
            return E.right({
              ...s,
              payload: { ...s.payload, filters },
            } as _State);
          }

          if (states.loading.is(s))
            return E.right(states.loading.create({ ...s.payload, filters }));
          if (states.loadError.is(s))
            return E.right(states.loading.create({ ...s.payload, filters }));

          if (states.ready.is(s))
            return E.right(
              states.fetching.create({ ...s.payload, filters, page: "start" }),
            );
          if (states.fetching.is(s))
            return E.right(
              states.fetching.create({ ...s.payload, filters, page: "start" }),
            );

          silentUnreachableError(s);
          return E.right(s);
        }),
        O.getOrElse(() => E.right(s)),
      );
    }

    if (actions.loadFail.is(a)) {
      if (states.loading.is(s))
        return E.right(states.loadError.create(s.payload));
      if (states.fetching.is(s)) return E.right(states.ready.create(s.payload));

      return E.right(s);
    }

    if (actions.loadSuccess.is(a)) {
      if (states.loading.is(s))
        return E.right(
          states.ready.create({
            ...s.payload,
            ...a.payload,
            selected: [],
            removing: {},
          }),
        );

      return E.right(s);
    }

    if (actions.fetchSuccess.is(a)) {
      if (states.fetching.is(s))
        return E.right(
          states.ready.create({
            ...s.payload,
            ...a.payload,
          }),
        );

      return E.right(s);
    }

    if (actions.reFetch.is(a)) {
      if (states.ready.is(s) || states.fetching.is(s))
        return E.right(
          states.fetching.create({
            ...s.payload,
            page: "current",
          }),
        );

      return E.right(s);
    }

    if (actions.setPage.is(a)) {
      if (states.ready.is(s) || states.fetching.is(s))
        return E.right(
          states.fetching.create({
            ...s.payload,
            page: a.payload,
          }),
        );

      return E.right(s);
    }

    if (actions.select.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) => {
          const selected = intersection(
            s.payload.items.map((v) => v.id),
            s.payload.selected,
          );

          return {
            ...s,
            payload: {
              ...s.payload,
              selected: selected.includes(a.payload)
                ? selected.filter((i) => i !== a.payload)
                : [...selected, a.payload],
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.selectAll.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.map((s) => {
          const ids = s.payload.items.map((v) => v.id);
          const selected = intersection(ids, s.payload.selected);
          return {
            ...s,
            payload: {
              ...s.payload,
              selected: selected.length ? [] : ids,
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeItem.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) => !s.payload.removing[a.payload]),
        O.filter((s) => s.payload.items.some((i) => i.id === a.payload)),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              removing: { ...s.payload.removing, [a.payload]: "confirm" },
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeBulk.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) => !!s.payload.selected.length),
        O.chain((s) => {
          const diff = Arr.difference(idEq)(
            s.payload.selected,
            Obj.keys(s.payload.removing),
          );

          if (!diff.length) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              removing: {
                ...s.payload.removing,
                ...diff.reduce(
                  (acc, i) => {
                    acc[i] = "confirm" as const;
                    return acc;
                  },
                  {} as Record<T["id"], "confirm">,
                ),
              },
            },
          } as typeof s);
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeConfirm.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) =>
          Obj.values(s.payload.removing).some((v) => v === "confirm"),
        ),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              removing: Obj.map(
                (v) => (v === "confirm" ? "removing" : v),
                s.payload.removing,
              ),
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeDecline.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) =>
          Obj.values(s.payload.removing).some((v) => v === "confirm"),
        ),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              removing: Obj.filter((v) => v === "confirm", s.payload.removing),
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeSuccess.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) =>
          Obj.values(s.payload.removing).some((v) => v === "removing"),
        ),
        O.chain((s) => {
          const removing = intersection(
            a.payload,
            Obj.entries(s.payload.removing)
              .filter(([_, v]) => v === "removing")
              .map(([k]) => k),
          );

          if (!removing.length) return O.none;

          const items = intersection(
            a.payload,
            s.payload.items.map((v) => v.id),
          );

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              items: items.length
                ? s.payload.items.filter((v) => !items.includes(v.id))
                : s.payload.items,
              removing: Obj.filter(
                (_, k) => removing.includes(k),
                s.payload.removing,
              ),
            },
          } as typeof s);
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeFail.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.filter((s) =>
          Obj.values(s.payload.removing).some((v) => v === "removing"),
        ),
        O.chain((s) => {
          const removing = intersection(
            a.payload,
            Obj.entries(s.payload.removing)
              .filter(([_, v]) => v === "removing")
              .map(([k]) => k),
          );

          if (!removing.length) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              removing: Obj.filter(
                (_, k) => removing.includes(k),
                s.payload.removing,
              ),
            },
          } as typeof s);
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.openAdvancedFilters.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter((s) => s.payload.advancedFiltersState === "closed"),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              advancedFiltersState: "open",
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.closeAdvancedFilters.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter((s) => s.payload.advancedFiltersState === "open"),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              advancedFiltersState: "closed",
            },
          } as typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.orderBy.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(isOneOf([states.ready.is, states.fetching.is])),
        O.map((s) => {
          return states.fetching.create({
            ...s.payload,
            order: Fp.pipe(
              s.payload.order,
              O.of,
              O.chain((v) => {
                if (v?.by !== a.payload)
                  return O.some({ by: a.payload, direction: "asc" });

                if (v.direction === "asc")
                  return O.some({ by: a.payload, direction: "desc" });

                return O.none;
              }),
              O.toUndefined,
            ),
            page: "current",
          });
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.create.is(a)) {
      return E.left(exits.create.create());
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};
