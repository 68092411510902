import { styled } from "@mui/material/styles";
import { useTranslation } from "i18n";
import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "../../../types/columns";
import { DataEntryBase } from "../../../types/data";
import { styleSecondaryTextColor } from "../../../utils/styles";
import { formatAsTimeDate } from "../../../utils/date";

export namespace TimeDate {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.timeDate>["renderProps"]
  >;
}

export const TimeDate = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  date,
}: TimeDate.Props<DataEntry, ColumnConfig>) => {
  const { language } = useTranslation();

  return <Wrapper>{date ? formatAsTimeDate(date, language) : "-"}</Wrapper>;
};

const Wrapper = styled("span")`
  ${({ theme }) => theme.typography.body2};
  color: ${styleSecondaryTextColor};
`;
