import { useTranslation as _useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Translator } from "../types";

export default function useTranslation() {
  const {
    t: _t,
    i18n: { language },
  } = _useTranslation();

  return useMemo<Translator>(
    () => ({
      t: (text, vars) =>
        _t(
          text,
          // fixme: how to separate interpolation vars from predefined options: context, count, ns ?
          vars,
        ) as TranslatedStr,
      language,
    }),
    [_t, language],
  );
}
