import { ReactElement, useCallback, useMemo } from "react";
import { CustomersListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import { RootState } from "state-manager";
import { flow, pipe } from "fp-ts/function";
import { ListingWrapper } from "ui/layouts/Listing/ListingWrapper";
import { ISODate } from "types/src/date/ISODate";
import * as O from "fp-ts/Option";
import { Typed } from "utils/Typed";
import { ListingHeader } from "./ListingHeader";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { CustomersTable } from "./CustomersTable";

export interface AllCustomersProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: (a: Listing.Actions) => void;
}

export function AllCustomers({
  selector$,
  dispatch,
}: AllCustomersProps): ReactElement {
  const columns$ = useMemo(() => () => [], [selector$]);
  const items$: CustomersTable.Props["items$"] = useMemo(
    () =>
      flow(selector$, (s) =>
        s.payload.items.map(
          (i): CustomersTable.Item => ({
            ...i,
            fields: {},
            createdAt: ISODate.toDate(i.createdAt),
            updatedAt: pipe(i.updatedAt, O.map(ISODate.toDate)),
            selected: s.payload.selected.includes(i.id),
          }),
        ),
      ),
    [selector$],
  );
  const orderBy$ = useMemo(
    () => flow(selector$, (s) => s.payload.order, O.fromNullable),
    [selector$],
  );

  const orderBy = useCallback(
    (v: "createdAt" | "updatedAt") =>
      dispatch(Listing.instance.actions.orderBy.create(v)),
    [dispatch],
  );
  const onSelect = useCallback(
    flow(Listing.instance.actions.select.create, dispatch),
    [dispatch],
  );
  const onSelectAll = useCallback(
    flow(Listing.instance.actions.selectAll.create, dispatch),
    [dispatch],
  );

  const onDelete = useCallback(
    flow(Listing.instance.actions.removeItem.create, dispatch),
    [dispatch],
  );
  const onDeleteAll = useCallback(
    flow(Listing.instance.actions.removeBulk.create, dispatch),
    [dispatch],
  );

  return (
    <>
      <ListingWrapper
        header={<ListingHeader selector$={selector$} dispatch={dispatch} />}
      >
        <CustomersTable
          columns$={columns$}
          items$={items$}
          orderBy$={orderBy$}
          orderBy={orderBy}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          onDeleteAll={onDeleteAll}
          onDelete={onDelete}
        />
      </ListingWrapper>
      <DeleteConfirmation selector$={selector$} dispatch={dispatch} />
    </>
  );
}
