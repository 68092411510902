import { createStore, StateProvider } from "state-manager";
import { useMemo } from "react";
import {
  UserProvider as UserManager,
  WebStorageStateStore,
} from "authentificator";
import * as Auth from "state-manager/states/Auth";
import * as Notifications from "state-manager/states/Notifications";
import { ThemeProvider } from "ui/components/ThemeProvider";
import { GlobalStyles } from "ui/layouts/GlobalStyles";
import { ToastProvider } from "ui/components/Toast";
import { RouterDispatcher } from "@Containers/RouterDispatcher";
import { BrowserRouter } from "react-router-dom";
import { Notifications as NotificationsContainer } from "@Containers/Notifications/Notifications";
import { Loading } from "ui/layouts/Loading";
import { SupportedLang, I18nInitSuspense } from "i18n";
import { Pages } from "@/pages";
import { getRedirectAction } from "@/router";

export interface AppProps {
  clientId: string;
  authority: string;
  redirectUri: string;
  clientUri: string;
  zitadelConsole: string;
}

export function App(p: AppProps) {
  const dataSources = useMemo(
    () => ({
      // eslint-disable-next-line no-console
      logger: console.error,
      userManager: new UserManager({
        clientId: p.clientId,
        authority: p.authority,
        redirectUri: p.redirectUri,
        store: new WebStorageStateStore({ store: window.localStorage }),
      }),
      clientUri: p.clientUri,
    }),
    [],
  );
  const state =
    window.location.pathname === "/callback"
      ? Auth.authConfirmation({})
      : Auth.idle({
          goTo: getRedirectAction(window.location.pathname) ?? {
            type: "data-types:listing",
          },
        });
  const store = createStore(dataSources, {
    content: state,
    notifications: Notifications.init(),
  });

  return (
    <BrowserRouter>
      <StateProvider store={store}>
        <ThemeProvider theme="light">
          <GlobalStyles />
          <I18nInitSuspense
            Loading={Loading /*todo: handle `error` prop*/}
            lang={SupportedLang.de}
          >
            <ToastProvider>
              <Pages />
              <NotificationsContainer selector={(s) => s.notifications} />
            </ToastProvider>
          </I18nInitSuspense>
        </ThemeProvider>
        <RouterDispatcher />
      </StateProvider>
    </BrowserRouter>
  );
}
