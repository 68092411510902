import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import { InventoryItemsListingAll as Listing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import * as O from "fp-ts/Option";
import { AdvancedFiltersForm } from "@Containers/Ready/DataManager/InventoryItems/ListingAll/components/AdvancedFiltersForm";
import { AdvancedFilters } from "@Containers/Listing/AdvancedFilters";
import { Typed } from "utils/Typed";
import { NoEmptyString } from "types/src/NoEmptyString";
import { match } from "fp-utilities";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface FiltersProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof Listing.instance.states>[
    | "ready"
    | "fetching"];
  dispatch: Dispatch<Listing.Actions>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const search$ = flow(
    p.selector$,
    (s) => s.payload.filters.payload.fields.search,
    O.getOrElseW(() => ""),
  );
  const isOpen$ = flow(
    p.selector$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const filters$ = flow(p.selector$, (s) => s.payload.filters);
  const dataTypes$ = flow(
    p.selector$,
    (s) => s.payload.dataTypes,
    match(
      [Listing.instance.dataTypes.states.loading.is, () => []],
      [Listing.instance.dataTypes.states.loadError.is, () => []],
      [
        Listing.instance.dataTypes.states.ready.is,
        (s) =>
          s.payload.data.map((v) => ({
            id: v.id,
            name: v.name as TranslatedStr,
          })),
      ],
    ),
  );

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(
          NoEmptyString.fromString,
          (v) => filters.actions.setValue.create({ search: v }),
          p.dispatch,
        )}
      />
      <FiltersTrigger
        isSelected$={flow(
          p.selector$,
          (s) => !filters.states.idle.is(s.payload.filters),
        )}
        onClear={flow(filters.actions.reset.create, p.dispatch)}
        onClick={flow(
          Listing.instance.actions.openAdvancedFilters.create,
          p.dispatch,
        )}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(
          Listing.instance.actions.closeAdvancedFilters.create,
          p.dispatch,
        )}
        onClear={flow(filters.actions.reset.create, p.dispatch)}
        onApply={flow(filters.actions.submit.create, p.dispatch)}
      >
        <AdvancedFiltersForm
          dispatch={p.dispatch}
          selector$={filters$}
          dataTypes$={dataTypes$}
        />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}

const filters = Listing.instance.subStates.filters;
