import { FiberManualRecord } from "@mui/icons-material";
import { forwardRef } from "react";

export namespace StatusIndicator {
  export interface Props {
    type: "available" | "offline";
  }
}

export const StatusIndicator = forwardRef<SVGSVGElement, StatusIndicator.Props>(
  (p, ref) => {
    return (
      <FiberManualRecord
        ref={ref}
        sx={(t) => ({
          fill: {
            available: t.palette.success.main,
            offline: t.palette.grey["300"],
          }[p.type],
        })}
      />
    );
  },
);
