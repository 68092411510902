import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Schema from "../../../../../../../../generic-states/Schema";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (Schema.isActions(a)) {
    return s.type === "Ready:DataManager:DataTypes:Create:Ready"
      ? E.right(
          State.ready({
            ...s.payload,
            schema: Schema.reducer(s.payload.schema, a),
          }),
        )
      : E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:DataTypes:Create:SetName":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? E.right(State.ready({ ...s.payload, name: a.payload }))
        : E.right(s);
    case "Ready:DataManager:DataTypes:Create:SetDescription":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? E.right(State.ready({ ...s.payload, description: a.payload }))
        : E.right(s);
    case "Ready:DataManager:DataTypes:Create:SetDefault":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? E.right(State.ready({ ...s.payload, default: a.payload }))
        : E.right(s);
    case "Ready:DataManager:DataTypes:Create:ResetSchema": {
      return State.isReady(s)
        ? E.right(
            State.ready({ ...s.payload, schema: Schema.draft(a.payload) }),
          )
        : E.right(s);
    }
    case "Ready:DataManager:DataTypes:Create:Submit":
      return s.type === "Ready:DataManager:DataTypes:Create:Ready"
        ? E.right(State.saving(s.payload))
        : E.right(s);
    case "Ready:DataManager:DataTypes:Create:SaveFail":
      return s.type === "Ready:DataManager:DataTypes:Create:Saving"
        ? E.right(State.ready(s.payload))
        : E.right(s);
    case "Ready:DataManager:DataTypes:Create:SaveSuccess":
      return s.type === "Ready:DataManager:DataTypes:Create:Saving"
        ? E.left(Exits.exits.created.create(a.payload))
        : E.right(s);
    default:
      silentUnreachableError(a);
      return E.right(s);
  }
}
