import { FiltersWrapper } from "ui/layouts/Listing/FiltersWrapper";
import { SearchInput } from "@Containers/Form/SearchInput";
import { RootState, useSelector } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { flow } from "fp-ts/function";
import { FiltersButton } from "ui/layouts/Filters/FiltersButton";
import * as O from "fp-ts/Option";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { AdvancedFilters } from "@Containers/Listing/AdvancedFilters";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Typed } from "utils/Typed";
import { ListingState } from "../../../../../../../../../packages/state-manager/src/generic-states/Listing";
import { AdvancedFiltersForm } from "./AdvancedFiltersForm";

const state = DataManager.pickingOrderState.subStates.listing;

export interface FiltersProps {
  selector$: (
    s: RootState,
  ) => Typed.GetCollectionType<typeof state.states>["ready" | "fetching"];
  dispatch: Dispatch<ListingState.GetActions<typeof state>>;
}

export function ListingHeader(p: FiltersProps): ReactElement {
  const search$ = flow(
    p.selector$,
    (s) => s.payload.filters.payload.fields.search,
    O.getOrElseW(() => ""),
  );
  const isOpen$ = flow(
    p.selector$,
    (s) => s.payload.advancedFiltersState === "open",
  );
  const filters$ = flow(p.selector$, (s) => s.payload.filters);

  return (
    <FiltersWrapper>
      <SearchInput
        value$={search$}
        onChange={flow(
          NoEmptyString.fromString,
          (v) => state.subStates.filters.actions.setValue.create({ search: v }),
          p.dispatch,
        )}
      />
      <FiltersTrigger
        isSelected$={flow(
          p.selector$,
          (s) => !state.subStates.filters.states.idle.is(s.payload.filters),
        )}
        onClear={flow(state.subStates.filters.actions.reset.create, p.dispatch)}
        onClick={flow(state.actions.openAdvancedFilters.create, p.dispatch)}
      />
      <AdvancedFilters
        open$={isOpen$}
        onClose={flow(state.actions.closeAdvancedFilters.create, p.dispatch)}
        onClear={flow(state.subStates.filters.actions.reset.create, p.dispatch)}
        onApply={flow(
          state.subStates.filters.actions.submit.create,
          p.dispatch,
        )}
      >
        <AdvancedFiltersForm dispatch={p.dispatch} selector$={filters$} />
      </AdvancedFilters>
    </FiltersWrapper>
  );
}

interface FiltersTriggerProps {
  isSelected$: (s: RootState) => boolean;
  onClear: () => void;
  onClick: () => void;
}
function FiltersTrigger(p: FiltersTriggerProps): ReactElement {
  const isSelected = useSelector(p.isSelected$);

  return (
    <FiltersButton
      isSelected={isSelected}
      onClick={p.onClick}
      onClear={p.onClear}
    />
  );
}
