import { styled } from "@mui/material/styles";

export const Icon: React.FC = () => (
  <Wrapper>
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" r="4" fill="currentColor" fillOpacity="0.87" />
    </svg>
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H12"
        stroke="currentColor"
        strokeOpacity="0.87"
        strokeDasharray="2 2"
      />
    </svg>
  </Wrapper>
);

const Wrapper = styled("div")`
  display: inline-flex;
  align-items: center;
  opacity: 0.3;
`;
