import React from "react";
import { Box } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // todo: review
import { useTranslation } from "i18n";
import { langToDateFnsLocale } from "../../../constants/date";

export namespace DateRangePicker {
  export type Value = Partial<{ start: Date; end: Date }>;
  export type Props = {
    min?: Date;
    max?: Date;
    value?: Value;
    onChange: (value?: Value) => void;
  };
}

// fixme: maybe move to `src/components/`
export const DateRangePicker: React.FC<DateRangePicker.Props> = ({
  min,
  max,
  value,
  onChange,
}) => {
  const { language } = useTranslation();

  const onStartChange = (date: Date | null) => {
    if (!date) {
      return onChange({
        start: undefined,
        end: value?.end,
      });
    }
    if (min && date < min) {
      return;
    }
    if (max && date > max) {
      return;
    }
    onChange({
      start: date,
      end: value?.end ? (date > value.end ? date : value.end) : undefined,
    });
  };
  const onEndChange = (date: Date | null) => {
    if (!date) {
      return onChange({
        start: value?.start,
        end: undefined,
      });
    }
    if (min && date < min) {
      return;
    }
    if (max && date > max) {
      return;
    }
    onChange({
      start: value?.start
        ? date < value.start
          ? date
          : value.start
        : undefined,
      end: date,
    });
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={langToDateFnsLocale[language]}
      >
        <DateCalendar
          value={value?.start}
          minDate={min}
          maxDate={max}
          onChange={onStartChange}
        />
        <DateCalendar
          value={value?.end}
          onChange={onEndChange}
          minDate={value?.start || min}
          maxDate={max}
        />
      </LocalizationProvider>
    </Box>
  );
};
