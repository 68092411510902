import { useTranslation } from "i18n";
import { Typography } from "ui/components/Typography";
import { Selector } from "state-manager";
import * as Create from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Create";

export interface HeaderProps {
  selector: Selector<Create.State>;
  dispatch: (a: Create.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Typography>{t("Add new repository")}</Typography>;
}
