import { RootState } from "state-manager";
import { Dispatch, ReactElement } from "react";
import { FormWrapper } from "ui/layouts/FormWrapper";
import * as O from "fp-ts/Option";
import { DataTypeId } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ISODate } from "types/src/date/ISODate";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as Filters from "state-manager/generic-states/Filters";
import { ById } from "@Containers/Filters/ById";
import { CreatedFrom } from "@Containers/Filters/CreatedFrom";
import { CreatedTo } from "@Containers/Filters/CreatedTo";
import { DataTypes } from "@Containers/Filters/DataTypes";
import { Orphans } from "@Containers/Filters/Orphans";
import { SearchFields } from "@Containers/Filters/SearchFields";

export namespace AdvancedFiltersForm {
  export interface DataType {
    id: DataTypeId;
    name: TranslatedStr;
  }

  export type Filter = {
    id: O.Option<NoEmptyString>;
    search: O.Option<NoEmptyString>;
    createdAt: [ISODate | undefined, ISODate | undefined];
    updatedAt: [ISODate | undefined, ISODate | undefined];
    status: "active" | "orphan" | "all";
    dataTypes: DataTypeId[];
  };

  export interface Props<P extends string> {
    selector$: (s: RootState) => Filters.State<P, Filter>;
    dataTypes$: (s: RootState) => Array<DataType>;
    dispatch: Dispatch<Filters.Actions<P, Filter>>;
    actions: ReturnType<typeof Filters.createState<P, Filter>>["actions"];
  }
}

export function AdvancedFiltersForm<P extends string>(
  p: AdvancedFiltersForm.Props<P>,
): ReactElement {
  return (
    <FormWrapper>
      <ById selector$={p.selector$} dispatch={p.dispatch} actions={p.actions} />
      <SearchFields
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <DataTypes
        dataTypes$={p.dataTypes$}
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <Orphans
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <CreatedFrom
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
      <CreatedTo
        selector$={p.selector$}
        dispatch={p.dispatch}
        actions={p.actions}
      />
    </FormWrapper>
  );
}
