import { Transaction } from "types/src/Transactions/Transaction";
import { TransactionFragmentFragment } from "../generated/graphql";
import { repositoryFragmentToRepository } from "./Repositories";
import { inventoryItemFragmentToInventoryItem } from "./InventoryItems";

export function transactionFragmentToTransaction(
  v: TransactionFragmentFragment,
): Transaction {
  return {
    id: v.id as Transaction["id"],
    createdAt: v.createdAt,
    quantity: v.quantity,
    type: v.type,
    repository: repositoryFragmentToRepository(v.repository),
    item: inventoryItemFragmentToInventoryItem(v.item),
  };
}
