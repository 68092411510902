import React, { useMemo } from "react";
import { styled, Theme } from "@mui/material/styles";
import { Button, SxProps } from "@mui/material";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  ListData,
  DataEntryBase,
  PaginationDestination,
} from "@layouts/ListingTable/types/data";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useTranslation } from "i18n";
import {
  styleButtonBorderColor,
  styleSecondaryTextColor,
} from "@layouts/ListingTable/utils/styles";

export namespace Footer {
  export type Props<DataEntry extends DataEntryBase> = {
    data$: BehaviorValue<ListData<DataEntry>>;
    onPageChange?: (page: PaginationDestination) => void;
  };
}

export const Footer = <DataEntry extends DataEntryBase>({
  data$,
  onPageChange,
}: Footer.Props<DataEntry>) => {
  const { t } = useTranslation();
  const { hasPrev, hasNext } = useBehaviorValue(
    useMemo(() => data$.map(({ pagination }) => pagination), [data$]),
  );

  return (
    <Wrapper>
      <PaginationButton
        disabled={!hasPrev}
        onClick={() => onPageChange?.("start")}
      >
        <span>⟪</span>
        {t("First")}
      </PaginationButton>
      <PaginationButton
        disabled={!hasPrev}
        onClick={() => onPageChange?.("prev")}
      >
        <span>⟨</span>
        {t("Previous")}
      </PaginationButton>
      <PaginationButton
        disabled={!hasNext}
        onClick={() => onPageChange?.("next")}
      >
        {t("Next")}
        <span>⟩</span>
      </PaginationButton>
      <PaginationButton
        disabled={!hasNext}
        onClick={() => onPageChange?.("end")}
      >
        {t("Last")}
        <span>⟫</span>
      </PaginationButton>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: end;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(3, 6, 4)};
`;

const PaginationButton: React.FC<
  React.PropsWithChildren<{ onClick: () => void; disabled: boolean }>
> = ({ onClick, disabled, children }) => (
  <Button
    size="small"
    variant="outlined"
    sx={sxPaginationButton}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

const sxPaginationButton: SxProps<Theme> = (theme) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2, 3.5),
  borderColor: styleButtonBorderColor(theme),
  gap: "0.7em",
  "&:not(.Mui-disabled) > span": {
    color: styleSecondaryTextColor(theme),
  },
});
