import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "@layouts/ListingTable/types/columns";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { Wrapper, Text } from "../MovedFrom";
import { Icon } from "./Icon";

export namespace MovedTo {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.movedTo>["renderProps"]
  >;
}

export const MovedTo = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  text,
}: MovedTo.Props<DataEntry, ColumnConfig>) => (
  <Wrapper>
    <Icon />
    <Text>{text ?? "-"}</Text>
  </Wrapper>
);
