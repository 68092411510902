import { ReactElement } from "react";
import { Selector, useSelector } from "state-manager";
import * as Fp from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Footer as FooterLayout } from "ui/layouts/Footer";
import { TransactionsListing as Listing } from "state-manager/states/Ready/states/DataManager/states/Transactions/states/Listing";
import { Pagination } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Pagination";

export interface FooterProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions) => void;
}

export function Footer(p: FooterProps): ReactElement | null {
  const r = useSelector(
    Fp.flow(p.selector, (s: Listing.State) => {
      if (Listing.instance.states.loading.is(s))
        return { type: "null" } as const;
      if (Listing.instance.states.loadError.is(s))
        return { type: "null" } as const;

      if (
        Listing.instance.states.ready.is(s) ||
        Listing.instance.states.fetching.is(s)
      ) {
        return {
          type: "paginated",
          selector: Fp.flow(
            p.selector,
            (v) => v as typeof s,
            (s) => ({
              hasPrev: s.payload.pageInfo.hasPreviousPage,
              hasNext: s.payload.pageInfo.hasNextPage,
            }),
          ),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "null" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return (
        <FooterLayout>
          <div />
          <Pagination
            selector={r.selector}
            onChange={Fp.flow(
              Listing.instance.actions.setPage.create,
              p.dispatch,
            )}
          />
        </FooterLayout>
      );
  }
}
