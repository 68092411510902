import { ReactElement } from "react";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Footer as FooterLayout } from "ui/layouts/Footer";
import { Pagination } from "@Containers/Ready/DataManager/DataTypes/Listing/components/Pagination";
import { SuppliersListingAll as ListingAll } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";

export interface FooterProps {
  selector: Selector<ListingAll.State>;
  dispatch: (a: ListingAll.Actions) => void;
}

export function Footer({
  selector,
  dispatch,
}: FooterProps): ReactElement | null {
  const r = useSelector(
    flow(selector, (s: ListingAll.State) => {
      if (ListingAll.instance.states.loading.is(s))
        return { type: "null" } as const;
      if (ListingAll.instance.states.loadError.is(s))
        return { type: "null" } as const;

      if (
        ListingAll.instance.states.ready.is(s) ||
        ListingAll.instance.states.fetching.is(s)
      ) {
        return {
          type: "paginated",
          selector: flow(
            selector,
            (v) => v as typeof s,
            (s) => ({
              hasPrev: s.payload.pageInfo.hasPreviousPage,
              hasNext: s.payload.pageInfo.hasNextPage,
            }),
          ),
        } as const;
      }

      silentUnreachableError(s);
      return { type: "null" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "null":
      return null;
    case "paginated":
      return (
        <FooterLayout>
          <div></div>
          <Pagination
            selector={r.selector}
            onChange={(v) =>
              dispatch(ListingAll.instance.actions.setPage.create(v))
            }
          />
        </FooterLayout>
      );
  }
}
