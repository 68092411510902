import React, { useCallback, useRef, useState } from "react";

export const usePopoverController = () => {
  const [open, setOpen] = useState<undefined | boolean>(
    undefined /*== don't render*/,
  );
  const close = useCallback(() => setOpen(false), []);
  const buttonRef = useRef<Element>();
  const onOpenButtonClick = useCallback<React.MouseEventHandler<Element>>(
    (event) => {
      buttonRef.current = event.currentTarget;
      setOpen((v) => !v);
    },
    [],
  );

  return {
    onOpenPopoverButtonClick: onOpenButtonClick,
    popoverOpen: open,
    popoverProps: {
      open: open || false,
      onClose: close,
      anchorEl: buttonRef.current,
    },
  };
};
